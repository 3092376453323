import OwnCreatureCard from "components/Cards/Owncreature";
import PaginationPager from "components/Pagination/pagination";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Sgoblin from "assets/02-Silver/01-Goblin.png"
import Stroll from "assets/02-Silver/02-Troll.png"
import Sogre from "assets/02-Silver/03-Ogre.png"
import Sorc from "assets/02-Silver/04-Orc.png"
import Syeti from "assets/02-Silver/05-Yeti.png"
import Sminotaur from "assets/02-Silver/06-Minotaur.png"
import Sdragon from "assets/02-Silver/07-Dragon.png"
import { or } from "ajv/dist/compile/codegen";
const SilverInventory = () => {
    const [goblin, setGoblin] = useState([]),
    [troll, setTroll] = useState([]),
    [ogre, setOgre] = useState([]),
    [orc, setOrc] = useState([]),
    [yeti, setYeti] = useState([]),
    [minotaur, setMinotaur] = useState([]),
    [dragon, setDragon] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

    const [list, setlist] = useState({})


    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/inventory/getinventory?rank=silver&page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
              setlist(data.data)
                const sgoblin = data.data.s_goblin
                const stroll = data.data.s_troll
                const sogre = data.data.s_ogre
                const sorc = data.data.s_orc
                const syeti = data.data.s_yeti
                const sminotaur = data.data.s_minotaur
                const sdragon = data.data.s_dragon

                setGoblin(sgoblin)
                setOrc(sorc)
                setTroll(stroll)
                setOgre(sogre)
                setYeti(syeti)
                setMinotaur(sminotaur)
                setDragon(sdragon)
                setIsLoading(false)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[page])

      const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const totalAccumulated = Object.keys(list).filter(key => key!== 'totalPages').reduce((acc, current) => {
  return acc + list[current].totalaccumulated;
  }, 0);

  useEffect(() => {
    localStorage.setItem('silver',totalAccumulated)
  })

    return(
        <MDBContainer fluid>
            <MDBRow style={{gap:'1rem'}}>
                { 
                    goblin != undefined && goblin.length != 0 &&

                    <OwnCreatureCard
                    title="Goblin"
                    image={Sgoblin}
                    dailyaccumulated={`${goblin.dailyaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    dailyaccumulatedusd={`${(goblin.dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    totalaccumulated={`${goblin.totalaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    totalaccumulatedusd={`${(goblin.totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    creatureid={goblin.creatureid}
                    
                    qty={goblin.qty}
                    />
                }

                { 
                    troll != undefined && troll.length != 0 &&

                    <OwnCreatureCard
                    title="Troll"
                    image={Stroll}
                    dailyaccumulated={`${troll.dailyaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    dailyaccumulatedusd={`${(troll.dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    totalaccumulated={`${troll.totalaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    totalaccumulatedusd={`${(troll.totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    creatureid={troll.creatureid}

                    qty={troll.qty}
                    />
                }

                { 
                    ogre != undefined && ogre.length != 0 &&

                    <OwnCreatureCard
                    title="Ogre"
                    image={Sogre}
                    dailyaccumulated={`${ogre.dailyaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    dailyaccumulatedusd={`${(ogre.dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    totalaccumulated={`${ogre.totalaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    totalaccumulatedusd={`${(ogre.totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    creatureid={ogre.creatureid}

                    qty={ogre.qty}
                    />
                }

                { 
                    orc != undefined && orc.length != 0 &&

                    <OwnCreatureCard
                    title="Orc"
                    image={Sorc}
                    dailyaccumulated={`${orc.dailyaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    dailyaccumulatedusd={`${(orc.dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    totalaccumulated={`${orc.totalaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    totalaccumulatedusd={`${(orc.totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    creatureid={orc.creatureid}
                    qty={orc.qty}
                    />
                }
                
                { 
                    yeti != undefined && yeti.length != 0 &&

                    <OwnCreatureCard
                    title="Yeti"
                    image={Syeti}
                    dailyaccumulated={`${yeti.dailyaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    dailyaccumulatedusd={`${(yeti.dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    totalaccumulated={`${yeti.totalaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    totalaccumulatedusd={`${(yeti.totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    creatureid={yeti.creatureid}

                    qty={yeti.qty}
                    />
                }

                { 
                    minotaur != undefined && minotaur.length != 0 &&

                    <OwnCreatureCard
                    title="Minotaur"
                    image={Sminotaur}
                     dailyaccumulated={`${minotaur.dailyaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                     dailyaccumulatedusd={`${(minotaur.dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    totalaccumulated={`${minotaur.totalaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    totalaccumulatedusd={`${(minotaur.totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    creatureid={minotaur.creatureid}

                    qty={minotaur.qty}
                    />
                }

                { 
                    dragon != undefined && dragon != 0 &&

                    <OwnCreatureCard
                    title="Dragon"
                    image={Sdragon}
                     dailyaccumulated={`${dragon.dailyaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                     dailyaccumulatedusd={`${(dragon.dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    totalaccumulated={`${dragon.totalaccumulated.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0 `}
                    totalaccumulatedusd={`${(dragon.totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / 0`}
                    creatureid={dragon.creatureid}

                    qty={dragon.qty}
                    />
                }

                 {Object.keys(list).filter(key => key !== 'totalPages').map(( data, index) => (
                 
                   <OwnCreatureCard
                   key={index}
                    title={`${list[data].type === "s_goblin" && 'Goblin' || list[data].type === "s_troll" && 'Troll' || list[data].type === "s_orc" && 'Orc' || list[data].type === "s_ogre" && 'Ogre' || list[data].type === "s_yeti" && 'Yeti' || list[data].type === "s_minotaur" && 'Minotaur' || list[data].type === "s_dragon" && 'Dragon'} `}


                    image={`${list[data].type === 's_goblin' && Sgoblin || list[data].type === 's_troll' && Stroll || list[data].type === 's_orc' && Sorc || list[data].type === 's_ogre' && Sogre || list[data].type === 's_yeti' && Syeti || list[data].type === 's_minotaur' && Sminotaur || list[data].type === 's_dragon' && Sdragon}`}

                    dailyaccumulated={`${(list[data].dailyaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(list[data].limitdaily).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}

                    dailyaccumulatedusd={`${(list[data].dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / $ ${(list[data].limitdaily / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}

                    totalaccumulated={`${(list[data].totalaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(list[data].limittotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}

                    totalaccumulatedusd={`${(list[data].totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / $ ${(list[data].limittotal / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    creatureid={list[data].creatureid}

                    />
                ))}
            </MDBRow>
            <PaginationPager
                total={total} 
                page={page} 
                setPage={setPage}
                isloading={isloading}
            />
        </MDBContainer>
    )
}

export default SilverInventory;