import { MDBContainer, MDBTypography, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBInput, MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
    MDBTable, MDBTableHead, MDBTableBody
  } from 'mdb-react-ui-kit';
import PaginationPager from "components/Pagination/pagination";



const SuperAdminSales = () => {
     const [sales, setSales] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [ rate, setRate] = useState(0)


   useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/analytics/gettotalpayinperday?startDate=${start}&endDate=${end}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        console.log(data.data)
        setSales(data.data.analytics)
     
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [start,end]);

  const reset = () => {
    setStart('')
    setEnd('')
  }

   // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
    }, []);
   
    return (
        <div className="content">
          {/* <h2>Sales</h2> */}

        <MDBCardBody>
            <div className=" flex md:flex-row flex-col gap-2">
                 <div className=" flex flex-col gap-1">
                    <label>Start Date</label>
                    <input value={start} onChange={(e) => setStart(e.target.value)} type="date" className=" w-[150px]"/>
                </div>

                <div className=" flex flex-col gap-1">
                    <label>End Date</label>
                    <input value={end} onChange={(e) => setEnd(e.target.value)} type="date" className=" w-[150px]"/>
                </div>
                <MDBBtn onClick={reset} className=" max-w-[180px]">Reset</MDBBtn>
            </div>
           
       
        <MDBTable small responsive className=" mt-4 bg-zinc-100">
        <MDBTableHead>
            <tr className="text-center">
            <th scope='col'>Date</th>
            <th scope='col'>Amount</th>
            
            </tr>
        </MDBTableHead>
        <MDBTableBody>
            {
                sales.length !== 0 ?
                    sales.map((data, i) =>(
                    <tr key={i} className="text-center">
                        <td scope='row'>{data._id}</td>
                        <td className="text-lg">₱ {data.totalValue?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                            <p className=" text-sm">${(data.totalValue / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </td>
                    
                    </tr>
                    ))
                :
                <tr className="text-center">
                  <th scope='row' colSpan={6}>No Data</th>
                </tr>
            }
            
        </MDBTableBody>
        </MDBTable>
        {/* <PaginationPager
          total={total} 
          page={page} 
          setPage={setPage}
          isloading={isloading}
          /> */}
      </MDBCardBody>
        </div>
    )
}

export default SuperAdminSales;