import React,{ useEffect, useState} from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBInput
  } from 'mdb-react-ui-kit';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
  import Swal from "sweetalert2";
const PaymentDetails = () => {
     const [mydetail, setMyDetail] = useState([])
      const [loading, setLoading] = useState(false)
      const [payment, setPayment] = useState('Gcash')




    const handleUpdatePayment = (e) => {
    e.preventDefault();
    setLoading(true)
    const { firstname, lastname, address, city, country, postalcode, paymentmethod, accountnumber } = e.target
    Swal.fire({
      title: "Are you sure?",
      text: "You want to update your profile details!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!"
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API_URL}/user/updateuserprofile`,{
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            paymentmethod: paymentmethod.value,
            accountnumber: accountnumber.value != "" ? accountnumber.value : mydetail.accountnumber
          })
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.replace("/login");
              }
            })
          }

          if(data.message == "success"){
            setLoading(false)
            Swal.fire({
              title: data.message,
              icon: "success",
              text: "Updated Successfully"
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.reload()
              }
            })
          }  else if (data.message == "failed"){
            setLoading(false)
            Swal.fire({
              title: data.message,
              icon: "info",
              text: data.data
            })
          }
        })
      } else {
        setLoading(false)
      }
    });
    
  }

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/user/getuserdetails`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }
        if( data.paymentmethod !== ''){
           setPayment(data.data.paymentmethod)
        }

        if(data.message == "success"){
          setMyDetail(data.data)
         
        }  else {
          Swal.fire({
            title: data.message,
            icon: "info",
            text: data.data
          })
        }
      })
    },[])


    return(
    <MDBCard>
    <form onSubmit={handleUpdatePayment}>
      <MDBCardBody>
        <MDBCardTitle>Edit Paymet Detail</MDBCardTitle>
       <Row>
                  <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Payment Method</label>
                         <select 
                         
                        value={payment}
                        name="paymentmethod" 
                        className=" border-[2px] border-zinc-400 px-4 py-[13px] rounded-md" 
                        onChange={(e) => setPayment(e.target.value)}
                        style={{width: "100%"}}>
                            <option value='Gcash'>Gcash</option>
                            <option value='Gotyme'>Gotyme</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Account Number</label>
                        <Input
                          name="accountnumber"
                          placeholder={mydetail.accountnumber}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
        
        <MDBBtn type="submit">Save</MDBBtn>
      </MDBCardBody>
    </form>
    </MDBCard>
    )
}

export default PaymentDetails;