import React , {useState, useEffect}from "react";
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon,
} from 'mdb-react-ui-kit';
import { 
    MDBTable, 
    MDBTableHead, 
    MDBTableBody, 
    MDBContainer, 
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSpinner
 } from 'mdb-react-ui-kit';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
  } from "reactstrap"
import Swal from "sweetalert2";
import PaginationPager from "components/Pagination/pagination";
import debounce from 'lodash.debounce';


const SuperAdminManageAccount = () => {
    const [basicActive, setBasicActive] = useState('tab1');
     const [basicModal, setBasicModal] = useState(false);
    const [staff, setStaff] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0),
     [page2, setPage2] = useState(1),
    [total2, setTotal2] = useState(0)

    const [show, setShow] = useState('password')
    const [user, setUser] = useState([]);


    const toggleOpen = () => setBasicModal(!basicModal);

    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
    };

     useEffect(() => {
      if(basicActive === 'tab1'){
         setIsLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}/staffuser/getadminlist?page=${page - 1}&limit=10`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }

        if(data.message == "success"){
          setIsLoading(false)
          setStaff(data.data.users)
          setTotal(data.data.totalPages)
        } else if (data.message == "failed"){
          setIsLoading(false)
            Swal.fire({
                title: data.message,
                icon: "info",
                text: data.data
            })
        }

      })
      }
     
    },[page])

    const [searchadmin, setSerachadmin] = useState('')

    useEffect(() => {
    const timer = setTimeout(() => {
       if(searchadmin !== '' && basicActive === 'tab1') {
         fetch(`${process.env.REACT_APP_API_URL}/staffuser/searchadminlist?page=0&adminusername=${searchadmin}&limit=10`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }

        if(data.message == "success"){
          setIsLoading(false)
          setStaff(data.data.users)
          setTotal(data.data.totalPages)
        } else if (data.message == "failed"){
          setIsLoading(false)
            Swal.fire({
                title: data.message,
                icon: "info",
                text: data.data
            })
        }

      })
      }
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
    }, [searchadmin]);

    const clearAdmin = async () => {
      fetch(`${process.env.REACT_APP_API_URL}/staffuser/getadminlist?page=${page - 1}&limit=10`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }
        setSerachadmin('')
          setIsLoading(false)

        if(data.message == "success"){
          setIsLoading(false)
          setStaff(data.data.users)
          setTotal(data.data.totalPages)
        } else if (data.message == "failed"){
          setIsLoading(false)
            Swal.fire({
                title: data.message,
                icon: "info",
                text: data.data
            })
        }

      })
    }
     const handleCreate = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const {username, password} = e.target
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/auth/registerstaffs`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: username.value,
                        password: password.value
                    })
                  }).then(result => result.json())
                  .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }

                    if(data.message == "success"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: "Admin account created successfully"
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                  })
            } else {
              setIsLoading(false)
            }
          });
       
    }

      const handleBan = (user, status) => {
      setIsLoading(true)
      Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then((result) => {
          if (result.isConfirmed) {
              fetch(`${process.env.REACT_APP_API_URL}/staffuser/multiplebanstaffusers`,{
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      status: status,
                      staffuserlist: [user]
                  })
                }).then(result => result.json())
                .then(data => {
                  if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                      Swal.fire({
                        icon: "error",
                        title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                        text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                        allowOutsideClick: false,
                        allowEscapeKey: false
                      }).then(ok => {
                        if(ok.isConfirmed){
                          window.location.href = "/login";
                        }
                      })
                  }

                  if(data.message == "success"){
                      setIsLoading(false)
                      Swal.fire({
                          title: data.message,
                          icon: "success",
                          text: `Admin account ${status === 'banned' ? 'banned' : 'unbanned'} successfully`
                      }).then(ok => {
                          if(ok.isConfirmed){
                              window.location.reload()
                          }
                      })
                  } else if (data.message == "failed"){
                      setIsLoading(false)
                      Swal.fire({
                          title: data.message,
                          icon: "info",
                          text: data.data
                      })
                  }
                })
          } else {
              setIsLoading(false)
            }
        });
     
    }

    const handleChangePassword = async (user, username) => {
      const { value: password } = await Swal.fire({
        title: `Change password for ${username}`,
        // input: "password",
        // inputLabel: "Password",
        // inputPlaceholder: "Enter your password",
        // inputAttributes: {
        //   maxlength: "10",
        //   autocapitalize: "off",
        //   autocorrect: "off"
        // },
         html:`
            <div style="position: relative;display:flex;align-items:center;justify-content:center">
              <input id="password-input" type="password" class="swal2-input" placeholder="Enter password" maxlength="10" style="width: 100%;" />
              <i id="toggle-password" class="fa fa-eye-slash" style="position: absolute; right: 60px; top: 65%; transform: translateY(-50%); cursor: pointer;"></i>
            </div>
        `,
        didOpen: () => {
          const togglePassword = document.getElementById("toggle-password");
          const passwordInput = document.getElementById("password-input");

          togglePassword.addEventListener("click", () => {
            // Toggle the password visibility
            const type = passwordInput.type === "password" ? "text" : "password";
            passwordInput.type = type;

            // Toggle the icon
            togglePassword.classList.toggle("fa-eye-slash");
            togglePassword.classList.toggle("fa-eye");
          });
        },
         customClass: {
          popup: 'no-scroll'
        },
         preConfirm: () => {
          const password = Swal.getPopup().querySelector("#password-input").value;
          if (!password) {
            Swal.showValidationMessage("You need to write something!");
            return false;
          }
          return password;
        }
      });

      if(password){
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/staffuser/updateadmin`,{
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              staffusername: user,
              password: password
          })
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
              Swal.fire({
                icon: "error",
                title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(ok => {
                if(ok.isConfirmed){
                  window.location.href = "/login";
                }
              })
          }

          if(data.message == "success"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "success",
                  text: "Admin password change successfully"
              }).then(ok => {
                  if(ok.isConfirmed){
                      window.location.reload()
                  }
              })
          } else if (data.message == "failed"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
          }
        })
      } else {
        setIsLoading(false)
      }
     
    }

    //user
    useEffect(() => {
        if(basicActive === 'tab2'){
           setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/user/getplayerlist?page=${page2 - 1}&limit=10`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }

  
          if(data.message == "success"){
            setIsLoading(false)
            setUser(data.data.userlist)
            setTotal2(data.data.totalPages)
          } else if (data.message == "failed"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
          }
  
        })
        }
     
    },[page2])

    const handleUserBan = (user, status) => {
      setIsLoading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/user/banunbanuser`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        status: status,
                        userid: user
                    })
                  }).then(result => result.json())
                  .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
  
                    if(data.message == "success"){
                      setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: `Player account ${status === 'banned' ? 'banned' : 'unbanned'} successfully`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                      setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                  })
            } else {
              setIsLoading(false)
            }
          });
      
    }

    const handleChangeUserPassword = async (user, username) => {
      const { value: password } = await Swal.fire({
        title: `Change password for ${username}`,
        // input: "password",
        // inputLabel: "Password",
        // inputPlaceholder: "Enter password",
        // inputAttributes: {
        //   maxlength: "10",
        //   autocapitalize: "off",
        //   autocorrect: "off"
        // },
        html:`
            <div style="position: relative;display:flex;align-items:center;justify-content:center">
              <input id="password-input" type="password" class="swal2-input" placeholder="Enter password" maxlength="10" style="width: 100%;" />
              <i id="toggle-password" class="fa fa-eye-slash" style="position: absolute; right: 60px; top: 65%; transform: translateY(-50%); cursor: pointer;"></i>
            </div>
        `,
        didOpen: () => {
          const togglePassword = document.getElementById("toggle-password");
          const passwordInput = document.getElementById("password-input");

          togglePassword.addEventListener("click", () => {
            // Toggle the password visibility
            const type = passwordInput.type === "password" ? "text" : "password";
            passwordInput.type = type;

            // Toggle the icon
            togglePassword.classList.toggle("fa-eye-slash");
            togglePassword.classList.toggle("fa-eye");
          });
        },
         customClass: {
          popup: 'no-scroll'
        },
         preConfirm: () => {
          const password = Swal.getPopup().querySelector("#password-input").value;
          if (!password) {
            Swal.showValidationMessage("You need to write something!");
            return false;
          }
          return password;
        }
      });

      if(password){
      setIsLoading(true)

        fetch(`${process.env.REACT_APP_API_URL}/user/changepassworduserforadmin`,{
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              playerid: user,
              password: password
          })
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
              Swal.fire({
                icon: "error",
                title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(ok => {
                if(ok.isConfirmed){
                  window.location.href = "/login";
                }
              })
          }

          if(data.message == "success"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "success",
                  text: "User password change successfully"
              }).then(ok => {
                  if(ok.isConfirmed){
                      window.location.reload()
                  }
              })
          } else if (data.message == "failed"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
          }
        })
      } else {
        setIsLoading(false)
      }
      
    }

    const [usersearch, ssetUsersearch] = useState('')

 

      useEffect(() => {
    const timer = setTimeout(() => {
        if (usersearch !== '' && basicActive === 'tab2') {
        fetch(`${process.env.REACT_APP_API_URL}/user/searchplayerlist?playerusername=${usersearch}&page=${page2 - 1}&limit=10`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }
            setIsLoading(false)
  
          if(data.message == "success"){
            setIsLoading(false)
            setUser(data.data.userlist)
            setTotal2(data.data.totalPages)
          } else if (data.message == "failed"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
          }
  
        })
      }
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
    }, [usersearch]);

    const clearUser = async () => {
        fetch(`${process.env.REACT_APP_API_URL}/user/getplayerlist?page=${page2 - 1}&limit=10`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }
          ssetUsersearch('')
            setIsLoading(false)

          if(data.message == "success"){
            setIsLoading(false)
            setUser(data.data.userlist)
            setTotal2(data.data.totalPages)
          } else if (data.message == "failed"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
          }
  
        })
    }


    useEffect(() => {
    if( basicActive === 'tab1'){
      clearAdmin()
    }
      if( basicActive === 'tab2'){
      clearUser()
    }
     setPage(1)
     setPage2(1)
    },[basicActive])


    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
     const handleMultipleUserBan = (user, status) => {
      setIsLoading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/user/multiplebanusers`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        status: selectedStatus,
                        userlist: selectedRows
                    })
                  }).then(result => result.json())
                  .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
  
                    if(data.message == "success"){
                      setIsLoading(false)
                      setSelectedRows([])
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: "Player account banned successfully"
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                      setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                  })
            } else {
              setIsLoading(false)
            }
          });
      
    }

     const handleCheckboxChange = (event, userIdObject) => {
  if (event.target.checked) {
    // Add the user ID object to the selected rows if checked
    setSelectedRows([...selectedRows, userIdObject]);
  } else {
    // Remove the user ID object from the selected rows if unchecked
    setSelectedRows(selectedRows.filter((obj) => obj.userid !== userIdObject.userid));
  }
};

    //count
    const [count1, setCount1] = useState()
    const [count2, setCount2] = useState()
    const [count3, setCount3] = useState()


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/user/getplayercount`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }
          setIsLoading(false)
          setCount1(data.data.totalusers)
          setCount2(data.data.activeusers)
          setCount3(data.data.banusers)
          // setCount(data.data)
  
      
        })
        
    },[])




    return (
        <div className="content">
          {basicActive === 'tab2' && (
              <Row>
          
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-users text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Registered Users</p>
                        <CardTitle tag="p" className=" text-4xl font-semibold" >
                          
                        {count1}
                        </CardTitle>
                          
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update Now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
           
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Activated Users</p>
                        <CardTitle tag="p"  className=" text-4xl font-semibold"  >
                        {count2}
                        </CardTitle>
                        
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-calendar" /> Last day
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
          
             <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-users text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Banned Users</p>
                        <CardTitle tag="p" className=" text-4xl font-semibold">
                         {count3}
                        </CardTitle>
                        
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            
        
          </Row>
          )}
           
         

          <div className=" flex md:flex-row flex-col items-center justify-between">
             <MDBTabs className='mb-3' pills>
                <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                <MDBIcon fas icon="user-tie" className='me-2'/>Admin
                </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                <MDBIcon fas icon="user-alt" className='me-2'/>Player
                </MDBTabsLink>
                </MDBTabsItem>
                {/* <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                <MDBIcon fas icon='hand-holding-usd' className='me-2' /> Commission History
                </MDBTabsLink>
                </MDBTabsItem> */}

              
            </MDBTabs>

            <div className=" flex lg:flex-row flex-col items-center gap-2">
              { basicActive === 'tab1' && (
                <MDBBtn 
                color="success"
                onClick={toggleOpen}
                >
                    Create Admin Account
                </MDBBtn>
              )  }

                {/* { basicActive === 'tab2' && (
                <MDBBtn 
                disabled
                color="primary"
                onClick={toggleOpen}
                >
                Delete
                </MDBBtn>
              )  } */}

               { basicActive === 'tab2' && (
                <MDBBtn 
                color="info"
                onClick={handleMultipleUserBan}
                >
                Ban / Unban Multiple
                </MDBBtn>
              )  }

               { basicActive === 'tab1' && (
                <div className=" flex items-center justify-end gap-1 lg:mr-11">
                    <input value={searchadmin} onChange={(e) => setSerachadmin(e.target.value)} placeholder="Search admin" className=" h-10 rounded-md p-2"/>
                    {/* <MDBBtn onClick={searchByusername}>Search</MDBBtn> */}
                    <MDBBtn color="warning" className=" mr-3" onClick={clearAdmin}>Clear</MDBBtn>
                </div>
              )  }

               { basicActive === 'tab2' && (
                <div className=" flex items-center justify-end gap-1 lg:mr-11">
                    <input value={usersearch} onChange={(e) => ssetUsersearch(e.target.value)} placeholder="Search user" className=" h-10 rounded-md p-2"/>
                    {/* <MDBBtn onClick={searchByusername}>Search</MDBBtn> */}
                    <MDBBtn color="warning" className=" mr-3" onClick={clearUser}>Clear</MDBBtn>
                </div>
              )  }
                

               
            </div>

              
          </div>



        <MDBTabsContent>
            <MDBTabsPane open={basicActive === 'tab1'}>
             <MDBContainer>
            {/* <MDBBtn 
            color="success"
            onClick={toggleOpen}
            >
                Create Admin Account
            </MDBBtn> */}
            <MDBTable small>
                
            <MDBTableHead>
                <tr className="text-center">
                <th scope='col'>Date</th>
                <th scope='col'>Username</th>
                <th scope='col'>Action</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
              {
                staff.length !== 0 ?
                  staff.map((data, i) => (
                  <tr key={i} className="text-center">
                      <td>
                      {new Date(data.createdAt).toLocaleString()}
                      </td>
                      <td>{data.username}</td>
                      <td>
                      <MDBBtn disabled={isloading} size="sm" className="m-1" color="info" onClick={() => handleChangePassword(data.username, data.username)}>{isloading ? <MDBSpinner size="sm"/> : 'Change Password'}</MDBBtn>
                      {
                        data.status == "active" ? 
                      <MDBBtn disabled={isloading} size="sm" className="m-1" color="danger" onClick={() => handleBan(data.userid, "banned")}>{isloading ? <MDBSpinner size="sm"/> : 'Ban'}</MDBBtn>
                      :
                      <MDBBtn disabled={isloading} size="sm" className="m-1" color="warning" onClick={() => handleBan(data.userid, "active")}>{isloading ? <MDBSpinner size="sm"/> : 'Unban'}</MDBBtn>
                      }
                      
                      </td>
                  </tr>
                ))
                :
                <tr className="text-center">
                <td colSpan={3}>No Data</td>
                </tr>
              }
                
            </MDBTableBody>
            </MDBTable>
            <PaginationPager
            
                total={total} 
                page={page} 
                setPage={setPage}
                isloading={isloading}
            />
              </MDBContainer>
            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab2'}> 
             <MDBContainer>
    
            <MDBTable small>
                
            <MDBTableHead>
                <tr className="text-center">
                  <th>Select</th>
                <th scope='col'>Date Joined</th>
                <th scope='col'>Username</th>
                <th scope='col'>Contact Number</th>
                <th scope='col'>Sponsor</th>
                <th scope='col'>Status</th>
                <th scope='col'>Action</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
              {
                user.length !== 0 ?
                  user.map((data, i) => (
                  <tr key={i} className="text-center">
                    <td>
                      <input type="checkbox"
                       onChange={(event) => { handleCheckboxChange(event, { userid: data.id, banreason: '' });setSelectedStatus(data.status === 'active' ? 'banned' : 'active')}}
                      />
                    </td>
                      <td>
                      {new Date(data.createdAt).toLocaleString()}
                      </td>
                      <td>{data.username}</td>
                      <td>{data.phonenumber}</td>
                      <td>{data.referralUsername}</td>
                      <td>{data.status}</td>
                      <td className=" flex items-end justify-end">
                      <MDBBtn className="m-1" size="sm"
                      onClick={() => {
                        const url = `${window.location.origin}/viewplayer/${data.id}/${data.username}/${data.status}`;
                        localStorage.setItem('userId',data.id);
                        window.open(url, '_blank');
                      }}
                       >View</MDBBtn> 
                      <MDBBtn disabled={isloading} size="sm" className="m-1" color="info" onClick={() => handleChangeUserPassword(data.id, data.username)}>{isloading ? <MDBSpinner size="sm"/> : 'Change Password'}</MDBBtn>
                      {
                        data.status == "active" ? 
                      <MDBBtn disabled={isloading} size="sm" className="m-1 w-[80px]" color="danger" onClick={() => handleUserBan(data.id, "banned")}>{isloading ? <MDBSpinner size="sm"/> : 'Ban'}</MDBBtn>
                      :
                      <MDBBtn disabled={isloading} size="sm" className="m-1 w-[80px]" color="warning" onClick={() => handleUserBan(data.id, "active")}>{isloading ? <MDBSpinner size="sm"/> : 'Unban'}</MDBBtn>
                      }
                      
                      </td>
                  </tr>
                ))
                :
                <tr className="text-center">
                <td colSpan={3}>No Data</td>
                </tr>
              }
            </MDBTableBody>
            </MDBTable>
            <PaginationPager
                total={total2} 
                page={page2} 
                setPage={setPage2}
                isloading={isloading}
            />
            </MDBContainer>
            </MDBTabsPane>
            {/* <MDBTabsPane open={basicActive === 'tab3'}>
            <CommissionHistory/>
            </MDBTabsPane> */}
        </MDBTabsContent>

        <MDBModal  open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Create Admin Account</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <form onSubmit={handleCreate}>
            <MDBModalBody>
            <label>Username</label>
            <MDBInput name="username" type="text" label="Input Username here"/>
            <label>Password</label>
            <MDBInput name="password" type="password" label="Input Password here"/>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn disabled={isloading} type="submit">{isloading ? <MDBSpinner size="sm"/> : 'Add '}</MDBBtn>
            </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
        </div>
    )
}

export default SuperAdminManageAccount;