import React, { useState, useEffect } from "react";
import { 
    MDBTable, 
    MDBTableHead, 
    MDBTableBody, 
    MDBContainer, 
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSpinner,
 } from 'mdb-react-ui-kit';
import Swal from "sweetalert2";
import PaginationPager from "components/Pagination/pagination";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
  } from "reactstrap"
const Amanageplayer = () => {
    const [user, setUser] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

    
    const [gamepayoutlist, setGamePayoutList] = useState([])
    const [search, setSearch] = useState('')



    useEffect(() => {
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/user/getplayerlist?page=${page - 1}&limit=10`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }
  
          if(data.message == "success"){
            setIsLoading(false)
            setUser(data.data.userlist)
            setTotal(data.data.totalPages)
          } else if (data.message == "failed"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
          }
  
        })
    },[page])

    const handleBan = (user, status) => {
      setIsLoading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/user/banunbanuser`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        status: status,
                        userid: user
                    })
                  }).then(result => result.json())
                  .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
  
                    if(data.message == "success"){
                      setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: "Admin account banned successfully"
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                      setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                  })
            } else {
              setIsLoading(false)
            }
          });
      
    }

    const handleChangePassword = async (user) => {
      const { value: password } = await Swal.fire({
        title: "Enter password",
        input: "password",
        inputLabel: "Password",
        inputPlaceholder: "Enter password",
        inputAttributes: {
          maxlength: "10",
          autocapitalize: "off",
          autocorrect: "off"
        },
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        }
      });

      if(password){
      setIsLoading(true)

        fetch(`${process.env.REACT_APP_API_URL}/user/changepassworduserforadmin`,{
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              playerid: user,
              password: password
          })
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
              Swal.fire({
                icon: "error",
                title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(ok => {
                if(ok.isConfirmed){
                  window.location.href = "/login";
                }
              })
          }

          if(data.message == "success"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "success",
                  text: "User password change successfully"
              }).then(ok => {
                  if(ok.isConfirmed){
                      window.location.reload()
                  }
              })
          } else if (data.message == "failed"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
          }
        })
      } else {
        setIsLoading(false)
      }
      
    }

    // useEffect(() => {
     
    // searchByusername()
    // },[search])
  

    

    const resetFilter = async () => {
          setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page - 1}&limit=10&type=gamebalance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setSearch('')
                setGamePayoutList(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(true)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
    }

    const [usersearch, ssetUsersearch] = useState('')

    useEffect(() => {

    if (usersearch !== ""){
      const timer = setTimeout(() => {
          fetch(`${process.env.REACT_APP_API_URL}/user/searchplayerlist?playerusername=${usersearch}&page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
          }).then(result => result.json())
          .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
              Swal.fire({
                icon: "error",
                title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(ok => {
                if(ok.isConfirmed){
                  window.location.href = "/login";
                }
              })
            }
              setIsLoading(false)
    
            if(data.message == "success"){
              setIsLoading(false)
              setUser(data.data.userlist)
              setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
              setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
    
          })
      }, 1000); // delay for 1 second
  
      return () => clearTimeout(timer);
    }
    }, [usersearch]);

    const clearUser = async () => {
        ssetUsersearch("")
        fetch(`${process.env.REACT_APP_API_URL}/user/getplayerlist?page=${page - 1}&limit=10`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }
          ssetUsersearch('')
            setIsLoading(false)

          if(data.message == "success"){
            setIsLoading(false)
            setUser(data.data.userlist)
            setTotal(data.data.totalPages)
          } else if (data.message == "failed"){
            setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
          }
  
        })
    }

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
     const handleMultipleUserBan = (user, status) => {
      setIsLoading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/user/multiplebanusers`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        status: selectedStatus,
                        userlist: selectedRows
                    })
                  }).then(result => result.json())
                  .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
  
                    if(data.message == "success"){
                      setIsLoading(false)
                      setSelectedRows([])
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: "Player account banned successfully"
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                      setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                  })
            } else {
              setIsLoading(false)
            }
          });
      
    }

      const handleCheckboxChange = (event, userIdObject) => {
  if (event.target.checked) {
    // Add the user ID object to the selected rows if checked
    setSelectedRows([...selectedRows, userIdObject]);
  } else {
    // Remove the user ID object from the selected rows if unchecked
    setSelectedRows(selectedRows.filter((obj) => obj.userid !== userIdObject.userid));
  }
};


      //count
    const [count1, setCount1] = useState()
    const [count2, setCount2] = useState()
    const [count3, setCount3] = useState()


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/user/getplayercount`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }
          setIsLoading(false)
          setCount1(data.data.totalusers)
          setCount2(data.data.activeusers)
          setCount3(data.data.banusers)
          // setCount(data.data)
  
      
        })
        
    },[])



    
    return(
    <MDBContainer>
        <div className=" flex lg:flex-row w-full flex-col items-end justify-center gap-2 mb-4">
            <div className=" flex lg:flex-row flex-col items-center gap-2">

            
                

                <div className=" flex items-center justify-end gap-1 lg:mr-11">
                    <input value={usersearch} onChange={(e) => ssetUsersearch(e.target.value)} placeholder="Search" className=" h-10 rounded-md p-2"/>
                    {/* <MDBBtn onClick={searchByusername}>Search</MDBBtn> */}
                    <MDBBtn color="warning" className=" mr-3" onClick={clearUser}>Clear</MDBBtn>
                </div>
            </div>
       
          </div>
            <MDBTable small>
                
            <MDBTableHead>
                <tr className="text-center">
             
                <th scope='col'>Date Joined</th>
                <th scope='col'>Username</th>
                <th scope='col'>Sponsor</th>
                <th scope='col'>Status</th>
                <th scope='col'>Action</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
              {
                user.length !== 0 ?
                  user.map((data, i) => (
                  <tr key={i} className="text-center">
                   
                      <td>
                      {new Date(data.createdAt).toLocaleString()}
                      </td>
                      <td>{data.username}</td>
                      <td>{data.referralUsername}</td>
                      <td>{data.status}</td>
                      <td className=" flex items-end justify-end">
                      <MDBBtn className="m-1" size="sm"
                      onClick={() => {
                        const url = `${window.location.origin}/admin/viewplayer/${data.id}/${data.username}/${data.status}`;
                        localStorage.setItem('userId',data.id);
                        window.open(url, '_blank');
                      }}
                       >View</MDBBtn> 
                      {/* <MDBBtn disabled={isloading} size="sm" className="m-1" color="info" onClick={() => handleChangePassword(data.id)}>{isloading ? <MDBSpinner size="sm"/> : 'Change Password'}</MDBBtn> */}
                    
                      
                      </td>
                  </tr>
                ))
                :
                <tr className="text-center">
                <td colSpan={3}>No Data</td>
                </tr>
              }
            </MDBTableBody>
            </MDBTable>
            <PaginationPager
                total={total} 
                page={page} 
                setPage={setPage}
                isloading={isloading}
            />
    </MDBContainer>
    )
}

export default Amanageplayer;