import React , { useState, useEffect }from "react";
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon
} from 'mdb-react-ui-kit';
import { 
    MDBContainer, 
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBSwitch, MDBBtn, MDBSpinner} from "mdb-react-ui-kit"
import LeaderboardRank from "./Event/leaderboard";
import Swal from "sweetalert2";
import LeaderboardHistory from "./Event/leaderboardhistory";
import LeaderboardMole from "./Event/moleleaderboard";
import LeaderboardHistoryMole from "./Event/moleleaderboardhistory";
const SuperAdminEvent = () => {


    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
    };

    const [eventgame, setEventGame] = useState("")
    const [eventgamemole, setEventGameMole] = useState("")
    const [payoutgame, setPayoutGame] = useState("")
    const [loading, setLoading] = useState(false)
    const [currentMax, setCurrentmax] = useState('')
    const [moleMax, setMolemax] = useState('')


    useEffect(()=> {
        fetch(`${process.env.REACT_APP_API_URL}/maintenance/getmaintenance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                const Eg = data.data.maintenancelist.find(e => e.type == "eventgame")
                const Po = data.data.maintenancelist.find(e => e.type == "payout")
                const mole = data.data.maintenancelist.find(e => e.type == "moleventgame")
                setEventGame(Eg.value)
                setEventGameMole(mole.value)
                setPayoutGame(Po.value)
              } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[])

    //current max round creature smash
    useEffect(()=> {
        fetch(`${process.env.REACT_APP_API_URL}/event/getcurrentmaxround`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                console.log(data.data)
                setCurrentmax(data.data.maxround)
                
              } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[])

    const gamemaintenance = (type, ischecked) => {
        setLoading(true)
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/maintenance/changemaintenance`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        type: type,
                        value: ischecked
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }

                    if(data.message == "success"){
                        setLoading(false)
                        Swal.fire({
                            title: `Maintenance ${ischecked == "0" ? "Off": "On"}`,
                            icon: "success",
                            text: `Maintenance ${type} is now ${ischecked == "0" ? "Off": "On"}`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }

                })
            } else {
                setLoading(false)
            }
          });
    }

     const handleLeaderboardEventReset = () => {
        setLoading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/reset/reseteventleaderboard`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
        
                    if(data.message == "success"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: `Creature Smash Leaderboard Event has been Reset successfully`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                })
            } else {
                setLoading(false)
            }
          });
    }

    const handleLeaderboardEventResetMole = () => {
      setLoading(true)
      Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        }).then((result) => {
          if (result.isConfirmed) {
              fetch(`${process.env.REACT_APP_API_URL}/reset/reseteventleaderboardmole`,{
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json'
                  },
              }).then(result => result.json())
              .then(data => {
                  if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                      Swal.fire({
                        icon: "error",
                        title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                        text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                        allowOutsideClick: false,
                        allowEscapeKey: false
                      }).then(ok => {
                        if(ok.isConfirmed){
                          window.location.href = "/login";
                        }
                      })
                  }
      
                  if(data.message == "success"){
                      setLoading(false)
                      Swal.fire({
                          title: data.message,
                          icon: "success",
                          text: `Whack Attack Leaderboard Event has been Reset successfully`
                      }).then(ok => {
                          if(ok.isConfirmed){
                              window.location.reload()
                          }
                      })
                  } else if (data.message == "failed"){
                      setLoading(false)
                      Swal.fire({
                          title: data.message,
                          icon: "info",
                          text: data.data
                      })
                  }
              })
          } else {
              setLoading(false)
          }
        });
  }

    const updateMaxround = () => {
        setLoading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/event/seteventmaxround`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                       maxround: currentMax
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
        
                    if(data.message == "success"){
                        setCurrentmax('')
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: `Max round has been updated successfully`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                })
            } else {
                setLoading(false)
            }
          });
    }

    //current max round whack attack
    useEffect(()=> {
        fetch(`${process.env.REACT_APP_API_URL}/event/getcurrentmaxroundmole`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                console.log(data.data)
                setMolemax(data.data.maxround)
                
              } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[])

    const updateMaxroundmole = () => {
        setLoading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/event/seteventmaxroundmole`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                       maxround: moleMax
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
        
                    if(data.message == "success"){
                        setCurrentmax('')
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: `Max round has been updated successfully`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                })
            } else {
                setLoading(false)
            }
          });
    }

     const gamemaintenanceMole = (type, ischecked) => {
        setLoading(true)
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/maintenance/changemaintenance`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        type: 'moleventgame',
                        value: ischecked
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }

                    if(data.message == "success"){
                        setLoading(false)
                        Swal.fire({
                            title: `Maintenance ${ischecked == "0" ? "Off": "On"}`,
                            icon: "success",
                            text: `Maintenance ${type} is now ${ischecked == "0" ? "Off": "On"}`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }

                })
            } else {
                setLoading(false)
            }
          });
    }

     

    return (
        <div className="content">

            <MDBContainer >
               
                <MDBRow >
                    {/* <MDBCol lg={6} className=" h-[180px] mb-2">
                    <MDBCard className=" h-full">
                    <MDBCardBody className=" group rounded-lg flex justify-between" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                    <div className=" flex flex-col gap-1">
                             <img src="/Creature Smash Logo.png" width={100} className=" group-hover:scale-105 ease-in-out duration-300"/>
                            <MDBCardTitle className=" text-white">Event Game Control Panel</MDBCardTitle>
                        </div>
                       

                        <div className=" flex flex-col items-end text-white gap-2">
                            <input onChange={(e) => setCurrentmax(e.target.value)} type="text" placeholder={`Current Max Round (${currentMax})`} className=" p-1 rounded-md text-black md:w-[180px] w-[150px]"/>
                            <MDBBtn onClick={updateMaxround} disabled={loading}>{loading ? <MDBSpinner size="sm"/> : "Save"}</MDBBtn>
                            <div className=" flex items-center gap-4">
                                <p className=" text-xs text-zinc-200">Turn off/on event</p>
                                <MDBSwitch
                            disabled={loading} 
                            checked={eventgame  == "1" ? true : false}  name="eventgame" 
                            id='flexSwitchCheckDefault'
                            onChange={(e) => gamemaintenance(e.target.name,e.target.checked ? 1 : 0)}
                            />
                            </div>
                             

                        </div>
                    
                    </MDBCardBody>
                    </MDBCard>
                    </MDBCol>

                    <MDBCol lg={6} className=" h-[180px] mb-2">
                    <MDBCard className=" h-full">
                    <MDBCardBody className=" group rounded-lg flex justify-between" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                    <div className=" flex flex-col gap-1">
                             <img src="/walogo.png" width={80} className=" group-hover:scale-105 ease-in-out duration-300"/>
                            <MDBCardTitle className=" text-white">Event Game Control Panel</MDBCardTitle>
                        </div>
                       

                        <div className=" flex flex-col items-end text-white gap-2">
                            <input onChange={(e) => setMolemax(e.target.value)} type="text" placeholder={`Current Max Round (${moleMax})`} className=" p-1 rounded-md text-black md:w-[180px] w-[150px]"/>
                            <MDBBtn onClick={updateMaxroundmole} disabled={loading}>{loading ? <MDBSpinner size="sm"/> : "Save"}</MDBBtn>
                            <div className=" flex items-center gap-4">
                                <p className=" text-xs text-zinc-200">Turn off/on event</p>
                                <MDBSwitch
                            disabled={loading} 
                            checked={eventgamemole  == "1" ? true : false}  name="eventgame" 
                            id='flexSwitchCheckDefault'
                            onChange={(e) => gamemaintenanceMole(e.target.name,e.target.checked ? 1 : 0)}
                            />
                            </div>

                        </div>
                    
                    </MDBCardBody>
                    </MDBCard>
                    </MDBCol> */}

                     <MDBCol lg={6} className=" h-auto lg:h-[220px] mb-2">
                    <MDBCard className=" h-full">
                    <MDBCardBody className=" group rounded-lg flex md:flex-row flex-col items-center gap-8" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                      <div className=" flex flex-col gap-1">
                             <img src="/walogo.png" width={200} className=" group-hover:scale-105 ease-in-out duration-300"/>
                            
                        </div>
                       

                        <div className=" w-full flex items-center text-white gap-2 bg-zinc-950/50 p-2 rounded-md">

                          <div className=" flex flex-col gap-2 w-full">
                            <p className=" text-white font-semibold mb-2">Event Game Control Panel</p>
                            <input onChange={(e) => setMolemax(e.target.value)} type="text" placeholder={`Current Max Round (${moleMax})`} className=" p-2 rounded-md text-black w-full"/>
                            <MDBBtn onClick={updateMaxroundmole} disabled={loading}>{loading ? <MDBSpinner size="sm"/> : "Save"}</MDBBtn>
                            <div className=" flex items-center gap-4">
                                <p className=" text-xs text-zinc-200">Turn off/on event</p>
                                <MDBSwitch
                                disabled={loading} 
                                checked={eventgamemole  == "1" ? true : false}  name="moleventgame" 
                                id='flexSwitchCheckDefault'
                                onChange={(e) => gamemaintenanceMole(e.target.name,e.target.checked ? 1 : 0)}
                                />
                            </div>

                          
                          </div>


                            
                             

                            {/* <MDBBtn disabled={loading}>{loading ? <MDBSpinner size="sm"/> : "Reset Event"}</MDBBtn> */}

                        </div>
                    
                    </MDBCardBody>
                    </MDBCard>
                    </MDBCol>

                    <MDBCol lg={6} className=" h-auto lg:h-[220px] mb-2">
                    <MDBCard className=" h-full">
                    <MDBCardBody className=" group rounded-lg flex md:flex-row flex-col items-center gap-8" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                      <div className=" flex flex-col gap-1">
                             <img src="/Creature Smash Logo.png" width={200} className=" group-hover:scale-105 ease-in-out duration-300"/>
                            
                        </div>
                       

                        <div className=" w-full flex items-center text-white gap-2 bg-zinc-950/50 p-2 rounded-md">

                          <div className=" flex flex-col gap-2 w-full">
                            <p className=" text-white font-semibold mb-2">Event Game Control Panel</p>
                            <input onChange={(e) => setCurrentmax(e.target.value)} type="text" placeholder={`Current Max Round (${currentMax})`} className=" p-2 rounded-md text-black w-full"/>
                            <MDBBtn onClick={updateMaxround} disabled={loading}>{loading ? <MDBSpinner size="sm"/> : "Save"}</MDBBtn>
                            <div className=" flex items-center gap-4">
                                <p className=" text-xs text-zinc-200">Turn off/on event</p>
                                <MDBSwitch
                                disabled={loading} 
                                checked={eventgame  == "1" ? true : false}  name="eventgame" 
                                id='flexSwitchCheckDefault'
                                onChange={(e) => gamemaintenance(e.target.name,e.target.checked ? 1 : 0)}
                                />
                            </div>
                          </div>


                            
                             

                            {/* <MDBBtn disabled={loading}>{loading ? <MDBSpinner size="sm"/> : "Reset Event"}</MDBBtn> */}

                        </div>
                    
                    </MDBCardBody>
                    </MDBCard>
                    </MDBCol>
                    
                    <MDBCol lg={6} className=" h-auto mb-2">
                        <MDBCard className=" h-full">
                            <MDBCardBody className=" rounded-lg w-full grid grid-cols-1 md:grid-cols-2 gap-2 p-2" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>

                            <div className=" flex flex-col gap-2 items-center justify-center w-full h-full bg-zinc-950/50 rounded-md p-2">
                              <img src="/walogo.png" width={100} className=" group-hover:scale-105 ease-in-out duration-300"/>
                              <div className=" flex items-center gap-4">
                                <p className=" text-white font-semibold">Reset Event</p>
                                <MDBBtn onClick={handleLeaderboardEventResetMole}>{loading ? <MDBSpinner size="sm"/> : "Reset"}</MDBBtn>

                              </div>
                            </div>

                            <div className=" flex flex-col gap-2 items-center justify-center w-full h-full bg-zinc-950/50 rounded-md p-2">
                              <img src="/Creature Smash Logo.png" width={150} className=" group-hover:scale-105 ease-in-out duration-300"/>
                              <div className=" flex items-center gap-4">
                                <p className=" text-white font-semibold">Reset Event</p>
                                <MDBBtn disabled={loading} onClick={handleLeaderboardEventReset}>{loading ? <MDBSpinner size="sm"/> : "Reset"}</MDBBtn>

                              </div>
                            </div>
                            
                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    {/* <MDBCol lg={6} className=" h-[180px] mb-2">
                        <MDBCard className=" h-full">
                            <MDBCardBody className=" rounded-lg" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
                            <img src="/podium.png" width={60} className=" mb-2"/>
                                <MDBCardTitle className=" text-white">Reset Event</MDBCardTitle>
                                <MDBBtn disabled={loading} onClick={handleLeaderboardEventReset}>{loading ? <MDBSpinner size="sm"/> : "Reset"}</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol> */}
                    
                </MDBRow>
            </MDBContainer>

            <div>

            </div>


        <MDBTabs className='mb-3 mt-6' pills>
            <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
            <MDBIcon fas  icon="trophy" className='me-2'/>Creature Smash Leaderboard
            </MDBTabsLink>
            </MDBTabsItem>

            <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            <MDBIcon fas  icon="trophy" className='me-2'/>Whack Attack Leaderboard
            </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
            <MDBIcon fas icon="cog" className='me-2'/>Creature Smash Leaderboard History
            </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
           
            <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
            <MDBIcon fas icon="cog" className='me-2'/>Whack Attack Leaderboard History
            </MDBTabsLink>
            </MDBTabsItem>
           
            {/* <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
            <MDBIcon fas icon='hand-holding-usd' className='me-2' /> Commission History
            </MDBTabsLink>
            </MDBTabsItem> */}
        </MDBTabs>

        <MDBTabsContent>
            <MDBTabsPane open={basicActive === 'tab1'}>
            <LeaderboardRank/>
            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab2'}> 
            <LeaderboardMole/>
            </MDBTabsPane>

            <MDBTabsPane open={basicActive === 'tab3'}> 
            <LeaderboardHistory/>
            </MDBTabsPane>

            <MDBTabsPane open={basicActive === 'tab4'}> 
            <LeaderboardHistoryMole/>
            </MDBTabsPane>
            {/* <MDBTabsPane open={basicActive === 'tab3'}>
            <CommissionHistory/>
            </MDBTabsPane> */}
        </MDBTabsContent>
        </div>
    )
}

export default SuperAdminEvent;