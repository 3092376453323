import OwnCreatureCard from "components/Cards/Owncreature";
import PaginationPager from "components/Pagination/pagination";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Bgoblin from "assets/01-Bronze/01-Goblin.png"
import Btroll from "assets/01-Bronze/02-Troll.png"
import Bogre from "assets/01-Bronze/03-Ogre.png"
import Borc from "assets/01-Bronze/04-Orc.png"
import Inventory from "../Inventory";
const BronzeInventory = () => {
    const [goblin, setGoblin] = useState([]),
    [troll, setTroll] = useState([]),
    [ogre, setOgre] = useState([]),
    [orc, setOrc] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

  const [bronzelist, setBronzelist] = useState({})

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/inventory/getinventory?rank=bronze&page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
              setBronzelist(data.data)
                const bgoblin = data.data.b_goblin
                const btroll = data.data.b_troll
                const bogre = data.data.b_ogre  
                const borc = data.data.b_orc
                
                setGoblin(bgoblin)
                setOrc(borc)
                setTroll(btroll)
                setOgre(bogre)
                setIsLoading(false)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[page])


      const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const totalAccumulated = Object.keys(bronzelist).filter(key => key!== 'totalPages').reduce((acc, current) => {
  return acc + bronzelist[current].totalaccumulated;
  }, 0);

  useEffect(() => {
    localStorage.setItem('bronze',totalAccumulated)
  })
    

    return(
        <MDBContainer fluid>
            <MDBRow style={{gap:'1rem'}}>
              

                 {Object.keys(bronzelist).filter(key => key !== 'totalPages').map(( data, index) => (
                 
                   <OwnCreatureCard
                    title={`${bronzelist[data].type === "b_goblin" && 'Goblin' || bronzelist[data].type === "b_troll" && 'Troll' || bronzelist[data].type === "b_orc" && 'Orc' || bronzelist[data].type === "b_ogre" && 'Ogre'} `}
                    image={`${bronzelist[data].type === 'b_goblin' && Bgoblin || bronzelist[data].type === 'b_troll' && Btroll || bronzelist[data].type === 'b_orc' && Borc || bronzelist[data].type === 'b_ogre' && Bogre}`}
                    dailyaccumulated={`${(bronzelist[data].dailyaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(bronzelist[data].limitdaily).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}


                    dailyaccumulatedusd={`${(bronzelist[data].dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}  / $ ${(bronzelist[data].limitdaily / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}


                    totalaccumulated={`${(bronzelist[data].totalaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(bronzelist[data].limittotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}


                    totalaccumulatedusd={`${(bronzelist[data].totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / $ ${(bronzelist[data].limittotal / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}
                    creatureid={bronzelist[data].creatureid}

                    />
                ))}

               
                
            </MDBRow>
            <PaginationPager
                total={total} 
                page={page} 
                setPage={setPage}
                isloading={isloading}
            />
        </MDBContainer>
    )
}

export default BronzeInventory;