import React, {useState, useEffect} from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBInput,
    MDBRow,
    MDBCol,
    MDBSpinner
  } from 'mdb-react-ui-kit';
  import Swal from "sweetalert2";
  import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
const CashoutRequest = () => {
  const [mydetail, setMyDetail] = useState([])
  const [loading, setLoading] = useState(false)
  const [wallettype, setWalletType] = useState("commissionbalance")
  const [wallet, setWallet] = useState([])
  const [payment, setPayment] = useState('Gcash')


  const today = new Date().getDay();

  useEffect(()=> {
    fetch(`${process.env.REACT_APP_API_URL}/wallets/playerwallets`,{
    method: 'GET',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json'
    },
    }).then(result => result.json())
    .then(data => {
    if(data.message == "success"){
        setWallet(data.data)
    } else {
        Swal.fire({
        title: data.message,
        text: data.data,
        icon: "error"
        })
    }
    })
},[])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/getuserdetails`,{
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(result => result.json())
    .then(data => {
      if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
        Swal.fire({
          icon: "error",
          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if(ok.isConfirmed){
            window.location.href = "/login";
          }
        })
      }

      if(data.message == "success"){
        // console.log(data.data)
        setMyDetail(data.data)
      }  else {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        })
      }
    })
  },[])
    

  const [ rate, setRate] = useState(0)
    // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const [walletSelected, setWalletselected] = useState('commissionbalance')

  useEffect(() => {
  const walletType = localStorage.getItem('walletType')
  if(walletType === 'tab1'){
    setWalletselected('commissionbalance')
  }
   if(walletType === 'tab2'){
    setWalletselected('gamebalance')
  }
  })

  const [amount, setAmount] = useState('')
  const [accno, setAccno] = useState('')
  const [accname, setAccname] = useState('')


   const handleRequestPayout = (e) => {
      e.preventDefault();
      setLoading(true)
      const { amount } = e.target
  
      // if(mydetail.paymentmethod == "" || mydetail.accountnumber == ""){
      //   setLoading(false)
      //   return Swal.fire({
      //       title: "Please Update Payment Method",
      //       icon: "info",
      //       text: "please update your payment method first"
      //   }).then( ok => {
      //        if(ok.isConfirmed){
      //         window.location.href = `/user/profile`
      //       }

      //     })
      // }

      // if(wallettype == "gamebalance" && wallet.gamebalance == 0){
      //   setLoading(false)
      //   return Swal.fire({
      //       title: "Opps...",
      //       icon: "error",
      //       text: "the amount your enter is less than your current wallet balance"
      //   })
      // }

      // if(wallettype == "commissionmoney" && wallet.commissionbalance == 0){
      //   setLoading(false)
      //   return Swal.fire({
      //       title: "Opps...",
      //       icon: "error",
      //       text: "the amount your enter is less than your current wallet balance"
      //   })
      // }

      //   if( today !== 5){
      //    setLoading(false)
      //    return Swal.fire({
      //        title: "Opps...",
      //        icon: "error",
      //        text: "Cashout Request is only available every friday"
      //    })
      //  }

      if(walletSelected === ""){
        setLoading(false)
        return Swal.fire({
            title: "Please Select Wallet",
            icon: "info",
            text: "you did not select wallet please select one"
        })
      }

      if( payment === ''){
        setLoading(false)
        return Swal.fire({
            title: "Opps...",
            icon: "error",
            text: "Please select a payment method"
        })
      }

      if( accno === ''){
        setLoading(false)
        return Swal.fire({
            title: "Opps...",
            icon: "error",
            text: "Please enter your account number"
        })
      }

      if( accname === ''){
        setLoading(false)
        return Swal.fire({
            title: "Opps...",
            icon: "error",
            text: "Please enter your account name"
        })
      }

      if( amount > 5000 && wallettype === 'Gcash'){
         setLoading(false)
         return Swal.fire({
             title: "Opps...",
             icon: "error",
             text: "Limit is 5,000"
         })
      }

      if(walletSelected !== '' && payment !== '' && accname !== '' && accname !== '') {
          fetch(`${process.env.REACT_APP_API_URL}/payout/requestpayout`,{
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type: walletSelected,
          payoutvalue: amount.value,
          paymentmethod: payment,
          accountnumber: accno ,
          accountname: accname
        })
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }

        if(data.message == "success"){
          setLoading(false)
          Swal.fire({
            title: data.message,
            icon: "success",
            text: 'Payout request successful'
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.reload()
              
            }
          })
        } else if (data.message == "failed"){
          setLoading(false)
          Swal.fire({
            title: data.message,
            icon: "info",
            text: data.data
          })
        }
      })
      }
    
    }

    //  const handleRequestPayoutCommission = (e) => {
    //   e.preventDefault();
    //   setLoading(true)
    //   const { amount } = e.target
    

    //   if(walletSelected === ""){
    //     setLoading(false)
    //     return Swal.fire({
    //         title: "Please Select Wallet",
    //         icon: "info",
    //         text: "you did not select wallet please select one"
    //     })
    //   }

    //    if(commisionWithdraw == 'false'){
    //      setLoading(false)
    //      Swal.fire({
    //          title: "Request",
    //          icon: "info",
    //          text: "Please wait before your pending request is finished"
    //      })
    //    }

    //   if(payment !== mydetail.paymentmethod){
    //     setLoading(false)
    //     Swal.fire({
    //         title: "Payment Method",
    //         icon: "info",
    //         text: `Your current payment method is ${mydetail.paymentmethod}. Please update your payment method on youre profile to ${payment === 'Gotyme' ? 'Gotyme' : 'Gcash'}`
    //     })
    //      .then(ok => {
    //         if(ok.isConfirmed){
    //            window.location.href = `/user/profile`
    //          }

    //      })
    //   }

    //   if(mydetail.paymentmethod == "" || mydetail.accountnumber == ""){
    //     setLoading(false)
    //     return Swal.fire({
    //         title: "Please Update Payment Method",
    //         icon: "info",
    //         text: "please update your payment method first"
    //     }).then( ok => {
    //          if(ok.isConfirmed){
    //           window.location.href = `/user/profile`
    //         }

    //       })
    //   }

    //   // if(wallettype == "gamebalance" && wallet.gamebalance == 0){
    //   //   setLoading(false)
    //   //   return Swal.fire({
    //   //       title: "Opps...",
    //   //       icon: "error",
    //   //       text: "the amount your enter is less than your current wallet balance"
    //   //   })
    //   // }

    //   // if(wallettype == "commissionmoney" && wallet.commissionbalance == 0){
    //   //   setLoading(false)
    //   //   return Swal.fire({
    //   //       title: "Opps...",
    //   //       icon: "error",
    //   //       text: "the amount your enter is less than your current wallet balance"
    //   //   })
    //   // }

    //   // if( amount > 5000 && wallettype === 'Gcash'){
    //   //   setLoading(false)
    //   //   return Swal.fire({
    //   //       title: "Opps...",
    //   //       icon: "error",
    //   //       text: "Limit is 5,000"
    //   //   })
    //   // }

    //   //   if( today !== 5){
    //   //    setLoading(false)
    //   //    return Swal.fire({
    //   //        title: "Opps...",
    //   //        icon: "error",
    //   //        text: "Cashout Request is only available every friday"
    //   //    })
    //   //  }

    //    if( payment === ''){
    //     setLoading(false)
    //     return Swal.fire({
    //         title: "Opps...",
    //         icon: "error",
    //         text: "Please select a payment method"
    //     })
    //   }

    //   if(payment === mydetail.paymentmethod  && commisionWithdraw === 'true') {
    //       fetch(`${process.env.REACT_APP_API_URL}/payout/requestpayout`,{
    //     method: 'POST',
    //     credentials: 'include',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //       type: 'commissionbalance',
    //       payoutvalue: amount.value,
    //       paymentmethod: payment
    //     })
    //   }).then(result => result.json())
    //   .then(data => {
    //     if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          
    //       Swal.fire({
    //         icon: "error",
    //         title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
    //         text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
    //         allowOutsideClick: false,
    //         allowEscapeKey: false
    //       }).then(ok => {
    //         if(ok.isConfirmed){
    //           window.location.href = "/login";
    //         }
    //       })
    //     }

    //     if(data.message == "success"){
    //       setLoading(false)
    //       Swal.fire({
    //         title: data.message,
    //         icon: "success",
    //         text: 'Payout request successfull'
    //       }).then(ok => {
    //         if(ok.isConfirmed){
    //           window.location.reload()
              
    //         }
    //       })
    //     } else if (data.message == "failed"){
    //       setLoading(false)
    //       Swal.fire({
    //         title: data.message,
    //         icon: "info",
    //         text: data.data
    //       })
    //     }
    //   })
    //   }

    
    // }

    useEffect(() => {
      setAmount('')
    },[payment])
 

    return(

    <MDBCard >
      <MDBCardBody >
        <MDBRow>
          <h2 className=" text-xl">Withdrawal</h2>
          {/* <p className=" text-zinc-400 text-sm mb-4">Players of Creature Smash must meet certain criteria to be eligible to withdraw their earnings. Players must have at least 500 Php or 10 USD in their Commission or Game Wallet as the minimum withdrawal amount, with a 5,000 Php maximum withdrawal for Gcash and no limit for Gotyme. Withdrawal requests can be made every Friday (12:00am to 11:59am) and will be released the following Friday (12:00pm to 11:59pm).</p> */}
          
         
        </MDBRow>
        <form onSubmit={handleRequestPayout} style={{backgroundColor:'#F0F0F0',padding:'1rem',borderRadius:'.5rem'}}>

          {/* <p style={{textAlign:'center',color:'#676767',fontSize:'.8rem'}}>Select Payment Method:</p>
          <MDBCol md="12" className="" style={{height:'auto',padding:'1rem',display:'flex',alignItems:'center',justifyContent:'center', gap:'1rem'}} >
            <p onClick={() => setPayment('Gcash')} className={`${payment === 'Gcash' ? ' bg-blue-600' : ' bg-blue-400' }`} style={{padding:'1rem',borderRadius:'.5rem'}}>
              <img src="/gcash.png" width={100}/>
            </p>

            <p onClick={() => setPayment('Gotyme')} className={`${payment === 'Gotyme' ? ' bg-blue-600' : ' bg-blue-400' }`} style={{padding:'1rem',borderRadius:'.5rem'}}>
             <img src="/gotyme.jpeg" width={90} style={{borderRadius:'.5rem'}}/>
            </p>
          </MDBCol>

          <p className=" mb-4" style={{textAlign:'center',color:'#676767',fontSize:'.8rem'}}>Selected Payment Method: <span className=" text-blue-500 text-sm font-semibold">{payment}</span></p> */}
      
        <MDBRow>
           <MDBCol lg={6} className=" mt-2">
            <label className="fw-bold text-black">PAYOUT METHOD:</label>
             <select        
              value={payment}
              name="paymentmethod" 
              className=" border-[2px] border-zinc-400 px-4 py-[8px] rounded-md" 
              onChange={(e) => setPayment(e.target.value)}
              style={{width: "100%"}}>
                  <option value='Gcash'>Gcash</option>
                  <option value='Gotyme'>Gotyme</option>
              </select>
            </MDBCol> 

            <MDBCol lg={6} className=" mt-2">
            <label className="fw-bold text-black">ACCOUNT NAME:</label>
            <MDBInput maxLength={50} required value={accname} onChange={(e) => setAccname(e.target.value)} type='text' name='accname'/>
            </MDBCol> 
          
            <MDBCol lg={6} className=" mt-2">
            <label className="fw-bold text-black">ACCOUNT NUMBER:</label>
            <MDBInput required maxLength={30} value={accno} onChange={(e) => setAccno(e.target.value)} type="number" name="accnumber"/>
            </MDBCol> 
            <MDBCol lg={6} className=" mt-2">
            <label className="fw-bold text-black">ENTER AMOUNT:</label>
            <MDBInput name="amount" type="number" onChange={(e) => setAmount(e.target.value)} value={amount} min={500} max={payment === 'Gcash' ? 5000 : 999999999999} required/>
            </MDBCol>
        </MDBRow>
        
        
        <MDBRow className="mt-2 align-items-center">
            

             <MDBCol className="text-center">
            <MDBBtn disabled={loading} type="submit">{loading ? <MDBSpinner size="sm"/> : 'Request'}</MDBBtn>
            </MDBCol>
            
        </MDBRow>

        {walletSelected === 'commissionbalance' && (
          <div>
            <div className="numbers" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginTop:'2rem'}}>
                      <p className="card-category"  style={{fontSize:'1.4rem',margin:'0'}}>Commission Wallet Ballance</p>
                      <CardTitle tag="p">
                      ₱ {wallet.commissionbalance?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                       
                      </CardTitle>
                        <CardTitle tag="p" style={{color:'grey',fontSize:'1.5rem'}}>
                      $ {(wallet.commissionbalance / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
            </div>
        </div>
        )}

        {walletSelected === 'gamebalance' && (
          <div>
            <div className="numbers" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginTop:'2rem'}}>
                      <p className="card-category"  style={{fontSize:'1.4rem',margin:'0'}}>Game Wallet Ballance</p>
                      <CardTitle tag="p">
                      ₱ {wallet.gamebalance?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                       
                      </CardTitle>
                        <CardTitle tag="p" style={{color:'grey',fontSize:'1.5rem'}}>
                      $ {(wallet.gamebalance / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
            </div>
        </div>
        )}


        </form>
        
        
      </MDBCardBody>
    </MDBCard>
    )
}

export default CashoutRequest;