import PaginationPager from "components/Pagination/pagination";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import OwnMoleCard from "components/Cards/Ownmole";

//wa steel images

import viking from "assets/Whack Attack Game Tab/3 - Viking Tier/1 - viking mole.png"
import shogun from "assets/Whack Attack Game Tab/3 - Viking Tier/2 - Shogun mole.png"
import samurai from "assets/Whack Attack Game Tab/3 - Viking Tier/3 - Samurai Mole.png"
import paroah from "assets/Whack Attack Game Tab/3 - Viking Tier/4 - Pharaoh Mole.png"
import king from "assets/Whack Attack Game Tab/3 - Viking Tier/5 - King Mole.png"

const SteelInventory = () => {
   
    const [normal, setNormal] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

  const [steellist, setSteellist] = useState({})

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/inventory/getinventory?rank=steel&page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
              setSteellist(data.data)
                
                setIsLoading(false)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[page])


    const [ rate, setRate] = useState(0)
    // rate
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        })
        .then(result => result.json())
        .then(data => {
        if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
            Swal.fire({
            icon: "error",
            title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
            text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
            }).then(ok => {
            if (ok.isConfirmed) {
                window.location.href = "/login";
            }
            });
        } else if (data.message === "success") {
            setRate(data.data.rate)
        } else if (data.message === "failed") {
            Swal.fire({
            title: data.message,
            icon: "info",
            text: data.data
            });
        }
        })
        .catch(error => {
        console.error('Error fetching data:', error);
        });
    }, []);

    const totalAccumulated = Object.keys(steellist).filter(key => key!== 'totalPages').reduce((acc, current) => {
    return acc + steellist[current].totalaccumulated;
    }, 0);

    useEffect(() => {
        localStorage.setItem('bronze',totalAccumulated)
    })
    

    return(
        <MDBContainer fluid>
            <MDBRow style={{gap:'1rem'}}>
              

                 {Object.keys(steellist).filter(key => key !== 'totalPages').map(( data, index) => (
                 
                   <OwnMoleCard
                    title={`${steellist[data].type === "viking_mole" && 'Viking Mole' || steellist[data].type === "shogun_mole" && 'Shogun Mole' || steellist[data].type === "samurai_mole" && 'Samurai Mole' || steellist[data].type === "pharaoh_mole" && 'Pharaoh Mole' || steellist[data].type === "king_mole" && 'King Mole'}  `}


                    image={`${steellist[data].type === 'viking_mole' && viking || steellist[data].type === 'shogun_mole' && shogun || steellist[data].type === 'samurai_mole' && samurai || steellist[data].type === 'pharaoh_mole' && paroah || steellist[data].type === 'king_mole' && king}`}


                    dailyaccumulated={`${(steellist[data].dailyaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(steellist[data].limitdaily || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}


                    dailyaccumulatedusd={`${(steellist[data].dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}  / $ ${(steellist[data].limitdaily / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}


                    totalaccumulated={`${(steellist[data].totalaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(steellist[data].limittotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}


                    totalaccumulatedusd={`${(steellist[data].totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / $ ${(steellist[data].limittotal / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}


                    creatureid={steellist[data].creatureid}

                    />
                ))}

               
                
            </MDBRow>
            <PaginationPager
                total={total} 
                page={page} 
                setPage={setPage}
                isloading={isloading}
            />
        </MDBContainer>
    )
}

export default SteelInventory;