import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import MoleInventory from './table/moleinventory';
import CreatureInventory from './table/creaturesinventory';

const ViewInventory = ({id}) => {
    const [verticalActive, setVerticalActive] = useState('tab1');
    const [verticalActive2, setVerticalActive2] = useState('tab1');

    const handleVerticalClick = (value) => {
    if (value === verticalActive) {
      return;
    }

    setVerticalActive(value);
    };

    const handleVerticalClick2 = (value) => {
      if (value === verticalActive) {
        return;
      }
  
      setVerticalActive2(value);
      };

    
    return(
        <MDBCol className=' px-4'>
          <MDBRow size='3'>
          <MDBTabs pills className=' text-center'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab1')} active={verticalActive === 'tab1'}>
                Creature Smash
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab2')} active={verticalActive === 'tab2'}>
               Whack Attack
              </MDBTabsLink>
            </MDBTabsItem>
            
          </MDBTabs>
         
        </MDBRow>

        <MDBCol size='12'>
          <MDBTabsContent>
            <MDBTabsPane open={verticalActive === 'tab1'}>
              <CreatureInventory id={id}/>
              
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab2'}>

             <MoleInventory id={id}/>
            </MDBTabsPane>
            
          </MDBTabsContent>
        </MDBCol>
        {/* <MDBRow size='3'>
          <p>Creature Smash</p>
          <MDBTabs pills className='flex text-center'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab1')} active={verticalActive === 'tab1'}>
                Epic
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab2')} active={verticalActive === 'tab2'}>
               Legend
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab3')} active={verticalActive === 'tab3'}>
                Mythic
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <p>Whack Attack</p>
          <MDBTabs pills className='flex text-center'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab4')} active={verticalActive === 'tab4'}>
                Wood
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab5')} active={verticalActive === 'tab5'}>
               Stone
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab6')} active={verticalActive === 'tab6'}>
                Steel
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBRow>
        <MDBCol size='9'>
          <MDBTabsContent>
            <MDBTabsPane open={verticalActive === 'tab1'}>
                <BronzeInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab2'}>
                <SilverInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab3'}>
                <GoldInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab4'}>
                <WoodInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab5'}>
                <StoneInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab6'}>
                <SteelInventory id={id}/>
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBCol> */}
      </MDBCol>
    )
}

export default ViewInventory;