import React, { useEffect, useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import WoodInventory from './wood';
import StoneInventory from './stone';
import SteelInventory from './steel';

const MoleInventory = ({id}) => {
    const [verticalActive, setVerticalActive] = useState('tab1');
   

    const handleVerticalClick = (value) => {
    if (value === verticalActive) {
      return;
    }

    setVerticalActive(value);
    };

   

    
    return(
        <MDBCol>
          

        <MDBCol size='12'>
         

              <MDBTabs pills className='flex text-center'>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleVerticalClick('tab1')} active={verticalActive === 'tab1'}>
                  Wood
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleVerticalClick('tab2')} active={verticalActive === 'tab2'}>
                Stone
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleVerticalClick('tab3')} active={verticalActive === 'tab3'}>
                  Steel
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
              <MDBTabsContent>
                
                <MDBTabsPane open={verticalActive === 'tab1'}>
                    <WoodInventory id={id}/>
                </MDBTabsPane>
                <MDBTabsPane open={verticalActive === 'tab2'}>
                    <StoneInventory id={id}/>
                </MDBTabsPane>
                <MDBTabsPane open={verticalActive === 'tab3'}>
                    <SteelInventory id={id}/>
                </MDBTabsPane>
              </MDBTabsContent>
            
        </MDBCol>
        
      </MDBCol>
    )
}

export default MoleInventory;