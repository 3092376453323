import React from "react"

function Video () {
    return(
        <div className="content grid grid-cols-1 gap-4 md:grid-cols-2">
           <div className=" aspect-video bg-zinc-200">
            <iframe className=" aspect-video w-full" src="https://www.youtube.com/embed/PGBiZI8N7d8?si=FOvEHv88O06AG8QQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
           </div>

            <div className=" aspect-video bg-zinc-200">
                <iframe className=" aspect-video w-full" src="https://www.youtube.com/embed/Q7Zu01moIQM?si=_uEDs7gsATrEfU1e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
           </div>

           <div className=" aspect-video bg-zinc-200">
                <iframe className=" aspect-video w-full" src="https://www.youtube.com/embed/AUbtnfukT3k?si=ESQxBPHtkvfXARMH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
           </div>

            <div className=" aspect-video bg-zinc-200">
                <iframe className=" aspect-video w-full" src="https://www.youtube.com/embed/zlDz4Lqm5Bk?si=f70JOzoWR5ViP_Ha" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
           </div>

           <div className=" aspect-video bg-zinc-200">
                <iframe className=" aspect-video w-full" src="https://www.youtube.com/embed/sFpEMAULQF0?si=D7un-F6foGg4I-MZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
           </div>


        </div>
    );

}

export default Video;