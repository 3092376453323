import OwnCreatureCard from "components/Cards/Owncreature";
import PaginationPager from "components/Pagination/pagination";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Gorc from "assets/03-Gold/01-Orc.png"
import Gyeti from "assets/03-Gold/02-Yeti.png"
import Gminotaur from "assets/03-Gold/03-Minotaur.png"
import Gdragon from "assets/03-Gold/04-Dragon.png"
import Gleviathan from "assets/03-Gold/05-Leviathan.png"
import { or } from "ajv/dist/compile/codegen";
const GoldInventory = () => {
    const [orc, setOrc] = useState([]),
    [yeti, setYeti] = useState([]),
    [minotaur, setMinotaur] = useState([]),
    [dragon, setDragon] = useState([]),
    [leviathan, setLeviathan] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

    const [list, setlist] = useState({})



    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/inventory/getinventory?rank=gold&page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
              setlist(data.data)
                const gorc = data.data.g_orc
                const gyeti = data.data.g_yeti
                const gminotaur = data.data.g_minotaur
                const gdragon = data.data.g_dragon
                const gleviathan = data.data.g_leviathan
                
                
                setOrc(gorc)
                setYeti(gyeti)
                setMinotaur(gminotaur)
                setDragon(gdragon)
                setLeviathan(gleviathan)
                setIsLoading(false)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[page])

      const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const totalAccumulated = Object.keys(list).filter(key => key!== 'totalPages').reduce((acc, current) => {
  return acc + list[current].totalaccumulated;
  }, 0);

  useEffect(() => {
    localStorage.setItem('gold',totalAccumulated)
  })

    return(
        <MDBContainer fluid>
            <MDBRow style={{gap:'1rem'}}>
              

                {Object.keys(list).filter(key => key !== 'totalPages').map((data, index) => (
                <OwnCreatureCard
                  key={index}
                  title={`${list[data].type === "g_goblin" && 'Goblin' || list[data].type === "g_troll" && 'Troll' || list[data].type === "g_orc" && 'Orc' || list[data].type === "g_ogre" && 'Ogre' || list[data].type === "g_yeti" && 'Yeti' || list[data].type === "g_minotaur" && 'Minotaur' || list[data].type === "g_dragon" && 'Dragon' || list[data].type === "g_leviathan" && 'Leviathan'}`}
                  image={`${list[data].type === 'g_orc' && Gorc || list[data].type === 'g_yeti' && Gyeti || list[data].type === 'g_minotaur' && Gminotaur || list[data].type === 'g_dragon' && Gdragon || list[data].type === 'g_leviathan' && Gleviathan}`}

                        dailyaccumulated={`${(list[data].dailyaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(list[data].limitdaily).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}

                    dailyaccumulatedusd={`${(list[data].dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / $ ${(list[data].limitdaily / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}

                    totalaccumulated={`${(list[data].totalaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(list[data].limittotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}

                    totalaccumulatedusd={`${(list[data].totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / $ ${(list[data].limittotal / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                  creatureid={list[data].creatureid}
                />
              ))}

            </MDBRow>
            <PaginationPager
                total={total} 
                page={page} 
                setPage={setPage}
                isloading={isloading}
            />
        </MDBContainer>
    )
}

export default GoldInventory;