import React, {useEffect, useState, useMemo} from 'react'
import { MDBContainer } from 'mdb-react-ui-kit'
import {
    MDBBtn,
    MDBTable, MDBTableHead, MDBTableBody, MDBSpinner
  } from 'mdb-react-ui-kit';
import Swal from "sweetalert2";
import PaginationPager from "components/Pagination/pagination";



const Topcomission = () => {

  const [list, setList] = useState([]);
  const [ rate, setRate] = useState(0)
  const [currentpage, setCurrentpage] = useState(1)
  const [isloading, setIsLoading] = useState(false)
  const [date, setDate] = useState('')
  const [dateend, setDateend] = useState('')

  const [totalpage, setTotalpage] = useState(0)
  const [page, setPage] = useState(1)
  const items = 10


  // rate
  useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
    }, []);

  useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/wallethistory/gettopcommissions?startDate=${date}&endDate=${dateend}`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
      }).then(result => result.json())
      .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
              Swal.fire({
                icon: "error",
                title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(ok => {
                if(ok.isConfirmed){
                  window.location.href = "/login";
                }
              })
          }
          setList(data.data)


          if(data.message == "success"){
          setList(data.data)

             
          } else if (data.message == "failed"){
          Swal.fire({
              title: data.message,
              icon: "info",
              text: data.data
          })
          }
      })
  },[date, dateend])

  const currentList = Object.values(list)
  const totalPages = Math.ceil(currentList.length / items)



  const currentItems = useMemo(() => {
    const startIndex = (currentpage - 1) * items;
    const endIndex = startIndex + items;
    return currentList.slice(startIndex, endIndex);
}, [currentpage, list]);

// Handle page change
// const goToPage = (pageNumber) => {
//     setLoading(true)
//     if (pageNumber > 0 && pageNumber <= totalPages) {
//     setCurrentpage(pageNumber);
//     setTimeout(() => {
//             setLoading(false);
//         }, 2000);
  
//     }
// };

  


  return (
    <div className="content">
    <MDBContainer>

    <div className=' flex items-end gap-2'>
      <div className=' flex flex-col gap-1'>
        <label className=' text-xs'>Start Date</label>
        <input type='date' onChange={(e) => setDate(e.target.value)} value={date} className=' text-sm p-2 rounded-md'/>

      </div>

      <div className=' flex flex-col gap-1'>
        <label className=' text-xs'>End Date</label>
        <input type='date' onChange={(e) => setDateend(e.target.value)} value={dateend} className=' text-sm p-2 rounded-md'/>

      </div>

      <button onClick={() => {setDate(''); setDateend('')}} className=' bg-blue-500 px-4 py-2 rounded-sm text-xs text-white h-fit'>Clear</button>
      
    </div>
    

    <MDBTable small responsive>
        <MDBTableHead>
            <tr className="text-center">
            <th scope='col'></th>
            <th scope='col'>Username</th>
            <th scope='col'>Comission Balance</th>
            <th scope='col'>Direct Referral Balance</th>
            <th scope='col'>Total Balance</th>

            </tr>
        </MDBTableHead>
        <MDBTableBody>
          {currentList.map((item, index) => (
            <tr key={index} className="text-center">
              <td scope='row'>{index + 1}</td>
              <td scope='row'>{item.username}</td>
              <td scope='row'>₱ {item.commissionBalance.toLocaleString('en-US',{
                style:'decimal',
                maximumFractionDigits: 2
              })}
              <p className=' text-xs'>$ {(item.commissionBalance / rate).toLocaleString('en-US',{
                style:'decimal',
                maximumFractionDigits: 2
              })}</p>
              
              </td>
              <td scope='row'>₱ {item.directReferralBalance.toLocaleString('en-US',{
                style:'decimal',
                maximumFractionDigits: 2
              })}
              <p className=' text-xs'>$ {(item.directReferralBalance / rate).toLocaleString('en-US',{
                style:'decimal',
                maximumFractionDigits: 2
              })}</p>
              
              </td> 

              <td scope='row'>₱ {item.totalBalance.toLocaleString('en-US',{
                style:'decimal',
                maximumFractionDigits: 2
              })}
              <p className=' text-xs'>$ {(item.totalBalance / rate).toLocaleString('en-US',{
                style:'decimal',
                maximumFractionDigits: 2
              })}</p>
              
              </td>                
          </tr>
          ))}
        
          
            
              
             
   
        </MDBTableBody>
        </MDBTable>
       
    </MDBContainer>

    {/* <PaginationPager
    total={totalPages} 
    page={page} 
    setPage={setPage}
    isloading={isloading}
    /> */}
</div>
  )
}

export default Topcomission;
