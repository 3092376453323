
import React , { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBIcon
} from 'mdb-react-ui-kit';

import imege from "assets/01-Bronze/01-Goblin.png"
import Swal from "sweetalert2";
import Profile from "./Profile/profile";
import PaymentDetails from "./Profile/payment";
import UserChangePass from "./Profile/password";
import ProfileImg from "./Profile/profileimg";
function Userprofile() {
  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <div className="content">
      <Row>
      

        <Col lg="12" md="6" sm="6">
              <Row style={{backgroundColor:'white'}}>
                 <Col lg="4" md="12" sm="12">
                  <MDBTabsContent>
                    
                    <ProfileImg/>
                      <Col lg="12" md="6" sm="6">
                        <MDBTabs className='mb-3' pills>
                          <Col>
                            <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                            <MDBIcon fas icon='user-cog' className='me-2' />Profile Details
                            </MDBTabsLink>
                          </MDBTabsItem>
                          {/* <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                            <MDBIcon fas icon='dollar-sign' className='me-2' />Payment Details
                            </MDBTabsLink>
                          </MDBTabsItem> */}
                          <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                            <MDBIcon fas icon='key' className='me-2' /> Change Password
                            </MDBTabsLink>
                          </MDBTabsItem>

                          {/* <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                            <MDBIcon fas icon='user-cog' className='me-2' /> Payment Details
                            </MDBTabsLink>
                          </MDBTabsItem> */}
                          </Col>
                        
                        </MDBTabs>
                    </Col>
                  
                  </MDBTabsContent>
                </Col>
                <Col lg="8" md="12" sm="12">
                  <MDBTabsContent>
                    <MDBTabsPane open={basicActive === 'tab1'}>
                    <Profile/>
                    
                    </MDBTabsPane>
                    {/* <MDBTabsPane open={basicActive === 'tab2'}> 
                    <PaymentDetails/>
                    </MDBTabsPane> */}
                    <MDBTabsPane open={basicActive === 'tab3'}>
                    <UserChangePass/>
                    </MDBTabsPane>

                    <MDBTabsPane open={basicActive === 'tab2'}>
                    <PaymentDetails/>
                    </MDBTabsPane>
                  </MDBTabsContent>
                </Col>
              </Row>
        
        </Col>

      </Row>
    

      
      </div>
    </>
  );
}

export default Userprofile;
