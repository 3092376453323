/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";

import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon
  } from 'mdb-react-ui-kit';
// core components
import BronzeInventory from "./Inventory/Bronze";
import SilverInventory from "./Inventory/Silver";
import GoldInventory from "./Inventory/Gold";
import Swal from "sweetalert2";
import WoodInventory from "./Inventory/Whackattack/Wood";
import StoneInventory from "./Inventory/Whackattack/Stone";
import SteelInventory from "./Inventory/Whackattack/Steel";



function Inventory() {
    const [tab, setTab] = useState('Cm')

     const [basicActive, setBasicActive] = useState(() => {
      const storedTab = localStorage.getItem('lastActiveTab');
      return storedTab || 'tab1';
    });

     const [basicActive2, setBasicActive2] = useState(() => {
      const storedTab = localStorage.getItem('lastActiveTab2');
      return storedTab || 'tab1';
    });

   useEffect(() => {
    localStorage.setItem('lastActiveTab', basicActive);
  }, [basicActive]);

  const handleBasicClick = (value) => {
      if (value === basicActive) {
        return;
      }
  
      setBasicActive(value);
  };

  const handleBasicClick2 = (value) => {
      if (value === basicActive2) {
        return;
      }
  
      setBasicActive2(value);
  };
        

  return (
    <>

     
      <div className="content">

        <div className=" flex items-center gap-2 my-6">
              <button onClick={() => setTab('Cm')} className={`px-6 py-2 text-sm lg:text-lg font-semibold ${tab === 'Cm' ? 'bg-blue-700/80 text-white' : 'bg-zinc-200 text-black'} rounded-md`}>Creature Smash</button>
              <button onClick={() => setTab('Wa')} className={`px-6 py-2 text-sm lg:text-lg font-semibold ${tab === 'Wa' ? 'bg-blue-700/80 text-white' : 'bg-zinc-200 text-black'} rounded-md`}>Whack Attack</button>

            </div>

            {tab === 'Cm' && (
            <>
             <MDBTabs className='mb-3' pills>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                <MDBIcon fas icon='box-open' className='me-2' />Epic
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                <MDBIcon fas icon='box-open' className='me-2' />Legend
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                <MDBIcon fas icon='box-open' className='me-2' /> Mythic
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>
              <MDBTabsPane open={basicActive === 'tab1'}>
              <BronzeInventory/>
              </MDBTabsPane>
              <MDBTabsPane open={basicActive === 'tab2'}> 
              <SilverInventory/>
              </MDBTabsPane>
              <MDBTabsPane open={basicActive === 'tab3'}>
              <GoldInventory/>
              </MDBTabsPane>
            </MDBTabsContent>
            
            </>
            )}

            {tab === 'Wa' && (
              <>
              <MDBTabs className='mb-3' pills>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick2('tab1')} active={basicActive2 === 'tab1'}>
                <MDBIcon fas icon='box-open' className='me-2' />Wood
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick2('tab2')} active={basicActive2 === 'tab2'}>
                <MDBIcon fas icon='box-open' className='me-2' />Stone
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick2('tab3')} active={basicActive2 === 'tab3'}>
                <MDBIcon fas icon='box-open' className='me-2' /> Steel
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>

              <MDBTabsContent>
              <MDBTabsPane open={basicActive2 === 'tab1'}>

                {/* <div className=" w-full h-[300px] flex items-center justify-center">
                  <p className=" text-lg text-zinc-400">No Inventory!</p>
                </div> */}

                <WoodInventory/>

              </MDBTabsPane>
              <MDBTabsPane open={basicActive2 === 'tab2'}> 
              {/* <div className=" w-full h-[300px] flex items-center justify-center">
                  <p className=" text-lg text-zinc-400">No Inventory!</p>
                </div> */}

                <StoneInventory/>
              </MDBTabsPane>
              <MDBTabsPane open={basicActive2 === 'tab3'}>
              <SteelInventory/>
              </MDBTabsPane>
            </MDBTabsContent>
              </>
              
            



            )}
     

      
      </div>
    </>
  );
}

export default Inventory;
