import PaginationPager from "components/Pagination/pagination";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import OwnMoleCard from "components/Cards/Ownmole";

//wa wood images
import wood1 from "assets/Whack Attack Game Tab/1 - Wood Tier/1 - normal Mole.png"
import wood2 from "assets/Whack Attack Game Tab/1 - Wood Tier/2 - bucket mole.png"
import wood3 from "assets/Whack Attack Game Tab/1 - Wood Tier/3 - cone mole.png"
import wood4 from "assets/Whack Attack Game Tab/1 - Wood Tier/4 - Construction Mole.png"
import wood5 from "assets/Whack Attack Game Tab/1 - Wood Tier/5 - Miner Mole.png"



const WoodInventory = () => {
    const [normal, setNormal] = useState([]),
    [construction, setConstruction] = useState([]),
    [bucket, setBucket] = useState([]),
    [cone, setCone] = useState([]),
    [miner, setMiner] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

  const [woodlist, setWoodlist] = useState({})

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/inventory/getinventory?rank=wood&page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
              setWoodlist(data.data)
                const normal = data.data.normal_mole
                const bucket = data.data.bucket_mole
                const cone = data.data.cone_mole
                const construction = data.data.construction_mole
                const miner = data.data.miner_mole
                
               setNormal(normal)
               setBucket(bucket)
               setCone(cone)
               setConstruction(construction)
               setMiner(miner)
                setIsLoading(false)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    },[page])


      const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const totalAccumulated = Object.keys(woodlist).filter(key => key!== 'totalPages').reduce((acc, current) => {
  return acc + woodlist[current].totalaccumulated;
  }, 0);

  useEffect(() => {
    localStorage.setItem('bronze',totalAccumulated)
  })
    

    return(
        <MDBContainer fluid>
            <MDBRow style={{gap:'1rem'}}>
              

                 {Object.keys(woodlist).filter(key => key !== 'totalPages').map(( data, index) => (
                 
                   <OwnMoleCard
                    title={`${woodlist[data].type === "normal_mole" && 'Normal Mole' || woodlist[data].type === "bucket_mole" && 'Bucket Mole' || woodlist[data].type === "cone_mole" && 'Cone Mole' || woodlist[data].type === "construction_mole" && 'Construction Mole' || woodlist[data].type === "miner_mole" && 'Miner Mole'}  `}
                    image={`${woodlist[data].type === 'normal_mole' && wood1 || woodlist[data].type === 'bucket_mole' && wood2 || woodlist[data].type === 'cone_mole' && wood3 || woodlist[data].type === 'construction_mole' && wood4 || woodlist[data].type === 'miner_mole' && wood5}`}


                    dailyaccumulated={`${(woodlist[data].dailyaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(woodlist[data].limitdaily || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}


                    dailyaccumulatedusd={`${(woodlist[data].dailyaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}  / $ ${(woodlist[data].limitdaily / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}


                    totalaccumulated={`${(woodlist[data].totalaccumulated).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ₱ ${(woodlist[data].limittotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}


                    totalaccumulatedusd={`${(woodlist[data].totalaccumulated / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / $ ${(woodlist[data].limittotal / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}
                    creatureid={woodlist[data].creatureid}

                    />
                ))}

               
                
            </MDBRow>
            <PaginationPager
                total={total} 
                page={page} 
                setPage={setPage}
                isloading={isloading}
            />
        </MDBContainer>
    )
}

export default WoodInventory;