import React, { useEffect, useState } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBTable, MDBTableHead, MDBTableBody
  } from 'mdb-react-ui-kit';
import Swal from "sweetalert2";
import PaginationPager from "components/Pagination/pagination";

const Payouthistory = () => {
    const [commissionpayouthistory, setCommissionPayoutHistory] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);
    
    useEffect(() => {
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayouthistorysuperadmin?page=${page - 1}&limit=10&type=commissionbalance&searchUsername`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setCommissionPayoutHistory(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
            }
        })
    },[page])

    const [ rate, setRate] = useState(0)
     // rate
     useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const [search, setSearch] = useState('')

    useEffect(() => {
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayouthistorysuperadmin?page=${page - 1}&limit=10&type=commissionbalance&searchUsername=${search}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setCommissionPayoutHistory(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
            }
        })
    },[search])

    const clear = () => {
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayouthistorysuperadmin?page=${page - 1}&limit=10&type=commissionbalance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
            setSearch('')
            if(data.message == "success"){
                setIsLoading(false)
                setCommissionPayoutHistory(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
            }
        })
    }

    return(
    <MDBCard>
      <div className=" flex w-full justify-between items-center p-4">
        <p>Commision History</p>
        <div className=" flex items-center justify-end gap-1">
            <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" className=" h-10 rounded-md p-2 bg-zinc-100"/>
            {/* <MDBBtn onClick={searchByusername}>Search</MDBBtn> */}
            <MDBBtn color="warning" className=" mr-3" onClick={clear}>Clear</MDBBtn>
        </div>
      </div>
      <MDBCardBody>
        {/* <MDBCardTitle>Payin History</MDBCardTitle> */}
        <MDBTable small responsive>
        <MDBTableHead>
              <tr className="text-center">
            <th scope='col'>Date</th>
            <th scope='col'>Username</th>
            <th scope='col'>Account Number</th>
            <th scope='col'>Account Name</th>
            <th scope='col'>Phone no.</th>
            <th scope='col'>Payout Method</th>
            <th scope='col'>Gross Amount</th>
            <th scope='col'>Withdrawal Fee</th>
            <th scope='col'>Net Amount</th>
            <th scope='col'>Status</th>
           
            </tr>
        </MDBTableHead>
        <MDBTableBody>
            {
                commissionpayouthistory.length !== 0 ?
                    commissionpayouthistory.map((data, i) =>(
                    <tr key={i} className="text-center">
                                    <td scope='row'>{new Date(data.createdAt).toLocaleString()}</td>
                                    <td scope='row'>{data.username}</td>
                                    <td scope='col'>{data.accountnumber}</td>
                                    <td scope='col'>{data.accountname}</td>
                                    <th scope='col'>{data.phonenumber}</th>

                                    <td scope='row'>{data.paymentmethod}</td>
                                    <td>₱ {data.grossamount?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.grossamount / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>
                                     <td>₱ {data.withdrawalfee?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.withdrawalfee / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>

                                     <td>₱ {data.netamount?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.netamount / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>
                                   
                                    <td scope='row'>{data.status}</td>
                    </tr>
                    ))
                :
                <tr className="text-center">
                <th scope='row' colSpan={7}>No Data</th>
                </tr>
            }
        </MDBTableBody>
        </MDBTable>
        <PaginationPager
          total={total} 
          page={page} 
          setPage={setPage}
          isloading={isloading}
          />
      </MDBCardBody>
    </MDBCard>
    )
}

export default Payouthistory;