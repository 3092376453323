import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const SuperAdminDashboard = () => {
  const [details, setDetails] = useState([])
  const [payin, setPayin] = useState([])
  const [filter, setFilter] = useState('daily')
  const [filter2, setFilter2] = useState('Total User Top Up')
  const [timeData, setTimeData] = useState([]);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total User Top Up',
        data: [], // Start with an empty array
        fill: false,
        backgroundColor: 'rgba(39, 183, 245, 0.8)',
        borderColor: 'rgba(39, 80, 245, 0.8)',
        tension: 0.1,
      },
       {
        label: 'Game Profit',
        data: [], // Start with an empty array
        fill: false,
        backgroundColor: 'rgba(245, 140, 39, 0.8)',
        borderColor: 'rgba(245, 170, 39, 0.8)',
        tension: 0.1,
      },
      {
        label: 'Total Payout Comission',
        data: [], // Start with an empty array
        fill: false,
        backgroundColor: 'rgba(191, 39, 245, 0.8)',
        borderColor: 'rgba(226, 39, 245, 0.8)',
        tension: 0.1,
      },
      {
        label: 'Total Payout Game',
        data: [], // Start with an empty array
        fill: false,
        backgroundColor: 'rgba(39, 245, 174, 0.8)',
        borderColor: 'rgba(0, 218, 93, 0.8)',
        tension: 0.1,
      },
      
    ],
  });

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Timeframe',
        },
      },
      y: {
        beginAtZero: true, // Ensure Y-axis starts from 0
        title: {
          display: true,
          text: 'Data',
        },
      },
    },
  };



  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/staffuser/getsadashboard`,{
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(result => result.json())
    .then(data => {
      if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
        Swal.fire({
          icon: "error",
          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if(ok.isConfirmed){
            window.location.href = "/login";
          }
        })
      }

      if(data.message == "success"){
        setDetails(data.data)
      } else if (data.message == "failed"){
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        })
      }

    })

  },[])

   useEffect(() => {
  async function fetchData() {
    const dataset1Data = await fetch(`${process.env.REACT_APP_API_URL}/analytics/getpayingraph?charttype=${filter}`,{
       method: 'GET',
       credentials: 'include',
       headers: {
         'Content-Type': 'application/json'
       },
    })
      .then(response => response.json())
      .then(data => data.data);

    const labels = Object.keys(dataset1Data);
    const values = Object.values(dataset1Data);

    const dataset2Data = await fetch(`${process.env.REACT_APP_API_URL}/analytics/getproductgraph?charttype=${filter}`,{
       method: 'GET',
       credentials: 'include',
       headers: {
         'Content-Type': 'application/json'
       },
    })
      .then(response => response.json())
      .then(data => data.data);

    const dataset3Data = await fetch(`${process.env.REACT_APP_API_URL}/analytics/getunilevelpayoutgraph?charttype=${filter}`,{
       method: 'GET',
       credentials: 'include',
       headers: {
         'Content-Type': 'application/json'
       },
    })
      .then(response => response.json())
      .then(data => data.data);

    const dataset4Data = await fetch(`${process.env.REACT_APP_API_URL}/analytics/getearningpayoutgraph?charttype=${filter}`,{
       method: 'GET',
       credentials: 'include',
       headers: {
         'Content-Type': 'application/json'
       },
    })
      .then(response => response.json())
      .then(data => data.data);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: 'Total User Top Up',
          data: values,
          fill: false,
          backgroundColor: 'rgba(39, 183, 245, 0.8)',
          borderColor: 'rgba(39, 80, 245, 0.8)',
          tension: 0.1,
        },
        {
          label: 'Game Profit',
          data: dataset2Data,
          fill: false,
          backgroundColor: 'rgba(245, 140, 39, 0.8)',
          borderColor: 'rgba(245, 170, 39, 0.8)',
          tension: 0.1,
        },
        {
          label: 'Total Payout Comission',
          data: dataset3Data,
          fill: false,
         
          backgroundColor: 'rgba(191, 39, 245, 0.8)',
          borderColor: 'rgba(226, 39, 245, 0.8)',
          tension: 0.1,
        },
        {
          label: 'Total Payout Game',
          data: dataset4Data,
          fill: false,
          backgroundColor: 'rgba(39, 245, 174, 0.8)',
          borderColor: 'rgba(0, 218, 93, 0.8)',
          tension: 0.1,
        },
      ],
    });
  }

  fetchData();
}, [filter]);

  // useEffect(() => {
  //   setFilter('daily')
  // },[filter2])

  //payin
  // useEffect(() => {
  //   if( filter2 === 'Total User Top Up'){
  //     fetch(`${process.env.REACT_APP_API_URL}/analytics/getpayingraph?charttype=${filter}`, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //   })
  //   .then(result => result.json())
  //   .then(data => {
  //     if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
  //       Swal.fire({
  //         icon: "error",
  //         title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
  //         text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       }).then(ok => {
  //         if (ok.isConfirmed) {
  //           window.location.href = "/login";
  //         }
  //       });
  //     } else if (data.message === "success") {
  //       if(filter === 'daily'){
  //        const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if(filter === 'weekly'){
  //         // Function to get the date of the current week's day
  //         const getCurrentWeekDate = (day) => {
  //           const today = new Date();
  //           const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  //           const distance = day - currentDay;
  //           const resultDate = new Date(today);
  //           resultDate.setDate(today.getDate() + distance);
  //           return resultDate.toLocaleDateString();
  //         };

  //         // Map day names to numeric values
  //         const dayMap = {
  //           sunday: 0,
  //           monday: 1,
  //           tuesday: 2,
  //           wednesday: 3,
  //           thursday: 4,
  //           friday: 5,
  //           saturday: 6
  //         };

  //         // Ensure all 7 days are included in the correct order
  //         const orderedDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  //         const labels = orderedDays.map(day => getCurrentWeekDate(dayMap[day]));
  //         const values = orderedDays.map(day => data.data[day] || 0); 
  //         setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if( filter === 'monthly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }

  //        if( filter === 'yearly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }

        
      
  //     } else if (data.message === "failed") {
  //       Swal.fire({
  //         title: data.message,
  //         icon: "info",
  //         text: data.data
  //       });
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error);
  //   });
  //   }
  // }, [filter, filter2]);

  //commision
  // useEffect(() => {
  //  if( filter2 === 'User Commission'){
  //    fetch(`${process.env.REACT_APP_API_URL}/analytics/getcommissiongraph?charttype=${filter}`, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //   })
  //   .then(result => result.json())
  //   .then(data => {
  //     if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
  //       Swal.fire({
  //         icon: "error",
  //         title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
  //         text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       }).then(ok => {
  //         if (ok.isConfirmed) {
  //           window.location.href = "/login";
  //         }
  //       });
  //     } else if (data.message === "success") {

  //       if(filter === 'daily'){
  //         // Extract labels and values
  //        const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);


  //           setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if(filter === 'weekly'){
  //         // Function to get the date of the current week's day
  //         const getCurrentWeekDate = (day) => {
  //           const today = new Date();
  //           const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  //           const distance = day - currentDay;
  //           const resultDate = new Date(today);
  //           resultDate.setDate(today.getDate() + distance);
  //           return resultDate.toLocaleDateString();
  //         };

  //         // Map day names to numeric values
  //         const dayMap = {
  //           sunday: 0,
  //           monday: 1,
  //           tuesday: 2,
  //           wednesday: 3,
  //           thursday: 4,
  //           friday: 5,
  //           saturday: 6
  //         };

  //         // Ensure all 7 days are included in the correct order
  //         const orderedDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  //         const labels = orderedDays.map(day => getCurrentWeekDate(dayMap[day]));
  //         const values = orderedDays.map(day => data.data[day] || 0); 
  //         setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if( filter === 'monthly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }

  //        if( filter === 'yearly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }
  //     } else if (data.message === "failed") {
  //       Swal.fire({
  //         title: data.message,
  //         icon: "info",
  //         text: data.data
  //       });
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error);
  //   });
  //  }
  // }, [filter, filter2]);

   //game profit
  // useEffect(() => {
  //  if( filter2 === 'Game Profit'){
  //    fetch(`${process.env.REACT_APP_API_URL}/analytics/getproductgraph?charttype=${filter}`, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //   })
  //   .then(result => result.json())
  //   .then(data => {
  //     if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
  //       Swal.fire({
  //         icon: "error",
  //         title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
  //         text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       }).then(ok => {
  //         if (ok.isConfirmed) {
  //           window.location.href = "/login";
  //         }
  //       });
  //     } else if (data.message === "success") {

  //      if(filter === 'daily'){
  //         // Extract labels and values
  //        const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //           setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if(filter === 'weekly'){
  //         // Function to get the date of the current week's day
  //         const getCurrentWeekDate = (day) => {
  //           const today = new Date();
  //           const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  //           const distance = day - currentDay;
  //           const resultDate = new Date(today);
  //           resultDate.setDate(today.getDate() + distance);
  //           return resultDate.toLocaleDateString();
  //         };

  //         // Map day names to numeric values
  //         const dayMap = {
  //           sunday: 0,
  //           monday: 1,
  //           tuesday: 2,
  //           wednesday: 3,
  //           thursday: 4,
  //           friday: 5,
  //           saturday: 6
  //         };

  //         // Ensure all 7 days are included in the correct order
  //         const orderedDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  //         const labels = orderedDays.map(day => getCurrentWeekDate(dayMap[day]));
  //         const values = orderedDays.map(day => data.data[day] || 0); 
  //         setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if( filter === 'monthly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }

  //        if( filter === 'yearly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }

  //     } else if (data.message === "failed") {
  //       Swal.fire({
  //         title: data.message,
  //         icon: "info",
  //         text: data.data
  //       });
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error);
  //   });
  //  }
  // }, [filter, filter2]);

   //payout
  // useEffect(() => {
  //  if( filter2 === 'Total Payout Game'){
  //    fetch(`${process.env.REACT_APP_API_URL}/analytics/getearningpayoutgraph?charttype=${filter}`, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //   })
  //   .then(result => result.json())
  //   .then(data => {
  //     if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
  //       Swal.fire({
  //         icon: "error",
  //         title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
  //         text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       }).then(ok => {
  //         if (ok.isConfirmed) {
  //           window.location.href = "/login";
  //         }
  //       });
  //     } else if (data.message === "success") {

  //       if(filter === 'daily'){
  //         // Extract labels and values
  //        const today = new Date();
  //          const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //           setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if(filter === 'weekly'){
  //         // Function to get the date of the current week's day
  //         const getCurrentWeekDate = (day) => {
  //           const today = new Date();
  //           const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  //           const distance = day - currentDay;
  //           const resultDate = new Date(today);
  //           resultDate.setDate(today.getDate() + distance);
  //           return resultDate.toLocaleDateString();
  //         };

  //         // Map day names to numeric values
  //         const dayMap = {
  //           sunday: 0,
  //           monday: 1,
  //           tuesday: 2,
  //           wednesday: 3,
  //           thursday: 4,
  //           friday: 5,
  //           saturday: 6
  //         };

  //         // Ensure all 7 days are included in the correct order
  //         const orderedDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  //         const labels = orderedDays.map(day => getCurrentWeekDate(dayMap[day]));
  //         const values = orderedDays.map(day => data.data[day] || 0); 
  //         setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if( filter === 'monthly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }

  //        if( filter === 'yearly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }


  //     } else if (data.message === "failed") {
  //       Swal.fire({
  //         title: data.message,
  //         icon: "info",
  //         text: data.data
  //       });
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error);
  //   });
  //  }
  // }, [filter, filter2]);

   //payout commission
  // useEffect(() => {
  //  if( filter2 === 'Total Payout Commission'){
  //    fetch(`${process.env.REACT_APP_API_URL}/analytics/getunilevelpayoutgraph?charttype=${filter}`, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //   })
  //   .then(result => result.json())
  //   .then(data => {
  //     if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
  //       Swal.fire({
  //         icon: "error",
  //         title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
  //         text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
  //         allowOutsideClick: false,
  //         allowEscapeKey: false
  //       }).then(ok => {
  //         if (ok.isConfirmed) {
  //           window.location.href = "/login";
  //         }
  //       });
  //     } else if (data.message === "success") {

  //      if(filter === 'daily'){
  //         // Extract labels and values
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //           setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if(filter === 'weekly'){
  //         // Function to get the date of the current week's day
  //         const getCurrentWeekDate = (day) => {
  //           const today = new Date();
  //           const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  //           const distance = day - currentDay;
  //           const resultDate = new Date(today);
  //           resultDate.setDate(today.getDate() + distance);
  //           return resultDate.toLocaleDateString();
  //         };

  //         // Map day names to numeric values
  //         const dayMap = {
  //           sunday: 0,
  //           monday: 1,
  //           tuesday: 2,
  //           wednesday: 3,
  //           thursday: 4,
  //           friday: 5,
  //           saturday: 6
  //         };

  //         // Ensure all 7 days are included in the correct order
  //         const orderedDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  //         const labels = orderedDays.map(day => getCurrentWeekDate(dayMap[day]));
  //         const values = orderedDays.map(day => data.data[day] || 0); 
  //         setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });

  //       }

  //       if( filter === 'monthly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }

  //        if( filter === 'yearly'){
  //         const today = new Date();
  //         const labels = Object.keys(data.data);
  //         const values = Object.values(data.data);

  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: 'Values',
  //             data: values,
  //             fill: false,
  //             backgroundColor: 'rgba(75,192,192,0.4)',
  //             borderColor: 'rgba(75,192,192,1)',
  //             tension: 0.1,
  //           },
  //         ],
  //       });
        
  //       }


  //     } else if (data.message === "failed") {
  //       Swal.fire({
  //         title: data.message,
  //         icon: "info",
  //         text: data.data
  //       });
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error);
  //   });
  //  }
  // }, [filter, filter2]);

  const totalSales = details.commission + details.payin
  const totalCompanyprofit = totalSales - details.payout


   const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);



    return(
        <div className="content">

        <Row className=" mx-1" >
          <Col style={{display:'flex',alignItems:'center',justifyContent:'space-between',backgroundColor:'white',marginBottom:'1.5rem',padding:'1.5rem', borderRadius:'.5em',flex:'wrap',backgroundImage:"url('/slider-2.jpg')",backgroundPosition:'bottom'}} className="">
           <p className=" text-3xl py-3 font-semibold text-white">Welcome Admin</p>
          </Col>
           
        </Row>
        
          <Row>
            {/* total sales */}
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-circle-dollar-to-slot text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Sales</p>
                        <CardTitle tag="p" className=" s" style={{fontSize:'28px'}}>
                          ₱
                          {totalSales.toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })}
                        </CardTitle>
                          <CardTitle className=" text-xl" tag="p" style={{color:'dimgrey'}}>
                      $ {(totalSales / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update Now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* company comision */}
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="far fa-money-bill-1 text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Company Commission</p>
                        <CardTitle tag="p" style={{fontSize:'28px'}} >
                          ₱
                          {details?.commission?.toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })}
                        </CardTitle>
                          <CardTitle className=" text-xl" tag="p" style={{color:'dimgrey'}}>
                      $ {(details.commission / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-calendar" /> Last day
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* total top up */}
             <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total User Top Up</p>
                        <CardTitle tag="p"style={{fontSize:'28px'}}>
                         ₱ {details.payin?.toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })}
                        </CardTitle>
                          <CardTitle className=" text-xl" tag="p" style={{color:'dimgrey'}}>
                      $ {(details.payin / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* total payout */}
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-file-invoice-dollar text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Payout</p>
                        <CardTitle tag="p" style={{fontSize:'28px'}}>
                          ₱
                          {details.payout?.toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })}
                            <CardTitle className=" text-xl" tag="p" style={{color:'dimgrey'}}>
                      $ {(details.payout / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
             {/* total payout game */}
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-file-invoice-dollar text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Payout Game</p>
                        <CardTitle tag="p" style={{fontSize:'28px',fontSize:'28px'}}>
                          ₱
                          {details.payoutgame?.toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })}
                        </CardTitle>
                          <CardTitle className=" text-xl" tag="p" style={{color:'dimgrey'}}>
                      $ {(details.payoutgame / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* payout comission */}
              <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-comment-dollar text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Payout Commission</p>
                        <CardTitle tag="p"style={{fontSize:'28px',fontSize:'28px'}}>
                        ₱  {details.payoutcommission?.toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })}
                        </CardTitle>
                          <CardTitle className=" text-xl" tag="p" style={{color:'dimgrey'}}>
                      $ {(details.payoutcommission / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* company profit */}
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-hand-holding-dollar text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Company Profit</p>
                        <CardTitle tag="p" style={{fontSize:'28px',fontSize:'28px'}}>
                          ₱
                          {totalCompanyprofit.toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })}
                          </CardTitle>
                            <CardTitle className=" text-xl" tag="p" style={{color:'dimgrey'}}>
                      $ {(totalCompanyprofit / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* game profit */}
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-diamond text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Game Profit</p>
                        <CardTitle tag="p"style={{fontSize:'28px'}}>
                          ₱
                          {details?.products?.toLocaleString('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                          })}
                        </CardTitle>
                          <CardTitle className=" text-xl" tag="p" style={{color:'dimgrey'}}>
                      $ {(details.products / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> In the last hour
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* totol user */}
              <Col lg="4" md="6" sm="6">
              <Card className="card-stats" style={{height:'110px'}}>
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-users text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">User Account</p>
                        <CardTitle tag="p"style={{fontSize:'28px'}}>
                          {details?.registered}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            
            
          </Row>

         <div className=" w-[50px] flex items-center gap-4" style={{marginTop:'2rem'}}>
          {/* <select name="date" className=" border p-2 w-[200px]"
            required
            onChange={(e) => {setFilter2(e.target.value); setFilter('daily')}}
            value={filter2}
            style={{width: "220px", borderRadius: "6px"}}>
                <option selected>Total User Top Up</option>
                <option selected>Game Profit</option>
                <option selected>Total Payout Commission</option>
                <option selected>Total Payout Game</option>
              
            </select> */}

          <select name="date" className=" border p-2"
            required
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            style={{width: "220px", borderRadius: "6px"}}>
                <option value='daily' selected>Daily</option>
                <option value='weekly' selected >Weekly</option>
                <option value='monthly' selected >Monthly</option>
                <option value='yearly' selected >Yearly</option>
              
            </select>
        </div>

        <div>

            <Line data={chartData} options={options} />
          {/* { filter2 ==='Total User Top Up' && (
            <Line data={chartData} options={options} />
          )}

          { filter2 ==='User Commission' && (
            <Line data={chartData} options={options} />
          )}

          { filter2 ==='Game Profit' && (
            <Line data={chartData} options={options} />
          )}

           { filter2 ==='Total Payout Commission' && (
            <Line data={chartData} options={options} />
          )}

            { filter2 ==='Total Payout Game' && (
            <Line data={chartData} options={options} />
          )} */}

        </div>

        

        
        </div>
    )
}

export default SuperAdminDashboard;