import { Card, CardImg, CardBody, CardText, Col,Row, CardFooter, Button, CardTitle, CardSubtitle } from 'reactstrap';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardSubTitle,
    MDBCardText,
    MDBCardLink,
    MDBCardImage,
    MDBBtn,
    MDBInput,
    MDBSpinner
} from 'mdb-react-ui-kit';
import React, { useState, useEffect } from "react";
import plankton from "../../assets/img/plankton.jpg"
import Swal from 'sweetalert2';
const CreatureCard = ({title, description, amount, image, type, usd, tier, locked, buyonetakeone}) => {
    const [loading, setLoading] = useState(false)

    const handleBuy = (e) => {
        e.preventDefault();
        const {qty} = e.target
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to buy this creature!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then((result) => {
          if (result.isConfirmed) {
             setLoading(true)
              fetch(`${process.env.REACT_APP_API_URL}/inventory/buycreature`,{
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: type,
                qty: 1
            })
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "success",
                    text: `You successfully bought ${title}`
                })
            } else if (data.message == "failed"){
                setLoading(false)
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
          }
        });
      
    }


    return (
        // <Col lg="3" md="6" sm="6" className="d-flex justify-content-center">
        // <MDBCard alignment='' style={{width: '20rem'}}>
        //     <form onSubmit={handleBuy}>
        //         <MDBCardBody>
        //         <MDBCardTitle tag="h3">{title}</MDBCardTitle>
        //         <MDBCardImage className='mb-3' top src={image} alt="..." />
        //         <MDBCardSubTitle className='mb-2 text-center' tag="h5">Price: ₱ {amount}</MDBCardSubTitle>
        //         <MDBCardSubTitle className='mb-2 text-center' tag="h5">$ {usd}</MDBCardSubTitle>
        //         <MDBCardText className='my-2 text-center' tag="p">Duration: {description}</MDBCardText>
                
                    
        //         <MDBBtn disabled={loading} type='submit' block>{loading ? <MDBSpinner size='sm'/> : 'Buy Me'}</MDBBtn>
        //         </MDBCardBody>
        //     </form> 
        //     </MDBCard>

           
        // </Col>
        // <Col lg="6" md="6" sm="6">
        //     <Row style={{backgroundColor:'red'}}>
        //             <h3>{title}</h3>
        //             <img className='mb-3' top src={image} alt="..." />
        //             <MDBCardSubTitle className='mb-2 text-center' tag="h5">Price: ₱ {amount}</MDBCardSubTitle>
        //             <MDBCardSubTitle className='mb-2 text-center' tag="h5">$ {usd}</MDBCardSubTitle>
        //             <MDBCardText className='my-2 text-center' tag="p">Duration: {description}</MDBCardText>
                        
                            
        //             <MDBBtn disabled={loading} type='submit' block>{loading ? <MDBSpinner size='sm'/> : 'Buy Me'}</MDBBtn>
            
        //     </Row>
        
        // </Col>

          <Col lg="4" md="6" sm="6" className=' relative w-[420px] overflow-hidden gap-4' style={{display:'flex',backgroundColor:'white',padding:'1rem',justifyContent:'space-between',alignItems:'end',backgroundImage:"url('/slider-2.jpg')",borderRadius:'.5rem'}}>

            <div className={`${locked === false ? 'hidden' : 'flex items-center justify-center absolute h-full w-full bg-zinc-950 top-1 left-2 bg-opacity-85'}`}>
              <img src='/padlock.png'width={50}/>
            </div>
              <img className='' src={image} style={{width:'120px'}} />

              <div className=' flex flex-col w-[80%] h-full lg:flex-row'>
                 <div style={{display:'flex',flexDirection:'column',color:'white'}}>
                 <h3 className=' text-2xl' style={{fontWeight:'bold',color:'white'}}>{title}</h3>

                 <MDBCardText className=' text-xs' tag="p" style={{ color:'#D3D3D3'}}>Duration: {description}</MDBCardText> 

                  <div className=' justify-end mt-6' style={{display:'flex',flexDirection:'column',color:'white'}}>
                 <MDBCardSubTitle className='' tag="h5">Price: ₱ {amount}</MDBCardSubTitle>
                 <MDBCardSubTitle className='' tag="h5" style={{fontSize:'.8em',color:'#D3D3D3'}}>Price: $ {usd}</MDBCardSubTitle>
                 <MDBCardSubTitle className={`${buyonetakeone === false ? 'hidden' : ''}`} style={{color: '#32CD32'}} tag="h5">Dual Smash</MDBCardSubTitle>
                </div>
                </div>

             
              </div>

             <button onClick={handleBuy} className=' w-[150px]' style={{height:'40px',backgroundColor:'blue',color:'white',border:'none',borderRadius:'.5em'}} disabled={loading} type='submit' block>{loading ? <MDBSpinner size='sm'/> : 'Buy Me'}</button>
         </Col>
    )
}

export default CreatureCard;
