import PaginationPager from "components/Pagination/pagination";
import React, {useState, useEffect} from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
function MoleBuyHistory( {historystate}) {
    const [buyhistory, setBuyHistory] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

    const params = useParams();


     useEffect(() => {
         setIsLoading(true)
         fetch(`${process.env.REACT_APP_API_URL}/inventory/getinventoryhistory?type=Buy mole&page=${page - 1}&limit=10`,{
           method: 'GET',
           credentials: 'include',
           headers: {
             'Content-Type': 'application/json'
           },
         }).then(result => result.json())
         .then(data => {
           if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
             Swal.fire({
               icon: "error",
               title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
               text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
               allowOutsideClick: false,
               allowEscapeKey: false
             }).then(ok => {
               if(ok.isConfirmed){
                 window.location.href = "/login";
               }
             })
           }

           if(data.message == "success"){
             setBuyHistory(Object.values(data.data.history))
             setIsLoading(false)
             setTotal(data.data.totalpages)

             console.log(data.data)
           } else if (data.message == "failed"){
             Swal.fire({
               title: data.message,
               icon: "info",
               text: data.data
             })
           }
         })
     },[page, params])

    const [ rate, setRate] = useState(0)
      useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
    }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">History</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Date</th>
                      <th>Tier</th>
                      <th>Mole Name</th>
                      <th>Amount</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                  { buyhistory.length != 0 ?
                      buyhistory.map((data, i) => (
                      <tr key={i}>
                        <td style={{width:'300px' }}>{new Date(data.createdAt).toLocaleString()}</td>
                        <td className={`${data.rank === 'wood' && ' text-amber-800' || data.rank == 'stone' && ' text-gray-400' || data.rank === 'steel' && ' text-violet-700'} font-semibold`}>{data.rank}</td>
                        <td style={{width:'300px' }}>{data.creaturename}</td>
                        <td >
                        ₱ {data.amount?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                        <p>$ {(data.amount / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}</p>
                        </td>
                      </tr>
                      ))
                      :
                      <tr className="text-center">
                        <td colSpan={4}>No Data</td>
                      </tr>
                    }
                    
                  </tbody>
                </Table>
                
              </CardBody>
            </Card>
            <PaginationPager
                total={total} 
                page={page} 
                setPage={setPage}
                isloading={isloading}
                />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MoleBuyHistory;