import { MDBContainer, MDBTypography, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBInput, MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
    MDBTable, MDBTableHead, MDBTableBody
  } from 'mdb-react-ui-kit';
import PaginationPager from "components/Pagination/pagination";



const Masterkey = () => {
     const [sales, setSales] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [ rate, setRate] = useState(0)
    const [loading, setLoading] = useState(false)

 
    return (
        <div className="content">
       
            <MDBCardBody>

                <div className=" flex flex-col gap-6 p-6 max-w-[400px] w-full h-auto bg-white shadow-md rounded-md">
                    <MDBInput name="username" label="Enter master key" required/>

                    <div className=" w-full flex items-end justify-end">
                        <MDBBtn disabled={loading} type="submit">{loading ? <MDBSpinner size="sm"/> : 'Save'}</MDBBtn>

                    </div>

                </div>

                <div className=" flex flex-col gap-6 bg-white rounded-sm p-8 shadow-md mt-12">
                    <p className=" text-sm">Master Key History</p>
                    <MDBTable small responsive className="">
                    <MDBTableHead>
                        <tr className="text-center">
                        <th scope='col'>Ip address</th>
                        <th scope='col'>Date changed</th>
                        
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {/* {
                            sales.length !== 0 ?
                                sales.map((data, i) =>(
                                <tr key={i} className="text-center">
                                    <td scope='row'>{data._id}</td>
                                    <td className="text-lg">₱ {data.totalValue?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                        <p className=" text-sm">${(data.totalValue / rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </td>
                                
                                </tr>
                                ))
                            :
                            <tr className="text-center">
                            <th scope='row' colSpan={6}>No Data</th>
                            </tr>
                        } */}
                        
                    </MDBTableBody>
                    </MDBTable>
                </div>
               
        
            </MDBCardBody>
        </div>
    )
}

export default Masterkey;