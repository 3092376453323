import { 
    MDBContainer, 
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCard,
    MDBCardBody,
    MDBBtn, 
    MDBCardText,
    MDBIcon,
    MDBSpinner
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import bg from "assets/register-bg.png"
import Swal from "sweetalert2";
const Register = () => {
  const [referral, setReferral] = useState("")
  const [referralusername, setReferralUsername] = useState("")
  const [loading, setLoading] = useState(false)

  const [erroorusername, setErrorusername] = useState('')
  const [erroorpasswoord, setErrorpasswoord] = useState('')

   const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [show, setShow] = useState('password')
  const [show2, setShow2] = useState('password')

  useEffect(()=> {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search);
    const id = params.get('id');
    if(id != null){
      fetch(`${process.env.REACT_APP_API_URL}/auth/getreferralusername?id=${id}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "success"){
          setReferral(id)
          setReferralUsername(data.data)
        } else {
          Swal.fire({
            title: data.message,
            text: data.data,
            icon: "error"
          })
        }
      })
    } else {
      window.location.href = "/register?id=65eeeeabd9576a4f8ae38afd";
    }
  },[])
  
  const register = (e) => {
    e.preventDefault();
    setLoading(true)
   
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;

    if(password !== confirmPassword){
      
        setLoading(false)
        setErrorpasswoord('Password does not match')
      
    } else if (specialChars.test(username) || specialChars.test(password)) {
      setLoading(false)
      setErrorpasswoord("Please check your username or password if there is special characters.")
       
     
    } else if ( username.length < 6 || username.length > 10) {
      setLoading(false)
        // setErrorusername("Username should be minimum 6 & maximum of 10 characters")
    } 
     else if ( number === '') {
      setLoading(false)
       Swal.fire({
            title: 'Invalid phone Number',
            icon: "error",
            text: 'Please enter youre phone number'
          })
    } 
     else {
      setErrorpasswoord('')
      setErrorusername('')
      fetch(`${process.env.REACT_APP_API_URL}/auth/register`,{
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: username,
          password: password,
          referral: referral,
          phonenumber: number
        })
      }).then(result => result.json())
      .then(data => {
        if(data.message == "success"){
          setLoading(false)
          Swal.fire({
            title: "Registered successfully",
            icon: "success",
            allowOutsideClick: false
          })
           .then(ok => {
             if(ok.isConfirmed){
              //  window.location.href = "/login"
               setTimeout(() => {
                  login();
                }, 500);
              
             }
           })
        } else {
          setLoading(false)
          Swal.fire({
            title: data.message,
            icon: "error",
            text: data.data
          })
        }
      })
      .catch(err => {
        setLoading(false)
        Swal.fire({
          title: "Error",
          text: err,
          icon: "error"
        })
      })
    }
    
  }

  const login = () => {
       
        fetch(`${process.env.REACT_APP_API_URL}/auth/login?username=${username}&password=${password}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if (data.message !== "success") {    
                setLoading(false)    
				Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
			} else {
        localStorage.setItem('username',username)
                setLoading(false)
                Swal.fire({
                    title: "Login Successfully",
                    icon: "success",
                    text: `Welcome ${username}`,
                    allowEscapeKey: false,
                    allowOutsideClick: false
                  })
                  .then(result1 => {
                    if(result1.isConfirmed){
                        if(data.data.auth === "superadmin"){
                            localStorage.setItem('uid', btoa("superadmin"))
                            window.location.href = `superadmin/dashboard`
                        } else if(data.data.auth === "admin"){
                            localStorage.setItem('uid', btoa("admin"))
                            window.location.href = `admin/dashboard`
                        } else if(data.data.auth === "player"){
                            localStorage.setItem('uid', btoa("player"))
                            window.location.href = `user/dashboard`
                        }
                    }
                })
            }
        })

  }


  useEffect(() => {
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
      if(password === confirmPassword){
        setLoading(false)
        setErrorpasswoord('')
      
    } 
    if ( username.length === 6) {
      setLoading(false)
        setErrorusername("")
    } 
  },[username,password,email,confirmPassword])


   const handleChange = (event) => {
    const { value } = event.target;
    // Only allow numbers and an optional decimal point
    if (/^-?\d*\.?\d*$/.test(value)) {
      setEmail(value);
    }
  };

   const handleKeyDown = (event) => {
    if (event.key === 'e' || event.key === 'E' || event.key === '+' || event.key === '-') {
      event.preventDefault();
    }
  };



    return (
        <MDBContainer fluid className=" relative w-full h-full flex align-items-center justify-content-center min-vh-100 bg-white overflow-y-hidden" >
          {/* <div className="absolute top-0">
              <video autoPlay="autoplay" muted loop className=" object-cover w-full h-full">
              <source src="/Header Video Loop.mp4" type="video/mp4" />
            </video>
          </div> */}

          <video autoPlay="autoplay" muted loop className=" z-0 absolute object-cover w-full h-full">
              <source src="/Header Video Loop.mp4" type="video/mp4" />
            </video>

          <div className=" absolute z-20 w-full h-full bg-zinc-950/90">

          </div>


         
        <MDBRow className=" relative z-40">
            

            <MDBCol>
              <div className=" w-full flex items-center justify-center mb-4">
                <img src="/TGIF Logo.png" className=" w-[200px] lg:w-[350px]"/>
              </div>
              
              <div className=" flex flex-col items-center justify-center px-2 pt-2 bg-blue-600 rounded-md">
                <p className=" text-2xl font-semibold text-white mt-2">Welcome</p>
                <p className=" text-sm text-zinc-200 mb-4">This will be amazing experience</p>
                <MDBCard>
                    <MDBCardBody>
                    <MDBCardText tag="h1" className="text-center mb-4">Register</MDBCardText>
                    <form onSubmit={register}>
                      
                      <MDBInput className='' name="username" label='Username' required onChange={(e) => setUsername(e.target.value)} />
                      <p style={{color:'red',fontSize: '12px'}}>Username should be minimum 6 & maximum of 10 characters</p>
                      {/* <MDBInput className='mb-4' name="email" type='email' label='Email address' required/> */}
                      <MDBInput className=' mt-2' name="number" type='number' label='Phone number' required onChange={(e) => setNumber(e.target.value)}   onKeyDown={handleKeyDown}/>
                      <p style={{color:'red',fontSize: '12px'}}>Must use active contact number</p>
                      
                      <MDBRow className=' mt-2'>
                        <MDBCol>
                          <div className="input-group ">
                            <MDBInput
                              name="password"
                              type={show}
                              id="form2Example2"
                              label="Password"
                              required
                              className="form-control"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="input-group-text" onClick={() => setShow(show === 'password' ? 'text' : 'password')}>
                              <MDBIcon fas icon={show === 'password' ? 'eye-slash' : 'eye'} />
                            </span>
                          </div>
                        {/* <MDBInput name="password"  type='password' label='Password' required onChange={(e) => setPassword(e.target.value)}/> */}
                        </MDBCol>
                        <MDBCol>
                          <div className="input-group ">
                            <MDBInput
                              name="confirmpassword"
                              type={show2}
                              id="form2Example2"
                              label="Confirm Password"
                              required
                              className="form-control"
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <span className="input-group-text" onClick={() => setShow2(show2 === 'password' ? 'text' : 'password')}>
                              <MDBIcon fas icon={show2 === 'password' ? 'eye-slash' : 'eye'} />
                            </span>
                          </div>
                        {/* <MDBInput name="confirmpassword" type='password'  label='Confirm Password' onChange={(e) => setConfirmPassword(e.target.value)}/> */}
                        </MDBCol>
                      </MDBRow>
                      <p style={{color:'red',fontSize: '12px'}}>{erroorpasswoord}</p>


                      <MDBInput name="referral" className='mb-4 mt-1' value={referralusername} label='Referral' disabled required/>

                      <MDBBtn disabled={loading} type='submit' className='mb-4' block>
                        {loading ? <MDBSpinner size="sm" /> : 'Sign up'}
                      </MDBBtn>

                      <div className='text-center'>
                        <p>
                          Already have an account? <a href="/login">Login</a>
                        </p>
                      
                      </div>
                    </form>
                  </MDBCardBody>
                </MDBCard>
              </div>
              
            </MDBCol>
        </MDBRow>
       </MDBContainer>
    )
}

export default Register;