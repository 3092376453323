import React, { useState, useEffect } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
  MDBIcon
} from 'mdb-react-ui-kit';
import Swal from 'sweetalert2';
import PaginationPager from "components/Pagination/pagination";
import { MDBContainer,  MDBTable, MDBTableHead, MDBTableBody  } from "mdb-react-ui-kit";

const levels = [
  {name: 'Level 1'},
  {name: 'Level 2'},
  {name: 'Level 3'},
  {name: 'Level 4'},
  {name: 'Level 5'},
  {name: 'Level 6'},
  {name: 'Level 7'},
  {name: 'Level 8'},
  {name: 'Level 9'},
  {name: 'Level 10'},
]

const Myteam = ({id}) => {
    const [verticalActive, setVerticalActive] = useState('tab1');
    const [basicActive, setBasicActive] = useState(0);

    const [network, setNetwork] = useState([]),
        [page, setPage] = useState(1),
        [isloading, setIsLoading] = useState(false),
        [total, setTotal] = useState(0);


    const handleVerticalClick = (value) => {
    if (value === verticalActive) {
      return;
    }

    setVerticalActive(value);
    };

    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
    };


    useEffect(() => {
      setPage(1)
    },[basicActive])

     const [ rate, setRate] = useState(0)
       // rate
     useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
       method: 'GET',
       credentials: 'include',
       headers: {
         'Content-Type': 'application/json'
       },
     })
     .then(result => result.json())
     .then(data => {
       if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
         Swal.fire({
           icon: "error",
           title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
           text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
           allowOutsideClick: false,
           allowEscapeKey: false
         }).then(ok => {
           if (ok.isConfirmed) {
             window.location.href = "/login";
           }
         });
       } else if (data.message === "success") {
         setRate(data.data.rate)
       } else if (data.message === "failed") {
         Swal.fire({
           title: data.message,
           icon: "info",
           text: data.data
         });
       }
     })
     .catch(error => {
       console.error('Error fetching data:', error);
     });
   }, []);

   const [search, setSearch] = useState('')


      useEffect(() => {
      const timer = setTimeout(() => {
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/unilevel/playeviewadminunilevel?playerid=${id}&page=${page - 1}&limit=10&level=${basicActive}&search=${search}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }
  
        if(data.message == "success"){
          setIsLoading(false)
          setNetwork(data.data.length != 0 ? data.data[0]?.data : data.data)
          setTotal(data.data.length != 0 ? data.data[0]?.totalPages : 0)
        } else if (data.message == "failed"){
          Swal.fire({
            title: data.message,
            icon: "info",
            text: data.data
          })
        } 
        
        if ( search !== ''){
          setPage(1)
        }
      })
      
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
     
    },[page, search,basicActive])


    return(
        <MDBRow>
        <MDBCol size='3'>
          <MDBTabs pills className='flex-column text-center'>
               {levels.map(( data, index) => (
                <MDBTabsItem key={index}>
                <MDBTabsLink onClick={() => handleBasicClick(index)} active={basicActive === index}>
                <MDBIcon fas icon="user-tie" className='me-2'/>{data.name}
                </MDBTabsLink>
                </MDBTabsItem>
            ))}
          
          </MDBTabs>
        </MDBCol>
        <MDBCol size='9'>
          <MDBTabsContent>
            <div className=" flex items-center gap-2 mb-4">
              <input onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search username" type="text" className=" p-2 bg-zinc-200"/>
              <button onClick={() => setSearch('')} className=" bg-blue-700 px-6 py-2 text-white rounded-md">Reset</button>
            </div>
            <MDBTabsPane open={verticalActive === 'tab1'}>
                  <MDBContainer>
                    <MDBTable small>
                        <MDBTableHead>
                            <tr className="text-center">
                            <th scope='col'>Date</th>
                            <th scope='col'>Amount</th>
                            <th scope='col'>Username</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {network.length === 0 ? (
                            <tr className="text-center">
                              <td colSpan="3">No data available</td>
                            </tr>
                          ): (
                            <>
                            {network.map((data, idx) => (
                               <tr key={idx} className="text-center">
                                <td colSpan="1">{new Date(data.createdAt).toLocaleString()}</td>
                                 <td>₱ {data.totalAmount?.toLocaleString('en-US', {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                  })}
                                  <p>$ {(data.totalAmount / rate).toLocaleString('en-US', {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                  })}</p>
                                  </td>
                                  <td>{data.username}</td>

                              </tr>
                            ))}
                            </>
                           
                          )}
                          
                        </MDBTableBody>
                    </MDBTable>
                    <PaginationPager
                    total={total} 
                    page={page} 
                    setPage={setPage}
                    isloading={isloading}
                    />
                </MDBContainer>
            </MDBTabsPane>
           
          </MDBTabsContent>
        </MDBCol>
      </MDBRow>
    )
}

export default Myteam;