import PaginationPager from "components/Pagination/pagination";
import { MDBContainer,  MDBTable, MDBTableHead, MDBTableBody  } from "mdb-react-ui-kit";
import React, {useState, useEffect} from "react";
import Swal from "sweetalert2";
const Level5 = () => {
    const [network, setNetwork] = useState([]),
        [page, setPage] = useState(1),
        [isloading, setIsLoading] = useState(false),
        [total, setTotal] = useState(0);
    
        const [search, setSearch] = useState('')

    useEffect(() => {
      const timer = setTimeout(() => {
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/unilevel/playerunilevel?level=4&page=${page - 1}&limit=10&search=${search}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }
  
        if(data.message == "success"){
          setIsLoading(false)
          setNetwork(data.data.length != 0 ? data.data[0]?.data : data.data)
          setTotal(data.data.length != 0 ? data.data[0]?.totalPages : 0)
        } else if (data.message == "failed"){
          Swal.fire({
            title: data.message,
            icon: "info",
            text: data.data
          })
        } 
        
        if ( search !== ''){
          setPage(1)
        }
      })
      
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
     
    },[page, search])

     const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);
    
    return(
        <MDBContainer>
          <div className=" flex items-center gap-2 mb-4">
            <input onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search username" type="text" className=" p-2"/>
            <button onClick={() => setSearch('')} className=" bg-blue-700 px-6 py-2 text-white rounded-md">Reset</button>
          </div>
            <MDBTable small>
                <MDBTableHead>
                    <tr className="text-center">
                    <th scope='col'>Date</th>
                    <th scope='col'>Amount</th>
                    <th scope='col'>Username</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    { network.length != 0 ?
                      network.map((data, i) => (
                      <tr className="text-center" key={i}>
                          <td style={{width:'400px' }}>{new Date(data.createdAt).toLocaleString()}</td>
                      <td>₱ {data.totalAmount?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                         <p>$ {(data.totalAmount / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}</p>
                        </td>
                        <td>{data.username}</td>
                      </tr>
                      ))
                      :
                      <tr className="text-center">
                        <td colSpan={3}>No Data</td>
                      </tr>
                    }
                </MDBTableBody>
            </MDBTable>
            <PaginationPager
              total={total} 
              page={page} 
              setPage={setPage}
              isloading={isloading}
            />
        </MDBContainer>
    )
}

export default Level5;