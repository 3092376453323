import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import BronzeInventory from './bronze';
import SilverInventory from './silver';
import GoldInventory from './gold';
import WoodInventory from './wood';
import StoneInventory from './stone';
import SteelInventory from './steel';


const CreatureInventory = ({id}) => {
    const [verticalActive, setVerticalActive] = useState('tab1');
    
    const handleVerticalClick = (value) => {
    if (value === verticalActive) {
      return;
    }

    setVerticalActive(value);
    };

   
    
    return(
        <MDBCol>
          

        <MDBCol size='12'>
          <MDBTabsContent>
           
            <MDBTabs pills className='flex text-center'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab1')} active={verticalActive === 'tab1'}>
                Epic
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab2')} active={verticalActive === 'tab2'}>
               Legend
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab3')} active={verticalActive === 'tab3'}>
                Mythic
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

            <MDBTabsContent>
              <MDBTabsPane open={verticalActive === 'tab1'}>
                  <BronzeInventory id={id}/>
              </MDBTabsPane>
              <MDBTabsPane open={verticalActive === 'tab2'}>
                  <SilverInventory id={id}/>
              </MDBTabsPane>
              <MDBTabsPane open={verticalActive === 'tab3'}>
                  <GoldInventory id={id}/>
              </MDBTabsPane>
              <MDBTabsPane open={verticalActive === 'tab4'}>
                  <WoodInventory id={id}/>
              </MDBTabsPane>
              <MDBTabsPane open={verticalActive === 'tab5'}>
                  <StoneInventory id={id}/>
              </MDBTabsPane>
              <MDBTabsPane open={verticalActive === 'tab6'}>
                  <SteelInventory id={id}/>
              </MDBTabsPane>
            </MDBTabsContent>
            
          </MDBTabsContent>
        </MDBCol>
        {/* <MDBRow size='3'>
          <p>Creature Smash</p>
          <MDBTabs pills className='flex text-center'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab1')} active={verticalActive === 'tab1'}>
                Epic
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab2')} active={verticalActive === 'tab2'}>
               Legend
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab3')} active={verticalActive === 'tab3'}>
                Mythic
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <p>Whack Attack</p>
          <MDBTabs pills className='flex text-center'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab4')} active={verticalActive === 'tab4'}>
                Wood
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab5')} active={verticalActive === 'tab5'}>
               Stone
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleVerticalClick('tab6')} active={verticalActive === 'tab6'}>
                Steel
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBRow>
        <MDBCol size='9'>
          <MDBTabsContent>
            <MDBTabsPane open={verticalActive === 'tab1'}>
                <BronzeInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab2'}>
                <SilverInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab3'}>
                <GoldInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab4'}>
                <WoodInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab5'}>
                <StoneInventory id={id}/>
            </MDBTabsPane>
            <MDBTabsPane open={verticalActive === 'tab6'}>
                <SteelInventory id={id}/>
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBCol> */}
      </MDBCol>
    )
}

export default CreatureInventory;