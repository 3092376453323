import React, { useState, useEffect } from "react";
import { 
    MDBTable, 
    MDBTableHead, 
    MDBTableBody, 
    MDBContainer, 
    MDBBtn,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon,
    MDBSpinner
 } from 'mdb-react-ui-kit';

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import ViewHistory from "./View/History";
import ViewInventory from "./View/Inventory";
import Myteam from "./View/Myteam";
import VPcreaturehistory from "./View/Purchase";


const SAviewplayer = () => {
    const { userId, username, status } = useParams();
    const [fiat, setFiat] = useState(0)
    const [commission, setCommission] = useState(0)
    const [gamebalance, setGameBalance] = useState(0)
    const [basicActive, setBasicActive] = useState('tab1');

    const [ statusCheck, setStatuscheck] = useState('')

    const [statusFinal, setStatusfinal] = useState('')

    useEffect(() => {
      if(status === 'active'){
        setStatuscheck('banned')
      }else{
        setStatuscheck('active')
      }
      
    },[])



    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/wallets/getplayerwalletforadmin?playerid=${userId}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
          }).then(result => result.json())
          .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
              Swal.fire({
                icon: "error",
                title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(ok => {
                if(ok.isConfirmed){
                  window.location.href = "/login"
                }
              })
            }
      
            if(data.message == "success"){
                const fiat = data.data.userwallets.find(e => e.type == "fiatbalance")
                const commission = data.data.userwallets.find(e => e.type == "commissionbalance")
                const game = data.data.userwallets.find(e => e.type == "gamebalance")
                setFiat(fiat.amount)
                setCommission(commission.amount)
                setGameBalance(game.amount)
            } else if (data.message == "failed"){
              Swal.fire({
                title: data.message,
                icon: "info",
                text: data.data
              })
            }
        })
    },[])

    const [ rate, setRate] = useState(0)
      // rate
    useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);
  
   const [isloading, setIsLoading] = useState(false)

    const handleBan = () => {
      setIsLoading(true)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/user/banunbanuser`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        status: statusCheck,
                        userid: userId
                    })
                  }).then(result => result.json())
                  .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
  
                    if(data.message == "success"){
                      setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            //text: "User account banned successfully"
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                      setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                  })
            } else {
              setIsLoading(false)
            }
          });
      
    }

      useEffect(() => {
        if(userId !== '') {
             fetch(`${process.env.REACT_APP_API_URL}/user/getuserdetailssuperadmin?userid=${userId}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
          }).then(result => result.json())
          .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
              Swal.fire({
                icon: "error",
                title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(ok => {
                if(ok.isConfirmed){
                  window.location.href = "/login"
                }
              })
            }

            setStatusfinal(data.data.status)
      
         
         
        })
        }
     
    },[])


    return(
        <MDBContainer>
          <div className=" flex items-center justify-between gap-3 p-4 mt-4 bg-zinc-100 rounded-lg" style={{backgroundImage:"url('/slider-2.jpg')", backgroundPosition:" bottom"}}>
            <div className=" flex items-center gap-3" >
              <div className=" flex items-end">
                <img src="/viewimg.png" className=" h-16"/>

              </div>
              <Col className=" text-white">
              <h2 className=" text-3xl font-semibold">{username}</h2>
              <p className={` text-lg ${ statusFinal === 'active' ? ' text-green-400' : ' text-red-500'}`}>{statusFinal}</p>
              </Col>
            </div>

              {
              statusFinal == "active" ? 
              <MDBBtn disabled={isloading} size="sm" className="m-1" color="danger" onClick={() => handleBan( "banned")}>{isloading ? <MDBSpinner size="sm"/> : 'Ban'}</MDBBtn>
              :
              <MDBBtn disabled={isloading} size="sm" className="m-1" color="info" onClick={() => handleBan("active")}>{isloading ? <MDBSpinner size="sm"/> : 'Unban'}</MDBBtn>
              }
            
          </div>
        
        <Row className="mt-5">
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-diamond text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Load Balance</p>
                      <CardTitle tag="p">
                       ₱ {fiat.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>

                      <CardTitle tag="p" className=" text-xl text-zinc-500">
                       $ {(fiat / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update now
                </div>
              </CardFooter> */}
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-credit-card text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Game Wallet Earnings</p>
                      <CardTitle tag="p">
                       ₱ {gamebalance?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>

                      <CardTitle tag="p" className=" text-xl text-zinc-500">
                       $ {(gamebalance / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update Now
                </div>
              </CardFooter> */}
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Commission Wallet Earnings</p>
                      <CardTitle tag="p">
                       ₱ {commission?.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <CardTitle tag="p" className=" text-xl text-zinc-500">
                       $ {(commission / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-calendar" /> Last day
                </div>
              </CardFooter> */}
            </Card>
          </Col>
        </Row>
        
        <MDBTabs className='mb-3' pills>
       
           <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
            <MDBIcon fas icon="user-tie" className='me-2'/>Load History
            </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            <MDBIcon fas icon="user-alt" className='me-2'/>Inventory
            </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
              <MDBIcon fas icon="user-alt" className='me-2'/>My Team
              </MDBTabsLink>
            </MDBTabsItem>

             <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
              <MDBIcon fas icon="user-alt" className='me-2'/>Purchase History
              </MDBTabsLink>
            </MDBTabsItem>
        </MDBTabs>

        <MDBTabsContent>
            <MDBTabsPane open={basicActive === 'tab1'}>
              <ViewHistory id={userId}/>
            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab2'}>
              <ViewInventory id={userId}/>
            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab3'}>
              <Myteam id={userId}/>
            </MDBTabsPane>

             <MDBTabsPane open={basicActive === 'tab4'}>
              <VPcreaturehistory id={userId}/>

            </MDBTabsPane>


        </MDBTabsContent>

        </MDBContainer>
    )
}

export default SAviewplayer