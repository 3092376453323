import React , {useState, useEffect}from "react";
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon,
} from 'mdb-react-ui-kit';
import { 
    MDBTable, 
    MDBTableHead, 
    MDBTableBody, 
    MDBContainer, 
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSpinner,
 } from 'mdb-react-ui-kit';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
  } from "reactstrap"
import Swal from "sweetalert2";
import Amanageplayer from "./Manageaccount/Manageplayer";

const AdminManageAccount = () => {
    const [basicActive, setBasicActive] = useState('tab2');
     const [basicModal, setBasicModal] = useState(false);
    const [staff, setStaff] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

    const toggleOpen = () => setBasicModal(!basicModal);

    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
    };

     const handleCreate = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const {username, password} = e.target
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/auth/registerstaffs`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: username.value,
                        password: password.value
                    })
                  }).then(result => result.json())
                  .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }

                    if(data.message == "success"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: "Admin account created successfully"
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                  })
            } else {
              setIsLoading(false)
            }
          });
       
    }

    
      //count
    const [count1, setCount1] = useState()
    const [count2, setCount2] = useState()
    const [count3, setCount3] = useState()


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/user/getplayercount`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }
          setIsLoading(false)
          setCount1(data.data.totalusers)
          setCount2(data.data.activeusers)
          setCount3(data.data.banusers)
          // setCount(data.data)
  
      
        })
        
    },[])

    return (
        <div className="content">
           
           <Row>
            {/* total sales */}
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-users text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Registered Users</p>
                        <CardTitle tag="p" className=" text-4xl font-semibold" >
                          
                        {count1}
                        </CardTitle>
                          
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update Now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* company comision */}
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Activated Users</p>
                        <CardTitle tag="p"  className=" text-4xl font-semibold"  >
                        {count2}
                        </CardTitle>
                        
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-calendar" /> Last day
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            {/* total top up */}
             <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-users text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Banned Users</p>
                        <CardTitle tag="p" className=" text-4xl font-semibold">
                         {count3}
                        </CardTitle>
                        
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
            
        
          </Row>

          <div className=" flex md:flex-row flex-col items-center justify-center">
             {/* <MDBTabs className='mb-3' pills>
                <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                <MDBIcon fas icon="user-tie" className='me-2'/>Admin
                </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                <MDBIcon fas icon="user-alt" className='me-2'/>Player
                </MDBTabsLink>
                </MDBTabsItem>
            
              
            </MDBTabs> */}

            <div className=" flex lg:flex-row flex-col items-center gap-2">

              
                {/* <MDBBtn 
                color="info"
                onClick={toggleOpen}
                >
                Ban Multiple
                </MDBBtn>
                

                <div className=" flex items-center justify-end gap-1 lg:mr-11">
                    <input placeholder="Search" className=" h-10 rounded-md p-2"/>
                    
                    <MDBBtn color="warning" className=" mr-3">Clear</MDBBtn>
                </div> */}
            </div>

              
          </div>



        <MDBTabsContent>
           
            <MDBTabsPane open={basicActive === 'tab2'}> 
            <Amanageplayer/>
            </MDBTabsPane>
         
        </MDBTabsContent>

        <MDBModal  open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Create Admin Account</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <form onSubmit={handleCreate}>
            <MDBModalBody>
            <label>Username</label>
            <MDBInput name="username" type="text" label="Input Username here"/>
            <label>Password</label>
            <MDBInput name="password" type="password" label="Input Password here"/>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn disabled={isloading} type="submit">{isloading ? <MDBSpinner size="sm"/> : 'Add '}</MDBBtn>
            </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
        </div>
    )
}

export default AdminManageAccount;