import React, { useEffect, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Swal from "sweetalert2";
const LeaderboardHistory = () => {
    const [history, setHistory] = useState([]),
    [date, setDate] = useState([]),
    [isloading, setIsLoading] = useState(false),
    [dateselected, setDateSelected] = useState("");

    useEffect(() => {
      setIsLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}/score/historydates?page=0&limit=10`,{
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(result => result.json())
      .then(data => {
        if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
          Swal.fire({
            icon: "error",
            title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
            text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(ok => {
            if(ok.isConfirmed){
              window.location.href = "/login";
            }
          })
        }
  
        if(data.message == "success"){
          setIsLoading(false)
          setDate(data.data.dates)
        } else if (data.message == "failed"){
          Swal.fire({
            title: data.message,
            icon: "info",
            text: data.data
          })
        }
      })
    },[])

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/score/getleaderboardhistory?page=0&limit=10&date=${dateselected}`,{
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(result => result.json())
        .then(data => {
          if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
            Swal.fire({
              icon: "error",
              title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
              text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(ok => {
              if(ok.isConfirmed){
                window.location.href = "/login";
              }
            })
          }
    
          if(data.message == "success"){
            setIsLoading(false)
            setHistory(Object.values(data.data.history))
          } else if (data.message == "failed"){
            Swal.fire({
              title: data.message,
              icon: "info",
              text: data.data
            })
          }
        })
    },[dateselected])


    return(
    <>
    <div className="w-50">
        <select name="date" className=" border p-2 mb-4"
        required
        onChange={(e) => setDateSelected(e.target.value)}
        style={{width: "100%", borderRadius: "6px"}}>
            <option disabled selected>Select Date First</option>
            {
              date.map((data, i) =>(
              <option key={i} value={data}>{new Date(data).toLocaleDateString()}</option>
              ))
            }
        </select>
    </div>
       
    <MDBTable small>
      <MDBTableHead>
        <tr className="text-center">
          <th scope='col'>Rank</th>
          <th scope='col'>Username</th>
          <th scope='col'>Score</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {
            history.length !== 0 ?
                history.map((data, i) => (
            <tr key={i} className="text-center">
            <th scope='row'>{i + 1}</th>
            <td>{data.owner}</td>
            <td>{data.amount?.toLocaleString('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
              })}</td>
            </tr>
            ))
            :
            <tr className="text-center">
                <td colSpan={3}>No Data</td>
            </tr>
        }
      </MDBTableBody>
    </MDBTable>
    </>
    )
}

export default LeaderboardHistory;