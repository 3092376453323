import React, { useEffect, useState } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBTable, MDBTableHead, MDBTableBody
  } from 'mdb-react-ui-kit';
import Swal from "sweetalert2";
import PaginationPager from "components/Pagination/pagination";
const SApayinhistory = () => {

    const [payinhistory, setPayinHistory] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0);

    useEffect(() => {
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payin/getpayinhistorysuperadmin?page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
            if(data.message == "success"){
                setIsLoading(false)
                setPayinHistory(data.data.payinhistory)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
            }
        })
    },[page])

    const [ rate, setRate] = useState(0)
     // rate
     useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  const [search, setSearch] = useState('')

  //  useEffect(() => {
      
  //   },[search])

    useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payin/getpayinhistorysuperadmin?page=${page - 1}&limit=10&searchUsername=${search}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
              setPayinHistory(data.data.payinhistory)
              setTotal(data.data.totalPages)
            if(data.message == "success"){
            
                setIsLoading(false)
                setPayinHistory(data.data.payinhistory)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
            }
        })
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
  }, [search]);

     const clear = () => {
       fetch(`${process.env.REACT_APP_API_URL}/payin/getpayinhistorysuperadmin?page=${page - 1}&limit=10`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
            setSearch('')
            if(data.message == "success"){
                setIsLoading(false)
                setPayinHistory(data.data.payinhistory)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
              setIsLoading(false)
              Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
              })
            }
        })
     }

    //  const [transacid, setTransacid] = useState('')
    //  const [userid, setUserid] = useState('')

    //  console.log(transacid, userid)

   

     const payinDelete = (userid, transacid) => {
      setIsLoading(true)
      Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then((result) => {
          if (result.isConfirmed) {
              fetch(`${process.env.REACT_APP_API_URL}/payin/deletepayinplayersuperadminn`,{
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    transactionid: transacid,
                    userid: userid
                  })
                }).then(result => result.json())
                .then(data => {
                  if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                      Swal.fire({
                        icon: "error",
                        title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                        text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                        allowOutsideClick: false,
                        allowEscapeKey: false
                      }).then(ok => {
                        if(ok.isConfirmed){
                          window.location.href = "/login";
                        }
                      })
                  }

                  if(data.message == "success"){
                      setIsLoading(false)
                      Swal.fire({
                          title: data.message,
                          icon: "success",
                          text: data.data
                      }).then(ok => {
                          if(ok.isConfirmed){
                              window.location.reload()
                          }
                      })
                  } else if (data.message == "failed"){
                      setIsLoading(false)
                      Swal.fire({
                          title: data.message,
                          icon: "info",
                          text: data.data
                      })
                  }
                })
          }
        });
     
    }


   


    return(
    <MDBCard>
      <div className=" flex w-full justify-between items-center p-4">
        <p>Deposit History</p>
        <div className=" flex items-center justify-end gap-1">
          <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" className=" h-10 rounded-md p-2 bg-zinc-100"/>
          {/* <MDBBtn onClick={searchByusername}>Search</MDBBtn> */}
          <MDBBtn color="warning" className=" mr-3" onClick={clear}>Clear</MDBBtn>
      </div>
      </div>
      <MDBCardBody>
        {/* <MDBCardTitle>Payin History</MDBCardTitle> */}
        <MDBTable small responsive>
        <MDBTableHead>
            <tr className="text-center">
            <th scope='col'>Date</th>
            <th scope='col'>Username</th>
            {/* <th scope='col'>Fullname</th> */}
            <th scope='col'>Amount</th>
            <th scope='col'>Status</th>
            <th scope='col'>Action</th>
            {/* <th scope='col'>Process By</th> */}
            </tr>
        </MDBTableHead>
        <MDBTableBody>
            {
                payinhistory.length !== 0 ?
                    payinhistory.map((data, i) =>(
                    <tr key={i} className="text-center">
                        <th scope='row'>{new Date(data.createdAt).toLocaleString()}</th>
                        <th scope='row'>{data.username}</th>
                        {/* <th scope='row'>{`${data.firstname} ${data.lastname}`}</th> */}
                        <td>{`₱ ${data.value?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}

                          <p>$ {(data.value / rate).toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}</p>
                        </td>
                        {/* <td>{data.processby}</td> */}
                        <th scope='row'>{data.status}</th>

                        <th scope='row'>
                        
                            <MDBBtn color="danger" onClick={() => payinDelete(data.userid, data.id)}>Delete</MDBBtn>
                        </th>

                        {/* <th scope='row'>
                          { data.deletable === true && (
                            <MDBBtn color="danger" onClick={() => payinDelete(data.ownerid, data.transactionid)}>Delete</MDBBtn>
                          )}
                        </th> */}

                    </tr>
                    ))
                :
                <tr className="text-center">
                  <th scope='row' colSpan={6}>No Data</th>
                </tr>
            }
            
        </MDBTableBody>
        </MDBTable>
        <PaginationPager
          total={total} 
          page={page} 
          setPage={setPage}
          isloading={isloading}
          />
      </MDBCardBody>
    </MDBCard>
    )
}

export default SApayinhistory;