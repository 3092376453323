import React from "react"

function Faq () {
    return(
        <div className="content grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className=" h-full w-full">
                 <div className=" flex flex-col gap-4 items-center">
                    <h2 className=" text-2xl font-bold" >FAQ</h2>
                    <p className=" text-center">
                        Creature Smash is a fast-paced play-to-earn game where players
                        battle creatures using a sword hidden in a bubble, aiming to
                        defeat them before time runs out for valuable rewards.
                    </p>

                    <img src="/1.jpg" className=" w-full" />
                    </div>
            </div>

            <div className=" flex flex-col gap-2 w-full h-auto ">
                 
                <div className="tab">
                <input type="checkbox" name="accordion-1" id="cb1" />
                <label htmlFor="cb1" className="tab__label">
                    What is Creature Smash?</label>
                <div className="tab__content text-zinc-100">
                    <p>
                    Creature Smash is an exciting Play-to-earn action game where players battle various creatures, complete challenges, and compete in tournaments to earn rewards.
                    </p>
                </div>
                </div>

                <div className="tab">
                <input type="checkbox" name="accordion-1" id="cb2" />
                <label for="cb2" className="tab__label">
                How can I download and install Creature Smash?</label>
                <div class="tab__content text-zinc-100">
                    <p>
                    You can download Creature Smash from the App Store or Google Play Store. Simply search for "Creature Smash," click download, and follow the installation instructions. You can Also download the APK directly from the website or play the game exactly on the website.
                    </p>
                </div>
                </div>

                <div className="tab">
                    <input type="checkbox" name="accordion-1" id="cb3" />
                    <label for="cb3" className="tab__label">
                        How can I earn money in Creature Smash?</label>
                    <div class="tab__content text-zinc-50">
                        <p>
                        You can earn money by playing the game’s main feature, smashing the creatures, to fulfill the daily limit per creature. You can also earn through joining the events. Compete with other players and reach for the leaderboard. Lastly, you can earn through inviting. As your invitees buy creatures you will earn commission too.

                        </p>
                    </div>
                </div>

                <div className="tab">
                    <input type="checkbox" name="accordion-1" id="cb4" />
                    <label for="cb4" className="tab__label">
                        How do I withdraw my earnings?</label>
                    <div class="tab__content text-zinc-100">
                        <p>
                        You can withdraw your earnings using Gcash and GoTyme. To do this, go to the withdrawal section and enter the required details. You need to have at least P500 or $10 in your Commission or Game Wallet to withdraw. Make sure your balance meets this minimum before you try to withdraw. Double-check all the information you provide to avoid any delays. Once everything is set up correctly, you can easily request a withdrawal through these payment methods. Keep your details accurate to ensure smooth withdrawals. 

                        </p>
                    </div>
                </div>

                <div className="tab">
                    <input type="checkbox" name="accordion-1" id="cb5" />
                    <label for="cb5" className="tab__label">
                        How do I create an account?</label>
                    <div class="tab__content text-zinc-100">
                        <p>
                        To create an account on Creature Smash, you must first join our official communities on platforms like Discord, Facebook Groups, or other social media channels indicated in the website. Once you are part of the community, reach out to any of the community leaders or admins. These individuals are designated to assist new players in getting started, and you can ask them for a referral link. After receiving the referral link, click on it to be redirected to the Creature Smash registration page. There, you will need to fill out the necessary information, including your name, email address, and other required details. Once you have completed the form, submit it to create your account and start your journey in Creature Smash.

                        </p>
                    </div>
                </div>

                <div className="tab">
                    <input type="checkbox" name="accordion-1" id="cb6" />
                    <label for="cb6" className="tab__label">
                    What types of creatures can I buy in Creature Smash?</label>
                    <div class="tab__content text-zinc-100">
                        <p>
                        Creature Smash offers a wide variety of creatures, classified into 3 types of creatures which are the Epic, Legend and Mythic creature. Earnings in each type differ depending on the creature that you bought. Epic creatures have 30% Revenue, 7 days earning period, Creature’s minimum price: ₱500 (10 USD), Creature’s maximum price: ₱5,000 (100 USD). While Legend creature has 80% Revenue 15 days earning period Creature’s minimum price: ₱500 (10 USD) Creature’s maximum price: ₱50,000 (1,000 USD). Lastly, Mythic Creatures which has 200% Revenue 30 days earning period Creature’s minimum price: ₱5,000 (100 USD) Creature’s maximum price: ₱500,000 (10,000 USD).

                        </p>
                    </div>
                </div>

                <div className="tab">
                    <input type="checkbox" name="accordion-1" id="cb7" />
                    <label for="cb7" className="tab__label">
                        Can I have multiple accounts?</label>
                    <div class="tab__content text-zinc-100">
                        <p>
                        Players can have multiple accounts in Creature Smash, but each account must use a different Gmail address. You can sign up and manage several accounts as long as each one is linked to a separate Gmail address. This allows you to explore different strategies or keep your progress separate. Just make sure to follow the game’s rules for each account to avoid any problems.

                        </p>
                    </div>
                </div>

                <div className="tab">
                    <input type="checkbox" name="accordion-1" id="cb8" />
                    <label for="cb8" className="tab__label">
                        How can I report a bug or technical problem?</label>
                    <div class="tab__content text-zinc-100">
                        <p>
                        To report a bug or technical problem you can email us at tgif.ph2024@gmail.com. When sending an email, include a detailed description of the problem you’re experiencing. If possible, attach screenshots or any other relevant information that can help us diagnose and resolve the issue more efficiently. Providing thorough details will assist our support team in addressing your concern more quickly and accurately. Your feedback is crucial in helping us improve the game and ensure a better experience for all players.

                        </p>
                    </div>
                </div>

                <div className="tab">
                    <input type="checkbox" name="accordion-1" id="cb9" />
                    <label for="cb9" className="tab__label">
                    Can I customize my character or creature in Creature Smash?</label>
                    <div class="tab__content text-zinc-100">
                        <p>
                        Creature Smash lets you customize your creature’s face. To do this, go to your profile in the game and select the option to change your Display Picture. You can pick from different designs or upload your own image. This will change how your creature looks, letting you add your personal touch and make your gameplay experience more unique and fun.


                        </p>
                    </div>
                </div>

                <div className="tab">
                    <input type="checkbox" nameNaclassName="accordion-1" id="cb10" />
                    <label for="cb10" class="tab__label">
                    How can I invite friends to play Creature Smash?</label>
                    <div class="tab__content text-zinc-100">
                        <p>
                        You can invite friends to join Creature Smash by sharing your unique referral link, which is available in the game’s invitation section. To find your referral link, go to the invitation or referral area within the game’s settings. Once you have your link, share it with friends through social media, email, or messaging apps.When your friends use the referral link to sign up and start playing Creature Smash, you will earn rewards based on their in-game activity. 
                        </p>
                    </div>
                    </div>


            </div>
        </div>
    );

}

export default Faq;