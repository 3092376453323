import { MDBContainer, MDBTypography, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBInput, MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import SApayinhistory from "./Payin/payinhistory";
import SApayinlist from "./Payin/payinlist";
import Swal from "sweetalert2";
import SAcreaturehistory from "./Payin/creaturehistory";
const SuperAdminPayin = () => {
    const [loading, setLoading] = useState(false)
    const [payinhistory, setPayinHistory] = useState([])
    const [search, setSearch] = useState('')

    const [open, setOpen] = useState(false)

     useEffect(() => {
        if (search !== '') {
            setOpen(true);
        }
        }, [search]);

      const handleInputChange = (e) => {
        setSearch(e.target.value);
        setPayinHistory([]); // add this line
        setOpen(false);
        };


    const handleSend = (e) => {
        e.preventDefault();
        setLoading(true)
        const {username, amount} = e.target

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, send it!"
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${process.env.REACT_APP_API_URL}/payin/superadminsendfiatplayer`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        playerusername: username.value,
                        amount: amount.value.replace(/,/g, '')
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
        
                    if(data.message == "success"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: `Successfully sent ${amount.value} to ${username.value}`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                })
            } else {
                setLoading(false)
            }
          });
    }

     useEffect(() => {
        if(search !== ''){
             const timer = setTimeout(() => {
                fetch(`${process.env.REACT_APP_API_URL}/user/searchplayerlist?playerusername=${search}&page=0&limit=10`,{
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                        icon: "error",
                        title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                        text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                        allowOutsideClick: false,
                        allowEscapeKey: false
                        }).then(ok => {
                        if(ok.isConfirmed){
                            window.location.href = "/login";
                        }
                        })
                    }
                    setPayinHistory(data.data.userlist)
                })
            }, 1000); // delay for 1 second
            return () => clearTimeout(timer);

        } else {
            setPayinHistory([])
        }
   

  }, [search]);

   const [amount, setAmount] = useState('');

    const handleAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    const formattedValue = value === '' ? '' : parseInt(value).toLocaleString();

    setAmount(formattedValue);
    };


    return (
        <div className="content">
            <MDBContainer>
                <MDBRow>
                    <MDBCol lg={4} md={6} sm={12} className="offset-lg-4">
                        <MDBCard>
                            <form onSubmit={handleSend}>
                            <MDBCardBody className=" relative">
                                <MDBCardTitle>Top Up</MDBCardTitle>
                                <label>Player Username</label>
                                <MDBInput value={search} onChange={handleInputChange} name="username" label="Input player username" required/>

                                    {open === true && (
                                        <>
                                         {payinhistory.length !== 0 && (
                                         <div className=" absolute z-50 bg-zinc-100 p-2 w-[90%] h-auto max-h-[200px] flex flex-col gap-2 mt-2 overflow-y-auto">
                                            {payinhistory.map((data, index) =>(
                                                <p key={index} onClick={() => {setOpen(false);setSearch(data.username)}} className=" text-center text-sm bg-zinc-200 p-2 rounded-md cursor-pointer">{data.username}</p>
                                            ))}
                                        </div>
                                    )}
                                        
                                        </>
                                    )}


                              
                                <label>Amount (Php)</label>
                                <MDBInput
                                    type="text"
                                    name="amount"
                                    label="Input amount to send"
                                    min={1}
                                    required
                                    value={amount}
                                    onChange={handleAmountChange}
                                    />
                                <MDBBtn disabled={loading} type="submit">{loading ? <MDBSpinner size="sm"/> : 'Send'}</MDBBtn>
                            </MDBCardBody>
                            </form>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                <SApayinhistory/>
                 
                {/* <MDBTypography tag={'h2'}>Payin List</MDBTypography>
                <SApayinlist/> */}
                {/* <MDBTypography tag={'h2'} className=" mb-2">Deposit History</MDBTypography> */}

            </MDBContainer>
        </div>
    )
}

export default SuperAdminPayin;