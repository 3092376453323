import { 
    MDBCol,
    MDBRow,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    } from "mdb-react-ui-kit";
import { 
    MDBTable, 
    MDBTableHead, 
    MDBTableBody, 
    MDBContainer, 
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBIcon,
    MDBSpinner,
 } from 'mdb-react-ui-kit';
import React, {useState, useEffect} from "react";
import Swal from "sweetalert2";
import bg from "assets/recent-game-bg.png"
const Login = () => {
    const [loading, setLoading] = useState(false)
     const [basicModal, setBasicModal] = useState(false);
    const toggleOpen = () => setBasicModal(!basicModal);

     const [basicModal2, setBasicModal2] = useState(false);
    const toggleOpen2 = () => setBasicModal2(!basicModal);
    const [show, setShow] = useState('password')

    
    const login = (e) => {
        e.preventDefault();
        setLoading(true)
        const {username, password} = e.target
        fetch(`${process.env.REACT_APP_API_URL}/auth/login?username=${username.value}&password=${password.value}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if (data.message !== "success") {
                  
                setLoading(false)    
				Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
			} else {
        localStorage.setItem('video','true')
        localStorage.setItem('username',username.value)
                setLoading(false)
                Swal.fire({
                    title: "Login Successfully",
                    icon: "success",
                    text: `Welcome ${username.value}`,
                    allowEscapeKey: false,
                    allowOutsideClick: false
                  })
                  .then(result1 => {
                    if(result1.isConfirmed){
                        if(data.data.auth === "superadmin"){
                            localStorage.setItem('uid', btoa("superadmin"))
                            window.location.href = `superadmin/dashboard`
                        } else if(data.data.auth === "admin"){
                            localStorage.setItem('uid', btoa("admin"))
                            window.location.href = `admin/dashboard`
                        } else if(data.data.auth === "player"){
                            localStorage.setItem('uid', btoa("player"))
                            window.location.href = `user/dashboard`
                        }
                    }
                })
            }
        })

    }

    //auto login
    useEffect(() => {
         fetch(`${process.env.REACT_APP_API_URL}/auth/automaticlogin`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
          if(data.data.auth === "superadmin"){
                localStorage.setItem('uid', btoa("superadmin"))
                window.location.href = `superadmin/dashboard`
            } else if(data.data.auth === "admin"){
                localStorage.setItem('uid', btoa("admin"))
                window.location.href = `admin/dashboard`
            } else if(data.data.auth === "player"){
                localStorage.setItem('uid', btoa("player"))
                window.location.href = `user/dashboard`
            }
        })

    },[])

    return (
       <MDBContainer fluid className=" relative flex align-items-center justify-content-center min-vh-100">
          <video autoPlay="autoplay" muted loop className=" z-0 absolute object-cover w-full h-full">
              <source src="/Header Video Loop.mp4" type="video/mp4" />
            </video>

          <div className=" absolute z-20 w-full h-full bg-zinc-950/90">

          </div>

        <MDBRow className=" relative z-50">
            <MDBCol className="">
              <div className=" w-full flex items-center justify-center mb-4">
                <img src="/TGIF Logo.png" className=" w-[200px] lg:w-[350px]"/>
              </div>

              <div className=" w-full h-auto flex flex-col items-center justify-center px-2 pt-2 bg-blue-600 rounded-md">
                <p className=" text-2xl font-semibold text-white mt-2">Welcome</p>
                <p className=" text-sm text-zinc-200 mb-4">This will be amazing experience</p>
                <MDBCard className=" w-full">
                <MDBCardBody className=" flex flex-col items-center w-full rounded-sm">
                <MDBCardText tag="h1" className="text-center mb-4 font-semibold">Login</MDBCardText>
                <form onSubmit={login} className=" w-full">
                    <label className=" text-xs">Username</label>
                    <input name="username" className=' mb-2 form-control' type='text' id='form2Example1' placeholder="Username"/>
                    {/* <MDBInput name="password" className='mb-4' type={show} id='form2Example2' label='Password'>
                      {show === 'password' ? <i onClick={() => setShow('text')} className=" fas fa-eye-slash trailing"/> : <i onClick={() => setShow('password')} className=" fas fa-eye trailing"/>}
                      </MDBInput> */}

                    <label className=" text-xs">Password</label>
                      <div className="input-group mb-4">
                        <input
                          name="password"
                          type={show}
                          id="form2Example2"
                          placeholder="Password"
                          className="form-control"
                        />
                        <span className="input-group-text" onClick={() => setShow(show === 'password' ? 'text' : 'password')}>
                          <MDBIcon fas icon={show === 'password' ? 'eye-slash' : 'eye'} />
                        </span>
                      </div>


                    <div className='text-center'>
                    {/* <a href='#!'>Forgot password?</a> */}
                    </div>

                    <MDBBtn disabled={loading} type='submit' className='mb-4' block>
                        {loading ? <MDBSpinner size="sm"/> : 'Sign in'}
                    </MDBBtn>

                    {/* <div className='text-center'>
                        <p>
                        Not a member? <a href='/register'>Register</a>
                        </p>
                    </div> */}
                </form>
                </MDBCardBody>
                </MDBCard>
              </div>
            
            </MDBCol>
        </MDBRow>


        {/* <div className=" absolute bottom-0 flex lg:flex-row flex-col gap-2 items-center justify-between w-full bg-zinc-900 px-10 py-3 md:py-4">
     
        <div className=" flex flex-row items-center gap-3 " >

            <a href="https://www.facebook.com/">
                <img
                src="/1.png"
                alt=""
                className=" w-4 md:w-6"
            /> </a>

            <a href="http://x.com/">
              <img
            src="/2.png"
            alt=""
            className=" w-4 md:w-6"
            /> 
            </a>

            <a href="https://web.telegram.org/">
            <img
            src="/3.png"
            alt=""
            className=" w-4 md:w-6"
            /> 
            </a>

            <a href="https://www.tiktok.com/">
           <img
            src="/tik-tok.png"
            alt=""
            className=" w-4 md:w-6"
            /> 
            </a>

            <a href="mailto: tgif.ph2024@gmail.com">
                
            <img
            src="/email.png"
            alt=""
            className=" w-4 md:w-6"
            /> 
            </a>


        </div>

        <p className=" text-white text-xs md:text-sm" >
        
          All right reserve 2024-2025
        </p>

        <div className=" flex items-center text-xs md:text-sm gap-4 text-zinc-500">
          <a onClick={toggleOpen} className=" cursor-pointer">Terms & Conditions</a>
          <a onClick={toggleOpen2} className=" cursor-pointer">Privacy policy</a>
        </div>

        
      </div> */}

      
        <MDBModal  open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
            <MDBModalDialog centered>
            <MDBModalContent className=" flex flex-col gap-2 p-6 h-[400px] md:h-[500px] overflow-y-auto">
                <MDBModalTitle className=" text-xl font-semibold">Terms & Conditions</MDBModalTitle>
                <h5 className=" font-semibold mt-4">Introduction</h5>
            <p>
              Welcome to Creature Smash! These terms and conditions outline the
              rules and regulations for the use of the Creature Smash game.
            </p>

             <h5 className=" font-semibold"> Acceptance of Terms</h5>
            <p>
             By downloading, installing, or using Creature Smash, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, do not use the game.
            </p>

            <h5 className=" font-semibold">Eligibility</h5>
            <p>
            You must be at least [Minimum Age] years old to play Creature Smash. By agreeing to these terms, you represent that you meet this age requirement.
            </p>

              <h5 className=" font-semibold">License to Use</h5>
            <p>
            We grant you a non-exclusive, non-transferable, revocable license to use Creature Smash for your personal, non-commercial entertainment purposes, subject to these terms.

            </p>

              <h5 className=" font-semibold">Game Rules</h5>
            <p>
           Players must follow the in-game rules and guidelines. Any form of cheating, hacking, or exploiting game mechanics is strictly prohibited. Respect other players and avoid any form of harassment, abuse, or inappropriate behavior.


            </p>

              <h5 className=" font-semibold">Virtual Currency</h5>
            <p>
          Creature Smash may include virtual currency that can be purchased with real money or earned through gameplay. Virtual currency is not redeemable for real money and is non-transferable. All purchases of virtual currency are final and non-refundable.

            </p>

               <h5 className=" font-semibold"> Intellectual Property</h5>
            <p>
          All content, including but not limited to game assets, graphics, characters, and music, is the intellectual property of CreatureSmash. You may not reproduce, distribute, or create derivative works based on our content without our express permission.
            </p>

                <h5 className=" font-semibold"> User Content
              </h5>
            <p>
        You may be able to create, upload, and share content within Creature Smash. By submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content within the game.

            </p>

              <h5 className=" font-semibold"> Privacy Policy
              </h5>
            <p>
       Your use of Creature Smash is also governed by our Privacy Policy, which can be found at www.creature-smash.com/Privacy-Policy


            </p>

            <h5 className=" font-semibold"> Termination
              </h5>
            <p>
      We reserve the right to terminate or suspend your access to Creature Smash at our sole discretion, without prior notice, for any reason, including but not limited to a violation of these terms.


            </p>
        
            </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>

        <MDBModal  open={basicModal2} setOpen={setBasicModal2} tabIndex='-1'>
            <MDBModalDialog centered>
            <MDBModalContent className=" flex flex-col gap-2 p-6 h-[400px] md:h-[500px] overflow-y-auto">
                <MDBModalTitle className=" text-xl font-semibold">Privacy Policy</MDBModalTitle>
               <h5 className=" font-semibold mt-4"> Introduction</h5>
            <p>
              Welcome to Creature Smash. We respect your privacy and are committed to protecting your personal data. This privacy policy explains how we collect, use, and share your information when you play Creature Smash.

            </p>

             <h5 className=" font-semibold">  Information We Collect</h5>
            <p>
            We collect the following types of information:

            </p>

             <h5 className=" font-semibold"> Personal Information:</h5>

            <ul>
              <li>Registration Information: When you create an account, we collect your name, email address, and other contact information.</li>

              <li>Payment Information: If you make in-app purchases or withdraw earnings, we collect payment information such as Gcash Number and Gotyme Details</li>
              
            </ul>

             <h5 className=" font-semibold"> Usage Data:</h5>

              <ul>
              <li>Game Data: Information about your gameplay, including scores, achievements, and interactions with other players.</li>

              <li>Device Information: Information about the device you use to play Creature Smash, including IP address, device type, operating system, and browser type.</li>

               <li>Log Data: Details about your interactions with the game, including access times, pages viewed, and game crashes.</li>
              
            </ul>

             <h5 className=" font-semibold"> Cookies and Tracking Technologies:</h5>

              <ul>
              <li>We use cookies and similar technologies to collect information about your usage patterns and preferences.</li>

            </ul>

             <h5 className=" font-semibold mt-4">  How We Use Your Information</h5>
             <p>We use the information we collect for the following purposes:</p>

              <ul>
              <li>To Provide and Improve the Game: To operate, maintain, and improve Creature Smash and its features.</li>
              <li>To Process Payments: To facilitate in-app purchases and withdrawals.</li>
              <li>To Communicate with You: To send you updates, notifications, and other information related to the game.</li>
              <li>To Ensure Security: To monitor and enhance the security of the game and prevent fraud or illegal activities.</li>
              <li>To Analyze Usage: To understand how players use Creature Smash and to optimize the game experience.</li>

            </ul>

               <h5 className=" font-semibold mt-4">  How We Share Your Information</h5>
             <p>We do not share your personal information with third parties, except in the following circumstances:</p>

              <ul>
              <li>With Service Providers: We may share information with third-party service providers who assist us in operating the game, processing payments, and providing customer support.</li>
              <li>With Legal Authorities: We may disclose information if required by law or in response to a legal request.</li>
              <li>To Communicate with You: To send you updates, notifications, and other information related to the game.In Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.</li>
            

            </ul>

             <h5 className=" font-semibold mt-4"> Data Security</h5>
             <p>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. However, no security system is completely foolproof, and we cannot guarantee the absolute security of your information.</p>

              <h5 className=" font-semibold mt-4">  Your Rights
</h5>
             <p>You have the following rights regarding your personal data:</p>

              <ul>
              <li>Access: You can request access to your personal information and obtain a copy.</li>
              <li>Correction: You can request corrections to any inaccurate or incomplete personal information.</li>
              <li>Deletion: You can request the deletion of your personal information, subject to certain exceptions.</li>
              <li>Opt-Out: You can opt-out of receiving promotional communications from us.</li>
            
            </ul>

             <p>To exercise these rights, please contact us at tgif.ph2024@gmail.com</p>

               <h5 className=" font-semibold mt-4"> Children's Privacy</h5>
             <p>Creature Smash is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it as soon as possible.</p>

                <h5 className=" font-semibold mt-4"> Changes to This Privacy Policy</h5>
             <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy within the game or on our website. Your continued use of Creature Smash after such changes will constitute your acknowledgment and acceptance of the updated policy.</p>

              <h5 className=" font-semibold mt-4"> Contact Us</h5>
             <p>If you have any questions or concerns about this privacy policy or our data practices, please contact us at tgif.ph2024@gmail.com</p>
        
            </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </MDBContainer>
    )
}

export default Login;