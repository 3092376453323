import { Card, CardImg, CardBody, CardText, Col, CardFooter, Button, CardTitle, CardSubtitle } from 'reactstrap';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardSubTitle,
    MDBCardText,
    MDBCardLink,
    MDBCardImage,
    MDBBtn,
    MDBInput,
     MDBSpinner
} from 'mdb-react-ui-kit';
import React, {useState} from "react";
import plankton from "../../assets/01-Bronze/01-Goblin.png"
import Swal from 'sweetalert2';

const OwnCreatureCard = ({title, totalaccumulated, dailyaccumulated, image, qty,creatureid,totalaccumulatedusd, dailyaccumulatedusd}) => {
    const [loading, setLoading] = useState(false)


     const handleClaim = (e) => {
        e.preventDefault();
        setLoading(true)
        const {qty} = e.target
        fetch(`${process.env.REACT_APP_API_URL}/inventory/claimtotalincome`,{
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               creatureid: creatureid
                
            })
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "success",
                    text: `You successfully claimed your earnings`
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.reload()
                  }
                })

                
            } else if (data.message == "failed"){
                setLoading(false)
                Swal.fire({
                  title: data.message,
                  icon: "info",
                  text: data.data
                })
              }
        })
    }

    
    return (
        // <Col lg="3" md="6" sm="6" className="d-flex justify-content-center">
        // <MDBCard alignment='' style={{width: '100%'}}>
            
        //         <MDBCardBody style={{ width:'100%'}}>
        //         <MDBCardTitle className="text-center" tag="h3">{title}</MDBCardTitle>
        //         <MDBCardImage className='mb-3' top src={image} alt="..." />

        //             <div className=' mb-2'>
        //               <MDBCardSubTitle className=' text-center' tag="p">Daily Income: ₱ {dailyaccumulated?.toLocaleString('en-US', {
        //               style: 'decimal',
        //               minimumFractionDigits: 2,
        //               maximumFractionDigits: 2
        //               })}</MDBCardSubTitle>
        //               <MDBCardSubTitle className=' text-center' tag="p">Daily Income: $ {dailyaccumulatedusd?.toLocaleString('en-US', {
        //               style: 'decimal',
        //               minimumFractionDigits: 2,
        //               maximumFractionDigits: 2
        //               })}</MDBCardSubTitle>
        //             </div>

        //             <div>
        //                <MDBCardText className=' mb-0 text-center' tag="p">Total Income: ₱ {totalaccumulated?.toLocaleString('en-US', {
        //                 style: 'decimal',
        //                 minimumFractionDigits: 2,
        //                 maximumFractionDigits: 2
        //                 })}</MDBCardText>
        //                 <MDBCardText className=' text-center' tag="p">Total Income: $ {totalaccumulatedusd?.toLocaleString('en-US', {
        //                 style: 'decimal',
        //                 minimumFractionDigits: 2,
        //                 maximumFractionDigits: 2
        //                 })}</MDBCardText>

        //             </div>
                    
                   
        //             <center>
                  
        //             {/* <p>{creatureid}</p> */}
        //             </center>
        //            <MDBBtn disabled={loading} onClick={handleClaim} block>{loading ? <MDBSpinner size='sm'/> : 'Claim Now'}</MDBBtn>
        //         </MDBCardBody>
                
        //     </MDBCard>
        // </Col>

         <Col lg="6" md="6" sm="6" className=' w-[790px] relative flex flex-col md:flex-row items-center md:items-start justify-center gap-4' style={{display:'flex',backgroundColor:'white',padding:'1rem',justifyContent:'space-between',backgroundImage:"url('/bato.jpg')",borderRadius:'.5rem', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>
              <img className=' relative z-10' src={image} style={{width:'120px'}} />

              <div className=' relative z-10 flex flex-col items-center md:items-start px-4 md:border-r-2 border-zinc-200'>
                 <h3 className=' text-2xl' style={{fontWeight:'bold',color:'white'}}>{title}</h3>

                  <p className=' text-zinc-100 md:mt-4 '>Daily Income: ₱ {dailyaccumulated?.toLocaleString('en-US', {
                   style: 'decimal',
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2
                   })}</p>
                   <p className=' text-zinc-100 '>Daily Income: $ {dailyaccumulatedusd?.toLocaleString('en-US', {
                   style: 'decimal',
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2
                   })}</p>     
              </div>

              <div className=' relative z-10 md:mt-12' style={{display:'flex',flexDirection:'column',color:'#D3D3D3'}}>
                <MDBCardText className=' mb-0 text-center text-zinc-100 drop-shadow-xl shadow-white' tag="p">Total Income: ₱ {totalaccumulated?.toLocaleString('en-US', {
                 style: 'decimal',
                 minimumFractionDigits: 2,
                 maximumFractionDigits: 2
                 })}</MDBCardText>
                 <MDBCardText className=' text-center text-zinc-100 drop-shadow-xl shadow-white' tag="p">Total Income: $ {totalaccumulatedusd?.toLocaleString('en-US', {
                 style: 'decimal',
                 minimumFractionDigits: 2,
                 maximumFractionDigits: 2
                 })}</MDBCardText>
               
              </div>

              <div className=' relative z-10 flex items-end h-full'>
             <button style={{height:'40px',width:'120px',backgroundColor:'blue',color:'white',border:'none',borderRadius:'.5em'}}  disabled={loading} onClick={handleClaim} block>{loading ? <MDBSpinner size='sm'/> : 'Claim Now'}</button>

              </div>

              <div className=' md:hidden absolute bottom-0 w-full h-[70%] rounded-lg bg-gradient-to-b from-[#13131300] to-black'>

              </div>
                    
         </Col>
    )
}

export default OwnCreatureCard;
