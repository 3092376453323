import React , { useState, useEffect }from "react";
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon,
    MDBTypography,
    MDBBtn
} from 'mdb-react-ui-kit';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBTable, MDBTableHead, MDBTableBody, MDBSpinner
  } from 'mdb-react-ui-kit';
import SAcommissionpayoutlist from "./Payout/commissionlist";
import SAcommissionpayouthistory from "./Payout/commissionhistory";
import SAgamepayoutlist from "./Payout/gamelist";
import SAgamepayouthistory from "./Payout/gamehistory";
import Swal from "sweetalert2";
import PaginationPager from "components/Pagination/pagination";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";


const SuperAdminPayout = () => {
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
    };

    const [commissionpayoutlist, setCommissionPayoutList] = useState([]),
    [page, setPage] = useState(1),
    [isloading, setIsLoading] = useState(false),
    [total, setTotal] = useState(0),
    [page2, setPage2] = useState(1),
    [total2, setTotal2] = useState(0);
    const [filter, setFilter] = useState('')

    const [gamepayoutlist, setGamePayoutList] = useState([])

    //commission
    useEffect(() => {
      if(basicActive === 'tab1'){
         setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page - 1}&limit=10&type=commissionbalance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
           

            if(data.message == "success" && basicActive === 'tab1'){
                setIsLoading(false)
                setCommissionPayoutList(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
      }
       
    },[page, basicActive])

    const commissionReload = () => {
       fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page - 1}&limit=10&type=commissionbalance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
                setCommissionPayoutList(data.data.payoutlist)

            if(data.message == "success"){
                setIsLoading(false)
                setCommissionPayoutList(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
      
    }

    const handlePayout = (id, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
        setIsLoading(true)

                fetch(`${process.env.REACT_APP_API_URL}/payout/processpayout`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        payoutid: id,
                        status: status
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
                    
                    if(data.message == "success"){

                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: `Payout successfully ${status}`
                        }).then(ok => {
                            if(ok.isConfirmed){
                               commissionReload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                })
            } else {
                setIsLoading(false)
            }
          });
    }   

     const [ rate, setRate] = useState(0)
     // rate
     useEffect(() => {
     fetch(`${process.env.REACT_APP_API_URL}/conversionrate/getcurrentconversionrate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => {
      if (data.message === "duallogin" || data.message === "banned" || data.message === "Unauthorized") {
        Swal.fire({
          icon: "error",
          title: data.message === "duallogin" ? "Dual Login" : data.message === "banned" ? "Account Banned." : data.message,
          text: data.message === "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message === "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(ok => {
          if (ok.isConfirmed) {
            window.location.href = "/login";
          }
        });
      } else if (data.message === "success") {
        setRate(data.data.rate)
      } else if (data.message === "failed") {
        Swal.fire({
          title: data.message,
          icon: "info",
          text: data.data
        });
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
    }, []);

  const [searchComission, setSearchcomission] = useState('')
  const [comissionType, setComissiontype] = useState('')
    const [date, setDate] = useState('')
    const [date2, setDate2] = useState('')


    useEffect(() => {
    const timer = setTimeout(() => {
      if(basicActive === 'tab1'){
         setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page - 1}&limit=10&type=commissionbalance&searchUsername=${searchComission}&methodtype=${filter}&date=${date}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setCommissionPayoutList(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
      }
      
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
    }, [searchComission]);

    useEffect(() => {
    const timer = setTimeout(() => {
     if(basicActive === 'tab1'){
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page - 1}&limit=10&type=commissionbalance&searchUsername=${searchComission}&methodtype=${filter}&date=${date}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setCommissionPayoutList(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
     }
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
    }, [filter]);

    useEffect(() => {
    const timer = setTimeout(() => {
      if(basicActive === 'tab1'){
         setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page - 1}&limit=10&type=commissionbalance&searchUsername=${searchComission}&methodtype=${filter}&date=${date}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setCommissionPayoutList(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
      }
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
    }, [date]);


    const clearComission =() => {
           fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page - 1}&limit=10&type=commissionbalance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
            setSearchcomission('')
                setCommissionPayoutList(data.data.payoutlist)
            setPage(1)
            if(data.message == "success"){
                setIsLoading(false)

                setCommissionPayoutList(data.data.payoutlist)
                setTotal(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
    }


    //game
    const [searchGame, setSearchgame] = useState('')
    const [gameTotalPage, setGameTotalPage] = useState(0)

     useEffect(() => {
       if(basicActive === 'tab2'){
         setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page2 - 1}&limit=10&type=gamebalance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success" && basicActive === 'tab2'){
                setIsLoading(false)
                setGamePayoutList(data.data.payoutlist)
                setTotal2(data.data.totalPages)
                setGameTotalPage(data.data.totalPages)
                
            } else if (data.message == "failed"){
                setIsLoading(true)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
       }
    },[page2, basicActive])

    const gameReload = () => {
       fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page2 - 1}&limit=10&type=gamebalance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setGamePayoutList(data.data.payoutlist)
                setTotal2(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(true)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
      
    }

    const handleGamePayout = (id, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)

                fetch(`${process.env.REACT_APP_API_URL}/payout/processpayout`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        payoutid: id,
                        status: status
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                           window.location.href = "/login";
                          }
                        })
                    }
                    
                    if(data.message == "success"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: `Payout successfully ${status}`
                        }).then(ok => {
                            if(ok.isConfirmed){
                              gameReload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                })
            } else {
                setIsLoading(false)
            }
          });
    }

    useEffect(() => {
    const timer = setTimeout(() => {
       if(basicActive === 'tab2'){
            setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page2 - 1}&limit=10&type=gamebalance&searchUsername=${searchGame}&methodtype=${filter}&date=${date}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setGamePayoutList(data.data.payoutlist)
                setTotal2(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
       }
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
    }, [searchGame]);

    useEffect(() => {
    const timer = setTimeout(() => {
        if( basicActive === 'tab2'){
             setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page2 - 1}&limit=10&type=gamebalance&searchUsername=${searchGame}&methodtype=${filter}&date=${date}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setGamePayoutList(data.data.payoutlist)
                setTotal2(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
        }
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
    }, [filter]);

    useEffect(() => {
    const timer = setTimeout(() => {
      if( basicActive === 'tab2'){
         setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page2 - 1}&limit=10&type=gamebalance&searchUsername=${searchGame}&methodtype=${filter}&date=${date}`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setGamePayoutList(data.data.payoutlist)
                setTotal2(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
      }
    }, 1000); // delay for 1 second

    return () => clearTimeout(timer);
    }, [date]);


    const clearGame = () => {
           fetch(`${process.env.REACT_APP_API_URL}/payout/getpayoutlist?page=${page - 1}&limit=10&type=commissionbalance`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }
            setSearchgame('')
            if(data.message == "success"){
                setIsLoading(false)

                setCommissionPayoutList(data.data.payoutlist)
                setTotal2(data.data.totalPages)
            } else if (data.message == "failed"){
                setIsLoading(false)
                Swal.fire({
                    title: data.message,
                    icon: "info",
                    text: data.data
                })
            }
        })
    }

    const clearFilter = () => {
    setFilter('')
    setDate('')
    }

    const clearFilter2 = () => {
    setFilter('')
    setDate2('')
    }

    useEffect(() => {
      setPage(1)
      setPage2(1)
      setCommissionPayoutList([])
      setGamePayoutList([])
    },[basicActive])


      const handleDeletePayout = (id, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
          }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)

                fetch(`${process.env.REACT_APP_API_URL}/payout/deletepayout`,{
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        payoutid: id,
                    })
                }).then(result => result.json())
                .then(data => {
                    if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                        Swal.fire({
                          icon: "error",
                          title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                          text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                          allowOutsideClick: false,
                          allowEscapeKey: false
                        }).then(ok => {
                          if(ok.isConfirmed){
                            window.location.href = "/login";
                          }
                        })
                    }
                    
                    if(data.message == "success"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "success",
                            text: `Payout deleted`
                        }).then(ok => {
                            if(ok.isConfirmed){
                                window.location.reload()
                            }
                        })
                    } else if (data.message == "failed"){
                        setIsLoading(false)
                        Swal.fire({
                            title: data.message,
                            icon: "info",
                            text: data.data
                        })
                    }
                })
            } else {
                setIsLoading(false)
            }
          });
    }

    useEffect(() => {
      setDate('')
      setFilter('')
      clearComission()
      clearGame()
    },[basicActive])

    const [totalcomission, setTotalcomission] = useState('0')
    const [totalgame, setTotalgame] = useState('0')

     useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/payout/gettotalrequest`,{
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
        }).then(result => result.json())
        .then(data => {
            if(data.message == "duallogin" || data.message == "banned" || data.message == "Unauthorized"){
                Swal.fire({
                  icon: "error",
                  title: data.message == "duallogin" ? "Dual Login" : data.message == "banned" ? "Account Banned." : data.message,
                  text: data.message == "duallogin" ? "Hi Creature, it appears that your account has been accessed from a different device." : data.message == "banned" ? "Hi Creature please contact admin" : "You Will Redirect to Login",
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(ok => {
                  if(ok.isConfirmed){
                    window.location.href = "/login";
                  }
                })
            }

            if(data.message == "success"){
                setIsLoading(false)
                setTotalcomission(data.data.totalrequestcommission)
                setTotalgame(data.data.totalrequestgame)
               
            } else if (data.message == "failed"){
                setIsLoading(false)
               
            }
        })

    }, []);


    console.log(gameTotalPage)

   
    return (
        <div className="content">

          <Row>
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-circle-dollar-to-slot text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Comission Request</p>
                        <CardTitle tag="p" className=" s" style={{fontSize:'28px'}}>
                        ₱{totalcomission.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}
                                    <p className=" text-xs mb-4">$ {(totalcomission / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p>
                        </CardTitle>
                        
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update Now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>

            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-circle-dollar-to-slot text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Game Request</p>
                        <CardTitle tag="p" className=" s" style={{fontSize:'28px'}}>
                        ₱{totalgame.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}
                                     <p className=" text-xs mb-4">$ {(totalgame / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p>
                        </CardTitle>
                        
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update Now
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
          </Row>

            <div className=" flex md:flex-row flex-col items-center justify-between">
                 <MDBTabs className='mb-3' pills>
                    <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                    <MDBIcon fas  icon="money-bill-alt" className='me-2'/>Commission
                    </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                    <MDBIcon fas icon="dice" className='me-2'/>Game
                    </MDBTabsLink>
                    </MDBTabsItem>
                    {/* <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                    <MDBIcon fas icon="dice" className='me-2'/>Payout
                    </MDBTabsLink>
                    </MDBTabsItem> */}
                </MDBTabs>

                {basicActive === 'tab1' && (
                    <div className=" flex items-center justify-end gap-1">
                        <input value={searchComission} onChange={(e) => setSearchcomission(e.target.value)} placeholder="Search" className=" h-10 rounded-md p-2"/>
                        {/* <MDBBtn onClick={searchByusername}>Search</MDBBtn> */}
                        <MDBBtn color="warning" className=" mr-3" onClick={clearComission}>Clear</MDBBtn>
                    </div>
                )}

                 {basicActive === 'tab2' && (
                    <div className=" flex items-center justify-end gap-1">
                        <input value={searchGame} onChange={(e) => setSearchgame(e.target.value)} placeholder="Search" className=" h-10 rounded-md p-2"/>
                        {/* <MDBBtn onClick={searchByusername}>Search</MDBBtn> */}
                        <MDBBtn color="warning" className=" mr-3" onClick={clearGame}>Clear</MDBBtn>
                    </div>
                )}

                

              
                
            </div>

       

        <MDBTabsContent>
            <MDBTabsPane open={basicActive === 'tab1'}>
            <MDBTypography tag={'h2'}>Commission Payout List</MDBTypography>
             <MDBCard className=" p-4">
                <div className=" flex md:flex-row flex-col items-center gap-4 ">
                    
                        <select className=" border p-2"
                        required
                        onChange={(e) => setFilter(e.target.value)}
                        value={filter}
                        style={{width: "220px", borderRadius: "6px"}}>
                            <option value='select' selected>Payment Method</option>
                            <option value='Gcash' selected>Gcash</option>              
                            <option value='Gotyme' selected>Gotyme</option>              
                        </select>
                        <input value={date} onChange={(e) => setDate(e.target.value)} type="date" className=" bg-zinc-100 p-2 rounded-md"/>
                        <MDBBtn onClick={clearFilter} className="m-1" size="sm" color="danger">
                            Reset
                        </MDBBtn>
                    </div>
                <MDBCardBody>
                    {/* <MDBCardTitle>Payin List</MDBCardTitle> */}
                    <MDBTable small responsive>
                    <MDBTableHead>
                        <tr className="text-center">
                        <th scope='col'>Date</th>
                        <th scope='col'>Username</th>
                        <th scope='col'>Account Number</th>
                        <th scope='col'>Account Name</th>
                        <th scope='col'>Phone no.</th>
                        <th scope='col'>Payout Method</th>
                        <th scope='col'>Gross Amount</th>
                        <th scope='col'>Withdrawal Fee</th>
                        <th scope='col'>Net Amount</th>
                        <th scope='col'>Status</th>
                        <th scope='col'>Action</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            commissionpayoutlist.length !== 0 ?
                                commissionpayoutlist.map((data, i) =>(
                                <tr key={i} className="text-center">
                                    <td scope='row'>{new Date(data.createdAt).toLocaleString()}</td>
                                    <td scope='row'>{data.username}</td>
                                    <td scope='col'>{data.accountnumber}</td>
                                    <td scope='col'>{data.accountname}</td>
                                    <th scope='col'>{data.phonenumber}</th>

                                     
                                    <td scope='row'>{data.paymentmethod}</td>
                                    <td>₱ {data.grossamount?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.grossamount / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>
                                     <td>₱ {data.withdrawalfee?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.withdrawalfee / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>

                                     <td>₱ {data.netamount?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.netamount / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>
                                   
                                    <td scope='row'>{data.status}</td>
                                    {/* <td>{data.status}</td> */}
                                    <td>
                                        <MDBBtn className="m-1" size="sm" color="danger" onClick={() => handleDeletePayout(data.id)}>
                                              {isloading ? <MDBSpinner size="sm"/> : 'Delete'}
                                          </MDBBtn>
                                        <MDBBtn disabled={isloading} className="m-1" size="sm" color="info" onClick={() => handlePayout(data.id, "done")}>
                                            {isloading ? <MDBSpinner size="sm"/> : 'Process'}
                                        </MDBBtn>
                                        <MDBBtn className="m-1" size="sm" color="danger" onClick={() => handlePayout(data.id, "reject")}>
                                            {isloading ? <MDBSpinner size="sm"/> : 'Reject'}
                                        </MDBBtn>
                                    </td>
                                </tr>
                                ))
                            :
                            <tr className="text-center">
                                <th scope='row' colSpan={8}>No Data</th>
                            </tr>
                        }
                    </MDBTableBody>
                    </MDBTable>
                    <PaginationPager
                    total={total} 
                    page={page} 
                    setPage={setPage}
                    isloading={isloading}
                    />
                </MDBCardBody>
                </MDBCard>
            <SAcommissionpayouthistory/>
            </MDBTabsPane>
            <MDBTabsPane open={basicActive === 'tab2'}> 
            <MDBTypography tag={'h2'}>Game Payout List</MDBTypography>
             <MDBCard className=" p-4">
                <div className=" flex md:flex-row flex-col items-center gap-4 ">
                
                    <select name="date" className=" border p-2"
                    required
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                    style={{width: "220px", borderRadius: "6px"}}>
                        <option value='select' selected>Payment Method</option>
                        <option value='Gcash' selected>Gcash</option>              
                        <option value='Gotyme' selected>Gotyme</option>              
                    </select>
                    <input value={date} onChange={(e) => setDate(e.target.value)} type="date" className=" bg-zinc-100 p-2 rounded-md"/>

                    <MDBBtn onClick={clearFilter} className="m-1" size="sm" color="danger">
                        Reset
                    </MDBBtn>
                </div>

            <MDBCardBody>
                {/* <MDBCardTitle>Payin List</MDBCardTitle> */}
                <MDBTable small responsive>
                <MDBTableHead>
                    <tr className="text-center">
                    <th scope='col'>Date</th>
                    <th scope='col'>Username</th>
                    <th scope='col'>Account Number</th>
                    <th scope='col'>Account Name</th>
                    <th scope='col'>Phone no.</th>

                    <th scope='col'>Payout Method</th>
                    <th scope='col'>Gross Amount</th>
                    <th scope='col'>Withdrawal Fee</th>
                    <th scope='col'>Net Amount</th>
                    <th scope='col'>Status</th>
                    <th scope='col'>Action</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        setGamePayoutList.length !== 0 ?
                            gamepayoutlist.map((data, i) =>(
                            <tr key={i} className="text-center">
                                 {/* <td scope='row'></td> */}
                                    <td scope='row'>{new Date(data.createdAt).toLocaleString()}</td>
                                    <td scope='row'>{data.username}</td>
                                    <td scope='col'>{data.accountnumber}</td>
                                    <td scope='col'>{data.accountname}</td>
                                    <th scope='col'>{data.phonenumber}</th>

                                    <td scope='row'>{data.paymentmethod}</td>
                                    <td>₱ {data.grossamount?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.grossamount / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>
                                     <td>₱ {data.withdrawalfee?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.withdrawalfee / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>

                                     <td>₱ {data.netamount?.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })} <p className=" text-xs">$ {(data.netamount / rate).toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                    })}</p></td>
                                   
                                    <td scope='row'>{data.status}</td>
                                {/* <td>{data.status}</td> */}
                                <td>
                                    {/* <MDBBtn className="m-1" size="sm" color="info" onClick={() => handleGamePayout(data.id, "done")}>
                                        Review
                                    </MDBBtn> */}
                                     <MDBBtn className="m-1" size="sm" color="danger" onClick={() => handleDeletePayout(data.id)}>
                                              {isloading ? <MDBSpinner size="sm"/> : 'Delete'}
                                          </MDBBtn>
                                    <MDBBtn className="m-1" size="sm" color="success" onClick={() => handleGamePayout(data.id, "done")}>
                                        Approve
                                    </MDBBtn>
                                    <MDBBtn className="m-1" size="sm" color="danger" onClick={() => handleGamePayout(data.id, "reject")}>
                                        Reject
                                    </MDBBtn>
                                </td>
                            </tr>
                            ))
                        :
                        <tr className="text-center">
                            <th scope='row' colSpan={8}>No Data</th>
                        </tr>
                    }
                </MDBTableBody>
                </MDBTable>
                <PaginationPager
                total={gameTotalPage} 
                page={page2} 
                setPage={setPage2}
                isloading={isloading}
                />
            </MDBCardBody>
            </MDBCard>
            <SAgamepayouthistory/>
            </MDBTabsPane>
             
        </MDBTabsContent>
        </div>
    )
}

export default SuperAdminPayout;